import NoPrivateBrowsingHelp from '@/views/help/NoPrivateBrowsingHelp.vue'

export default {
  component: NoPrivateBrowsingHelp,
  meta: {
    loadingMessage: 'Loading help...',
    showMenu: false,
  },
  name: 'helpNoPrivateBrowsing',
  path: '/help/private-browsing',
}
