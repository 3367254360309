import { useFormAnswersStore } from '@/storage/formAnswers'
import { useBypassStore } from '@/storage/bypass'
import { useCustomerStore } from '@/storage/customer'
import { useFormsStore } from '@/storage/forms'
import { useFrequenciesStore } from '@/storage/frequencies'
import { useNativeScanStore } from '@/storage/nativeScan'
import { usePermitFormStore } from '@/storage/permitForm'
import { useUserPositionStore } from '@/storage/userPosition'
import { useServicesStore } from '@/storage/services'
import { useSessionStore } from '@/storage/session'
import { useSiteStore } from '@/storage/site'
import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
import { useSupplementalFormStore } from '@/storage/supplementalForms'
import { useWorkOrderStore } from '@/storage/workOrder'
import { useUserStore } from '@/storage/user'
import { usePermitFormSubmissionsStore } from '@/storage/permitFormSubmissions'

export default function resetStore(shouldResetUser = true): void {
  if (shouldResetUser) {
    useUserStore().resetUser()
  }
  useNativeScanStore().resetNativeScan()
  useSiteStore().resetSite()
  useServicesStore().resetServices()
  useCustomerStore().resetCustomer()
  useFrequenciesStore().resetFrequencies()
  useUserPositionStore().resetUserPosition()
  useFormsStore().resetForms()
  useBypassStore().resetBypass()
  useSessionStore().resetSession()
  useSiteEntryConfirmationStore().resetSiteEntryConfirmation()
  useSupplementalFormStore().resetSupplementalForm()
  useFormAnswersStore().resetFormAnswers()
  usePermitFormStore().resetPermitForm()
  useWorkOrderStore().resetWorkOrder()
  usePermitFormSubmissionsStore().resetPermitFormSubmissions()
}

export function resetStoreExceptUser() {
  resetStore(false)
}

export function resetDataStores() {
  useFrequenciesStore().resetFrequencies()
  useFormsStore().resetForms()
  useSiteEntryConfirmationStore().resetSiteEntryConfirmation()
  useSupplementalFormStore().resetSupplementalForm()
  useFormAnswersStore().resetFormAnswers()
  usePermitFormStore().resetPermitForm()
  useWorkOrderStore().resetWorkOrder()
  usePermitFormSubmissionsStore().resetPermitFormSubmissions()
}
