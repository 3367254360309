<template>
  <section>
    <SiteEntryInformation />
    <SupervisorSignOff
      v-if="requiresSupervisorSignature && canContinueStatic"
      v-model:supervisor-signature="supervisorSignature"
      v-model:supervisor-signature-error="supervisorSignatureError"
      v-model:supervisor-name="supervisorName"
      v-model:supervisor-name-error="supervisorNameError"
      class="card p-4"
    />
    <div class="mb-10">
      <div v-if="canContinueStatic" class="card p-4">
        <p class="mb-4 text-center font-bold">
          {{ reportToText }}
        </p>
        <button
          :key="continueButtonKey"
          type="button"
          class="w-full rounded-md bg-green-600 p-4 text-center text-white shadow-md transition-all duration-100 ease-out active:bg-opacity-80 disabled:opacity-50"
          data-test="site-entry-confirmation-next-button"
          @click="handleNextRoute"
        >
          NEXT
        </button>
      </div>
      <div v-else class="p-4">
        <SecondaryButton
          :key="continueButtonKey"
          class="border border-gray-200 p-4 shadow"
          action="Return to Login"
          :icon="ArrowRightStartOnRectangleIcon"
          @click="handleNextRoute"
        />
      </div>
    </div>
    <LoadingView :is-loading="isLoading" message="Saving sign off..." />
  </section>
</template>

<script lang="ts">
  import { computed, defineComponent, nextTick, onMounted, ref } from 'vue'
  import SiteEntryInformation from '@/components/SiteEntryInformation.vue'
  import { useRouter } from 'vue-router'
  import resetStore from '@/components/use/resetStore'
  import Log from '@/utilities/Log'
  import { acquireRequiredData } from '@/components/use/checkout'
  import { postCheckoutData } from '@/services/api/checkout'
  import { submitSupervisorSignOff } from '@/services/api/inductionForm'
  import SupervisorSignOff from '@/components/SupervisorSignOff.vue'
  import LoadingView from '@/views/LoadingView.vue'
  import { useSessionStore } from '@/storage/session'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
  import { ArrowRightStartOnRectangleIcon } from '@heroicons/vue/24/outline'
  import SecondaryButton from '@/components/SecondaryButton.vue'

  export default defineComponent({
    name: 'SiteEntryConfirmation',

    components: {
      LoadingView,
      SecondaryButton,
      SiteEntryInformation,
      SupervisorSignOff,
    },

    setup() {
      const router = useRouter()
      const { id: siteId } = storeToRefs(useSiteStore())
      const { requiresSupervisorSignature } = storeToRefs(useCustomerStore())
      const { addSessionEvent } = useSessionStore()
      const { currentEvent } = storeToRefs(useSessionStore())
      const { isLoggedIn } = storeToRefs(useUserStore())
      const { canContinue } = storeToRefs(useSiteEntryConfirmationStore())
      const canContinueStatic = canContinue.value
      const reportToText = computed(() =>
        canContinueStatic
          ? 'Please report to the Site Manager / Representative'
          : '',
      )

      onMounted(() => {
        if (
          isLoggedIn.value &&
          canContinueStatic &&
          currentEvent.value !== 'SiteEntryConfirmationEvent'
        ) {
          addSessionEvent('SiteEntryConfirmationEvent')
        }
        window.scrollTo(0, 0)
      })

      const continueButtonKey = ref(0)
      const isLoading = ref(false)
      async function handleNextRoute() {
        continueButtonKey.value++
        if (requiresSupervisorSignature.value && canContinueStatic) {
          const canSubmit = validate()
          if (!canSubmit) return

          try {
            isLoading.value = true
            await submitSupervisorSignOff(
              siteId.value,
              supervisorName.value,
              supervisorSignature.value,
            )
          } finally {
            isLoading.value = false
          }
        }

        if (!canContinueStatic) {
          await router.replace({ name: 'login' })
          return
        }

        await router.replace({ name: 'workOrder' })
      }

      if (!canContinueStatic) {
        postCheckoutData(acquireRequiredData(), true).then(() => {
          nextTick(() => {
            resetStore()
            Log.storeReset('SiteEntryConfirmation.vue')
          })
        })
      }

      const supervisorName = ref<string>('')
      const supervisorNameError = ref<string>('')
      const supervisorSignature = ref<string>('')
      const supervisorSignatureError = ref<string>('')

      function validate(): boolean {
        if (supervisorSignature.value === '') {
          supervisorSignatureError.value = 'Please sign'
        }

        if (supervisorName.value.trim().length < 3) {
          supervisorNameError.value = 'Please enter at least 3 characters'
        }

        if (supervisorName.value === '') {
          supervisorNameError.value = 'Please enter name'
        }

        return (
          supervisorSignatureError.value === '' &&
          supervisorNameError.value === ''
        )
      }

      return {
        canContinueStatic,
        continueButtonKey,
        handleNextRoute,
        isLoading,
        reportToText,
        requiresSupervisorSignature,
        supervisorName,
        supervisorNameError,
        supervisorSignature,
        supervisorSignatureError,
      }
    },

    methods: { ArrowRightStartOnRectangleIcon },
  })
</script>
