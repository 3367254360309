import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { WorkOrder } from '@/services/api/workOrder'
import { errorMessage, SchemaType } from '@verifiedit/form-generator'

export const useWorkOrderStore = defineStore(
  'workOrder',
  () => {
    /** state */
    const selectedWorkOrder = ref<WorkOrder | null>(null)
    const workOrderCompleteStatus = ref<'COMPLETED' | 'IN_PROGRESS' | null>(
      null,
    )
    const workOrderResolutionDescription = ref<string | null>(null)

    /** getters */
    const selectedWorkOrderType: ComputedRef<
      'PREVENTATIVE' | 'REACTIVE' | null
    > = computed(() => selectedWorkOrder.value?.type ?? null)

    const hasSelectedWorkOrder: ComputedRef<boolean> = computed(
      () => selectedWorkOrder.value !== null,
    )

    const canSubmitWorkOrder: ComputedRef<boolean> = computed(
      () =>
        workOrderCompleteStatus.value === 'IN_PROGRESS' ||
        workOrderResolutionDescription.value !== null,
    )

    /** actions */
    function setSelectedWorkOrder(value: WorkOrder): void {
      selectedWorkOrder.value = value
    }
    function populateWorkOrderData(schema: (SchemaType & errorMessage)[]) {
      if (!selectedWorkOrder.value) {
        return
      }
      const workOrderReferenceComponent = schema.find(
        (component) => component.identifier === 'workOrder',
      )
      if (workOrderReferenceComponent) {
        workOrderReferenceComponent.default = selectedWorkOrder.value.reference
      }
      const workOrderDescriptionComponent = schema.find(
        (component) => component.identifier === 'workDescription',
      )
      if (workOrderDescriptionComponent) {
        workOrderDescriptionComponent.default =
          selectedWorkOrder.value.description
      }
    }

    function setWorkOrderCompleteStatus(
      value: 'IN_PROGRESS' | 'COMPLETED' | null,
    ) {
      workOrderCompleteStatus.value = value
    }

    function setWorkOrderResolutionDescription(value: string | null) {
      workOrderResolutionDescription.value = value
    }
    function resetWorkOrder() {
      selectedWorkOrder.value = null
      workOrderCompleteStatus.value = null
      workOrderResolutionDescription.value = null
    }

    return {
      canSubmitWorkOrder,
      hasSelectedWorkOrder,
      populateWorkOrderData,
      resetWorkOrder,
      selectedWorkOrder,
      selectedWorkOrderType,
      setSelectedWorkOrder,
      setWorkOrderCompleteStatus,
      setWorkOrderResolutionDescription,
      workOrderCompleteStatus,
      workOrderResolutionDescription,
    }
  },
  {
    persist: true,
  },
)
