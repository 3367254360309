import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import RecoverableException from '@/exceptions/RecoverableException'
import VisitorAccessRepresentation from '@/views/VisitorAccessRepresentation.vue'
import { useCustomerStore } from '@/storage/customer'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (useCustomerStore().hasVisitorManagement) {
    next()
  } else {
    throw new RecoverableException('No visitor access')
  }
}

export default {
  beforeEnter: guard,
  component: VisitorAccessRepresentation,
  meta: {
    loadingMessage: 'Loading visitor options...',
    showMenu: false,
  },
  name: 'representation',
  path: '/representation',
}
