import axios from 'axios'
import config from '@/config'
import resetStore from '@/components/use/resetStore'
import { useNativeScanStore } from '@/storage/nativeScan'
import RecoverableException from '@/exceptions/RecoverableException'
import ReportableException from '@/exceptions/ReportableException'
import MaintenanceModeException from '@/exceptions/MaintenanceModeException'
import NewMajorVersionException from '@/exceptions/NewMajorVersionException'
import { version } from '~/package.json'

const errorInterceptor = (): void => {
  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.errors) {
        throw new RecoverableException(
          response?.data?.errors?.[0]?.message ??
            JSON.stringify(response.data.errors),
        )
      }

      return response
    },
    (error) => {
      if (error?.message === 'canceled') {
        return
      }

      if (error?.response?.status === 401) {
        const uuid = useNativeScanStore().uuid

        resetStore()

        const url = new URL(config.unauthorized.redirect.url)
        if (uuid.length > 0) {
          url.searchParams.set('uuid', uuid)
        }
        window.location.replace(url.toString())
        return
      }

      if (error?.response?.status === 503) {
        const message = error?.response?.data?.message
        throw new MaintenanceModeException(message)
      }

      if (error?.response?.status > 499) {
        throw new ReportableException(
          `A server error with status ${error.response.status} has occurred.`,
          {
            error: error,
          },
          ['ErrorInterceptor', error.name],
        )
      }

      if (
        error?.response?.status === 400 &&
        error?.response?.data?.errors?.[0]?.code === 2000
      ) {
        const newVersion = error?.response?.data?.meta?.version

        throw new NewMajorVersionException(
          {
            current: version,
            new: newVersion,
          },
          ['NewMajorVersionDetected'],
          `New version ${newVersion} detected. Relaunching the application to update.`,
        )
      }

      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        throw new ReportableException(
          'There was a problem with your request. Please try again',
          { error: error },
          ['ErrorInterceptor', error.name],
        )
      }

      if (error.message === 'Network Error') {
        throw new RecoverableException(
          'A network error has occurred. Please check your internet connection.',
        )
      }

      if (error?.response?.status < 200 || error?.response?.status > 299) {
        throw new ReportableException('User error.', { error: error }, [
          'ErrorInterceptor',
          error.name,
        ])
      }

      throw new ReportableException(
        'An unknown error has occurred. Please try again later.',
        { error: error },
        ['ErrorInterceptor', error.name],
      )
    },
  )
}

export default errorInterceptor
