import dayjs from 'dayjs'
import config from '@/config'
import resetStore from '@/components/use/resetStore'
import { useSessionStore } from '@/storage/session'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/storage/user'
import { useAppStore } from '@/storage/app'

export function validateSessionOnFocus() {
  const { checkedInTime } = storeToRefs(useUserStore())
  const { sessionTtl } = storeToRefs(useSessionStore())

  if (checkedInTime.value === null) {
    return
  }

  const estimatedCheckoutTime = dayjs(checkedInTime.value).add(
    sessionTtl.value,
    'minute',
  )
  const isExpired = dayjs().isAfter(estimatedCheckoutTime)

  if (isExpired) {
    resetStore()
    window.location.replace(config.redirects.sessionExpired)
  }
}

export function initialiseLoadedAt() {
  const searchParams = new URLSearchParams(window.location.search)

  if (searchParams.has('t')) {
    const timestamp = parseInt(searchParams.get('t') as string)
    const olderThanOneDay = dayjs().isAfter(dayjs(timestamp).add(1, 'day'))

    if (olderThanOneDay) {
      useAppStore().setLoadedAt(dayjs().valueOf())
    } else {
      useAppStore().setLoadedAt(timestamp)
    }
  } else {
    useAppStore().setLoadedAt(dayjs().valueOf())
  }

  useSessionStore().setActiveLoadedAt(useAppStore().loadedAt)
}
