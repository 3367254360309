import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import overlay from '@/components/use/overlay'
import ServiceSelection from '@/views/ServiceSelection.vue'
import SiteHazardOverlay from '@/views/SiteHazardOverlay.vue'
import { useSiteStore } from '@/storage/site'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (useSiteStore().siteHazards.length > 0 && !useSiteStore().seenHazards) {
    overlay().setOverlayComponent(SiteHazardOverlay)
  }

  next()
}

export default {
  beforeEnter: guard,
  component: ServiceSelection,
  meta: {
    loadingMessage: 'Loading services...',
    showMenu: true,
  },
  name: 'serviceSelection',
  path: '/services/selection',
}
