import ServiceFrequency from '@/views/ServiceFrequency.vue'

export default {
  component: ServiceFrequency,
  meta: {
    loadingMessage: 'Loading service frequencies...',
    returnTo: 'serviceSelection',
    showMenu: true,
  },
  name: 'serviceFrequency',
  path: '/services/frequencies/:serviceType',
}
