import { checkSessionIsValid } from '@/services/api/sessionValidation'
import resetStore from '@/components/use/resetStore'
import config from '@/config'
import Log from '@/utilities/Log'
import { storeToRefs } from 'pinia'
import { useSessionStore } from '@/storage/session'

export async function sessionValidator(): Promise<boolean> {
  const { siteSessionId } = storeToRefs(useSessionStore())
  const userCheckedIn = siteSessionId.value !== null

  if (!userCheckedIn) {
    return true
  }

  const response = await checkSessionIsValid()

  if (!response.sessionIsValid) {
    resetStore()
    Log.storeReset('sessionValidation.ts')
    window.location.replace(config.redirects.sessionExpired)
  }

  return response.sessionIsValid
}
