import terminateSession from '@/handlers/terminateSession'
import forceCheckout from '@/handlers/forceCheckout'

export default [
  // deprecated - remove anytime after 25-03-2023
  {
    event: 'CheckedInOccurredEvent',
    handler: terminateSession,
  },
  // CheckInEvent/VisitorCheckedInEvent only captured by same user being logged in on different device (not self)
  {
    event: 'CheckedInEvent',
    handler: terminateSession,
  },
  {
    event: 'VisitorCheckedInEvent',
    handler: terminateSession,
  },
  {
    event: 'SystemCheckoutEvent',
    handler: terminateSession,
  },
  {
    event: 'ForceCheckoutEvent',
    handler: forceCheckout,
  },
]
