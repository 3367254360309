import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import PermitForm from '@/views/PermitForm.vue'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'
import { useCustomerStore } from '@/storage/customer'
import { usePermitFormStore } from '@/storage/permitForm'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { hasCheckList, needsPermitPerService } =
    storeToRefs(useCustomerStore())
  const { hasSubmittedStartPermit } = storeToRefs(usePermitFormStore())
  const { isVisitor } = storeToRefs(useUserLogin())
  const userHasntCompletedPermit = !hasSubmittedStartPermit.value

  const shouldShowStartPermit =
    hasCheckList.value &&
    (userHasntCompletedPermit || needsPermitPerService.value)

  if (shouldShowStartPermit) {
    next()
  } else if (isVisitor.value) {
    next({ name: 'readyToCheckout' })
  } else {
    next({ name: 'serviceSelection' })
  }
}

export default {
  beforeEnter: guard,
  component: PermitForm,
  meta: {
    loadingMessage: 'Loading permit form...',
    showMenu: true,
  },
  name: 'startPermit',
  path: '/permit-form/start',
}
