import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserVisitorCompaniesStore = defineStore(
  'userVisitorCompanies',
  () => {
    /** state */
    const names = ref<string[]>([])
    const lastSelected = ref<string>('')

    /** getters */

    /** actions */
    function setVisitorCompanyName(value: string) {
      names.value.push(value)
      names.value = [...new Set(names.value)]
    }

    function setLastUsedCompany(value: string) {
      lastSelected.value = value
    }
    return {
      lastSelected,
      names,
      setLastUsedCompany,
      setVisitorCompanyName,
    }
  },
  {
    persist: true,
  },
)
