import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type CompletedFrequencyType = {
  frequencyId: number[]
  serviceId: number | null
  subServiceId: number | null
}

export const useFrequenciesStore = defineStore(
  'frequencies',
  () => {
    /** state */
    const previouslyCompleted = ref([] as CompletedFrequencyType[])

    /** getters */
    const isSelectedFrequencyDefault = computed(
      () => selectedFrequency.value.length === 0,
    )

    const selectedFrequency = ref([] as number[])

    /** actions */
    function setSelectedFrequency(value: number[]): void {
      selectedFrequency.value = value
    }

    function setCompleted(
      serviceId: number | null,
      subServiceId: number | null,
      frequencyId: number,
    ) {
      const completionRecordExists = !!previouslyCompleted.value.find(
        (record) =>
          record.serviceId === serviceId &&
          record.subServiceId === subServiceId,
      )?.serviceId

      if (completionRecordExists) {
        previouslyCompleted.value.forEach((frequency) => {
          if (
            frequency.serviceId !== serviceId ||
            frequency.subServiceId !== subServiceId
          ) {
            return null
          }

          frequency.frequencyId.push(frequencyId)
          frequency.frequencyId = [...new Set(frequency.frequencyId)]
        })
      } else {
        previouslyCompleted.value.push({
          frequencyId: [frequencyId],
          serviceId: serviceId,
          subServiceId: subServiceId,
        })
      }
    }

    function resetFrequencies() {
      previouslyCompleted.value = []
      selectedFrequency.value = []
    }

    return {
      isSelectedFrequencyDefault,
      previouslyCompleted,
      resetFrequencies,
      selectedFrequency,
      setCompleted,
      setSelectedFrequency,
    }
  },
  {
    persist: true,
  },
)
