import axios from 'axios'
import config from '@/config'

export type ServiceRecord = {
  id: number
  serviceName: string
  siteName: string
  date?: string
  frequencyCodes?: (string | null)[]
}
export let controller: AbortController = new AbortController()

export const getServiceRecordByUser = async (
  userId: number,
  page: string,
  searchQuery: string | null = null,
  serviceDate: string | null = null,
): Promise<ServiceRecord[] | undefined> => {
  controller = new AbortController()
  try {
    const response = await axios.get('/api/service-records', {
      params: {
        page: page,
        perPage: config.pagination.sites.perPage.toString(),
        searchQuery: searchQuery,
        serviceDate: serviceDate,
      },
      signal: controller.signal,
    })
    // make sure request has not been cancelled
    return response?.data?.data ?? []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message !== 'canceled') {
      return []
    }
  }
}
