<template>
  <div class="p-2">
    <TitleBanner title="Register" />
    <form class="my-2 space-y-5" novalidate @submit.prevent="validateMobile">
      <PhoneNumberInput
        :initial-phone-number="phoneNumber"
        :default-country-code="country.countryCode"
        :disabled="true"
      />
      <BaseInput
        v-model:input-value="firstName"
        input-mode="text"
        identifier="visitor-first-name"
        label="First Name"
        :required="true"
        :is-valid="errorMessage.firstName === ''"
        :disabled="visitorDetailsSaved"
      />
      <span v-if="!visitorDetailsSaved" class="error m-1 !my-0 block">
        {{ errorMessage.firstName }}
      </span>
      <BaseInput
        v-model:input-value="lastName"
        input-mode="text"
        identifier="visitor-last-name"
        label="Last Name"
        :required="true"
        :is-valid="errorMessage.lastName === ''"
        :disabled="visitorDetailsSaved"
      />
      <span v-if="!visitorDetailsSaved" class="error m-1 !my-0 block">
        {{ errorMessage.lastName }}
      </span>
      <BaseInput
        v-model:input-value="pin"
        input-mode="numeric"
        identifier="visitor-pin"
        label="Create Pin"
        :required="true"
        :maxlength="4"
        :is-valid="errorMessage.pin === ''"
        :disabled="visitorDetailsSaved"
        @key-pressed="allowNumericOnly"
      />
      <span v-if="!visitorDetailsSaved" class="error m-1 !my-0 block">
        {{ errorMessage.pin }}
      </span>
      <BaseInput
        v-if="visitorDetailsSaved"
        v-model:input-value="smsVerificationCode"
        input-mode="numeric"
        identifier="visitor-verification-code"
        label="SMS Verification Code"
        :required="true"
        :maxlength="6"
        :is-valid="errorMessage.smsVerificationCode === ''"
        @key-pressed="allowNumericOnly"
      />
      <span v-if="visitorDetailsSaved" class="error m-1 !my-0 block">
        {{ errorMessage.smsVerificationCode }}
      </span>
      <PrimaryButton
        v-if="!visitorDetailsSaved"
        :key="saveButton"
        identifier="save-visitor"
        type="button"
        action="Save"
        @click.once="save"
      />
      <PrimaryButton
        v-if="visitorDetailsSaved"
        :key="validateMobileButton"
        identifier="validate-mobile"
        type="submit"
        action="Validate Mobile Number"
      />
    </form>
    <LoadingView :is-loading="isLoading" message="Sending registration..." />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, Ref, ref, watch } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import BaseInput from '@/components/BaseInput.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import PhoneNumberInput from '@/components/PhoneNumberInput.vue'
  import useCountry from '@/components/use/countries'
  import { useNativeScanStore } from '@/storage/nativeScan'
  import { onBeforeRouteLeave, useRouter } from 'vue-router'
  import {
    registerVisitor,
    validateMobileNumber,
  } from '@/services/api/visitorRegistration'
  import useAuth from '@/components/use/auth'
  import { User } from '@/services/api/user'
  import LoadingView from '@/views/LoadingView.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'

  type FormFieldName = 'firstName' | 'lastName' | 'pin' | 'smsVerificationCode'

  export default defineComponent({
    name: 'VisitorRegistration',
    components: {
      BaseInput,
      LoadingView,
      PhoneNumberInput,
      PrimaryButton,
      TitleBanner,
    },

    setup: function () {
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const {
        setPhoneNumber,
        setUserLoginFirstName,
        setUserLoginIsVisitor,
        setUserLoginExists,
      } = useUserLogin()
      const { isPhoneNumberDefault, phoneNumber } = storeToRefs(useUserLogin())
      const { checkUserIdentity } = useAuth()
      const router = useRouter()
      const { id: siteId } = storeToRefs(useSiteStore())
      const { fingerprint } = storeToRefs(useFingerprintStore())

      const country = getCountryByInternationalPhoneNumber(phoneNumber.value)
      const firstName = ref('')
      const lastName = ref('')
      const pin = ref('')
      const smsVerificationCode = ref('')
      const errorMessage = reactive({
        firstName: '',
        lastName: '',
        pin: '',
        smsVerificationCode: '',
      })
      const saveButton = ref(0)
      const validateMobileButton = ref(0)
      const visitorDetailsSaved = ref(false)
      const inactiveUserId = ref(0)
      const isLoading = ref(false)

      watch(firstName, () => showErrorOnEmptyField('firstName', firstName))
      watch(lastName, () => showErrorOnEmptyField('lastName', lastName))
      watch(pin, () => showErrorOnEmptyField('pin', pin))
      watch(smsVerificationCode, () =>
        showErrorOnEmptyField('smsVerificationCode', smsVerificationCode),
      )

      onBeforeRouteLeave((to) => {
        if (to.name === 'login') {
          setPhoneNumber('')
          setUserLoginFirstName('')
          setUserLoginExists(false)
          setUserLoginIsVisitor(false)
        }
      })

      function showErrorOnEmptyField(
        fieldName: FormFieldName,
        fieldValue: Ref<string | number | undefined>,
      ) {
        if (fieldValue.value === '') {
          errorMessage[fieldName] = 'Field cannot be empty'
        } else {
          errorMessage[fieldName] = ''
        }
      }

      function allowNumericOnly(keyPressEvent: {
        key: string
        which: number
        returnValue: boolean
        target: { value: string }
      }): void {
        const key = keyPressEvent.key
        const regex = /^[0-9]+$/
        if (!regex.test(key)) {
          keyPressEvent.returnValue = false
        }
      }

      function hasError() {
        return (
          errorMessage.firstName !== '' ||
          errorMessage.lastName !== '' ||
          errorMessage.pin !== ''
        )
      }

      async function save() {
        showErrorOnEmptyField('firstName', firstName)
        showErrorOnEmptyField('lastName', lastName)
        showErrorOnEmptyField('pin', pin)

        if (hasError()) {
          saveButton.value += 1
          return
        }

        isLoading.value = true
        const response = await registerVisitor(
          phoneNumber.value,
          firstName.value,
          lastName.value,
          pin.value,
        )

        if (response.status === 200) {
          inactiveUserId.value = response.data.data.id
          visitorDetailsSaved.value = true
        }
        isLoading.value = false
      }

      async function validateMobile() {
        showErrorOnEmptyField('smsVerificationCode', smsVerificationCode)

        if (hasError()) {
          validateMobileButton.value += 1
          return
        }

        isLoading.value = true
        try {
          const visitor = await validateMobileNumber(
            inactiveUserId.value,
            smsVerificationCode.value,
          )
          await autoLoginVisitor(visitor)
          if (useNativeScanStore().uuid?.length === 36 && siteId.value > 0) {
            await router.replace({ name: 'confirmNearbySite' })
          } else {
            await router.replace({ name: 'selectNearbySite' })
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (exception: unknown) {
          errorMessage.smsVerificationCode = 'Incorrect code'
          validateMobileButton.value += 1
          isLoading.value = false
        }

        isLoading.value = false
      }

      async function autoLoginVisitor(visitor: User) {
        setUserLoginFirstName(visitor.firstname)
        setUserLoginExists(visitor.id > 0)
        if (isPhoneNumberDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              phoneNumber: phoneNumber.value,
            },
            ['InvalidStore', 'StoreUserLogin', 'AutoLogin'],
          )
        }
        await checkUserIdentity(phoneNumber.value, pin.value)
      }

      return {
        allowNumericOnly,
        country,
        errorMessage,
        firstName,
        isLoading,
        lastName,
        phoneNumber,
        pin,
        save,
        saveButton,
        smsVerificationCode,
        validateMobile,
        validateMobileButton,
        visitorDetailsSaved,
      }
    },
  })
</script>
