import axios from 'axios'
import { ImageType } from '@verifiedit/form-generator'

export const storeImage = async function (
  customerId: number | null,
  siteId: number | null,
  userId: number | undefined,
  imageData: string,
  imageTypeId: number,
  sequenceOfPhoto: number,
  serviceId?: number | undefined,
  subServiceId?: number | undefined,
): Promise<number> {
  const response = await axios.post('/api/images', {
    customerId: customerId,
    imageData: imageData,
    imageTypeId: imageTypeId,
    sequence: sequenceOfPhoto,
    serviceId: serviceId,
    siteId: siteId,
    subServiceId: subServiceId,
  })
  return response?.data?.data?.id
}
export const removeImage = async function (imageId: number) {
  const response = await axios.delete(`/api/images/${imageId}`)
  return response?.data?.data?.code
}

export const getImages = async function (
  imageIds: number[],
): Promise<ImageType[]> {
  const response = await axios.get('/api/images', {
    params: {
      imageIds: imageIds,
    },
  })
  return response.data.data
}
