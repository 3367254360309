import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { usePermitFormStore } from '@/storage/permitForm'
import PermitForm from '@/views/PermitForm.vue'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (usePermitFormStore().endPermit.length !== 0) {
    next()
  } else {
    next({ name: 'checkout' })
  }
}

export default {
  beforeEnter: guard,
  component: PermitForm,
  meta: {
    loadingMessage: 'Loading permit form...',
    showMenu: true,
  },
  name: 'endPermit',
  path: '/permit-form/end',
}
