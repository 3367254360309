import { useAppStore } from '@/storage/app'

export default function setTQueryParam(to) {
  if (!to.query.t) {
    to.query.t = useAppStore().loadedAt
    return to
  } else {
    return
  }
}
