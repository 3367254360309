import axios from 'axios'
import { addOrAppendAxiosHeader } from '@/services/axios'
import { storeToRefs } from 'pinia'
import { useSessionStore } from '@/storage/session'

const siteSessionRequestInterceptor = (): void => {
  axios.interceptors.request.use(
    function (config) {
      const { siteSessionId } = storeToRefs(useSessionStore())

      if (siteSessionId.value === null) {
        return Promise.resolve(config)
      }

      config.headers = addOrAppendAxiosHeader(
        'x-verified-site-session-id',
        `${siteSessionId.value}`,
        config.headers,
      )

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    },
  )
}

export default siteSessionRequestInterceptor
