<template>
  <div v-show="withMenu">
    <Bars3Icon class="hamburger-menu h-6 w-6 text-white" @click="toggleMenu" />
    <Transition name="fade">
      <div
        v-if="show"
        class="fixed left-0 top-0 z-10 flex h-screen w-full flex-row-reverse bg-gray-400 bg-opacity-60"
      >
        <div class="w-8/12 bg-white">
          <ul class="p-3">
            <HamburgerMenuItem
              v-if="documentsAvailable"
              id="documents"
              label="Documents"
              @option-clicked="showViewDocumentsOverlay"
            >
              <DocumentIcon class="mr-4 h-6 w-6 text-gray-500" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              id="my-history"
              label="My History"
              @option-clicked="showUserHistoryOverlay"
            >
              <ClockIcon class="mr-4 h-6 w-6 text-gray-700" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              v-if="shouldShowSpotChecks"
              id="spot-checks"
              label="Permit Spot Check"
              @option-clicked="showSpotCheckOverlay"
            >
              <ClipboardDocumentCheckIcon class="mr-4 h-6 w-6 text-gray-700" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              v-if="canCheckout"
              id="checkout"
              label="Checkout"
              @option-clicked="goToCheckout"
            >
              <ArrowRightStartOnRectangleIcon
                class="mr-3 h-6 w-7 text-gray-700"
              />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              v-if="canViewSiteEntry"
              id="site-entry"
              label="Site Entry Confirmation"
              @option-clicked="showSiteEntryConfirmationOverlay"
            >
              <CheckCircleIcon class="mr-3 h-6 w-7 text-gray-700" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              v-if="hasSiteHazards"
              id="site-hazard"
              label="Site Hazards"
              @option-clicked="showSiteHazardOverlay"
            >
              <ExclamationTriangleIcon class="mr-3 h-6 w-7 text-gray-700" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              v-if="hasNotifications"
              id="current-notifications"
              label="Notifications"
              @option-clicked="showCurrentNotificationsOverlay"
            >
              <BellIcon class="mr-3 h-6 w-7 text-gray-700" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              id="change-pin"
              label="Change PIN"
              @option-clicked="showChangePinOverlay"
            >
              <PencilSquareIcon class="mr-4 h-6 w-6 text-gray-500" />
            </HamburgerMenuItem>
            <HamburgerMenuItem
              id="help"
              label="Help"
              @option-clicked="showUserHelpOverlay"
            >
              <QuestionMarkCircleIcon class="mr-4 h-6 w-6 text-gray-500" />
            </HamburgerMenuItem>
            <li
              id="terms-and-conditions"
              class="mt-6 text-left text-sm text-blue-700 underline"
            >
              <a
                href="https://webcontent.verified.com.au/Terms-and-conditions/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms & Conditions
              </a>
            </li>
            <li
              id="privacy-policy"
              class="mt-2 text-left text-sm text-blue-700 underline"
            >
              <a
                href="https://webcontent.verified.com.au/privacy-policy/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div class="w-4/12 opacity-100" @click="show = false"></div>
      </div>
    </Transition>
    <CheckoutConfirmation
      class="z-20"
      :show="shouldConfirmCheckout"
      @confirmed="confirmCheckout"
      @cancelled="shouldConfirmCheckout = false"
    >
      <p>
        You have not submitted a record for work order:
        {{ selectedWorkOrder?.reference }}
      </p>
      <p>Do you wish to checkout?</p>
    </CheckoutConfirmation>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import { Bars3Icon, QuestionMarkCircleIcon } from '@heroicons/vue/24/solid'
  import {
    ArrowRightStartOnRectangleIcon,
    BellIcon,
    CheckCircleIcon,
    ClipboardDocumentCheckIcon,
    ClockIcon,
    DocumentIcon,
    ExclamationTriangleIcon,
    PencilSquareIcon,
  } from '@heroicons/vue/24/outline'
  import { useRouter } from 'vue-router'
  import HamburgerMenuItem from '@/components/HamburgerMenuItem.vue'
  import ViewDocuments from '@/views/ViewDocuments.vue'
  import overlay from '@/components/use/overlay'
  import UserHistory from '@/views/UserHistory.vue'
  import SiteEntryConfirmationOverlay from '@/views/SiteEntryConfirmationOverlay.vue'
  import SiteHazardOverlay from '@/views/SiteHazardOverlay.vue'
  import ChangePin from '@/views/ChangePin.vue'
  import UserHelp from '@/views/UserHelp.vue'
  import CheckoutConfirmation from '@/components/CheckoutConfirmation.vue'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useSiteStore } from '@/storage/site'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { useNotificationsStore } from '@/storage/notifications'
  import CurrentNotificationsOverlay from '@/views/CurrentNotificationsOverlay.vue'
  import SpotCheckFormOverlay from '@/views/SpotCheckFormOverlay.vue'
  import { usePermitFormSubmissionsStore } from '@/storage/permitFormSubmissions'

  export default defineComponent({
    name: 'HamburgerMenu',
    components: {
      ArrowRightStartOnRectangleIcon,
      Bars3Icon,
      BellIcon,
      CheckCircleIcon,
      CheckoutConfirmation,
      ClipboardDocumentCheckIcon,
      ClockIcon,
      DocumentIcon,
      ExclamationTriangleIcon,
      HamburgerMenuItem,
      PencilSquareIcon,
      QuestionMarkCircleIcon,
    },

    props: {
      withMenu: {
        type: Boolean,
        default: false,
      },
    },

    setup() {
      const show = ref<boolean>(false)
      const { setOverlayComponent } = overlay()
      const router = useRouter()
      const { selectionConfirmed, siteDocuments, siteHazards } =
        storeToRefs(useSiteStore())
      const { customerDocuments, hasWorkOrder, getHasSpotChecks } =
        storeToRefs(useCustomerStore())
      const { canCheckout, canViewSiteEntry } = storeToRefs(
        useSiteEntryConfirmationStore(),
      )
      const { permitFormSubmitted } = storeToRefs(
        usePermitFormSubmissionsStore(),
      )
      const { hasSelectedWorkOrder, selectedWorkOrder } =
        storeToRefs(useWorkOrderStore())

      const hasNotifications = computed(
        () => useNotificationsStore().notifications.length > 0,
      )

      const shouldShowSpotChecks = computed(
        () => getHasSpotChecks.value && permitFormSubmitted.value,
      )

      const documentsAvailable = computed(
        () =>
          (siteDocuments.value.length > 0 ||
            customerDocuments.value.length > 0) &&
          selectionConfirmed.value,
      )
      const hasSiteHazards = computed(
        () => selectionConfirmed.value && siteHazards.value.length > 0,
      )
      const shouldConfirmCheckout = ref(false)
      const checkoutConfirmed = ref(false)

      function toggleMenu() {
        show.value = !show.value
      }

      function showChangePinOverlay() {
        setOverlayComponent(ChangePin)
        toggleMenu()
      }

      function showSiteEntryConfirmationOverlay() {
        setOverlayComponent(SiteEntryConfirmationOverlay)
        toggleMenu()
      }

      function showSiteHazardOverlay() {
        setOverlayComponent(SiteHazardOverlay)
        toggleMenu()
      }

      function showUserHelpOverlay() {
        setOverlayComponent(UserHelp)
        toggleMenu()
      }

      function showUserHistoryOverlay() {
        setOverlayComponent(UserHistory)
        toggleMenu()
      }

      function showSpotCheckOverlay() {
        setOverlayComponent(SpotCheckFormOverlay, false)
        toggleMenu()
      }

      function showViewDocumentsOverlay() {
        setOverlayComponent(ViewDocuments)
        toggleMenu()
      }

      function showCurrentNotificationsOverlay() {
        setOverlayComponent(CurrentNotificationsOverlay)
        toggleMenu()
      }

      async function goToCheckout() {
        overlay().hideOverlay()
        toggleMenu()

        const workOrderSelected =
          hasWorkOrder.value && hasSelectedWorkOrder.value

        if (workOrderSelected) {
          shouldConfirmCheckout.value = true
          return
        }

        await router.replace({ name: 'checkout' })
      }

      async function confirmCheckout() {
        await router.replace({ name: 'checkout' })
        shouldConfirmCheckout.value = false
      }

      return {
        canCheckout,
        canViewSiteEntry,
        checkoutConfirmed,
        confirmCheckout,
        documentsAvailable,
        goToCheckout,
        hasNotifications,
        hasSiteHazards,
        router,
        selectedWorkOrder,
        shouldConfirmCheckout,
        shouldShowSpotChecks,
        show,
        showChangePinOverlay,
        showCurrentNotificationsOverlay,
        showSiteEntryConfirmationOverlay,
        showSiteHazardOverlay,
        showSpotCheckOverlay,
        showUserHelpOverlay,
        showUserHistoryOverlay,
        showViewDocumentsOverlay,
        toggleMenu,
      }
    },
  })
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
