import resourceFunction from '@/services/api/inductionForm'
import ReportableException from '@/exceptions/ReportableException'

export function loadResource(resource: string) {
  const [functionName, siteOrCustomerId, documentId] =
    extractFunctionNameAndArguments(resource)

  if (!Object.prototype.hasOwnProperty.call(resourceFunction, functionName)) {
    throw new ReportableException(
      'Undefined resource function',
      {
        availableResourceFunctions: Object.keys(resourceFunction),
        functionName: functionName,
        resource: resource,
      },
      ['SchemaError'],
    )
  }
  return resourceFunction[functionName].apply(resourceFunction, [
    Number(siteOrCustomerId),
    Number(documentId),
  ])
}

function extractFunctionNameAndArguments(resource: string) {
  return resource.replace('@', '').replace('(', ',').replace(')', '').split(',')
}
