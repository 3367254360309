class ReportableException extends Error {
  public context: Record<string, unknown>
  public name = 'ReportableException'
  public tags: string[]

  constructor(
    message: string,
    context: Record<string, unknown>,
    tags: string[] = [],
  ) {
    super(message)
    this.context = context
    this.tags = tags
  }
}

export default ReportableException
