import axios from 'axios'
import { addOrAppendAxiosHeader } from '@/services/axios'
import { storeToRefs } from 'pinia'
import { useFingerprintStore } from '@/storage/fingerprint'

const fingerprintRequestInterceptor = (): void => {
  axios.interceptors.request.use(
    function (config) {
      const { fingerprint } = storeToRefs(useFingerprintStore())

      config.headers = addOrAppendAxiosHeader(
        'x-verified-fp-request-id',
        `${fingerprint.value?.requestId}`,
        config.headers,
      )
      config.headers = addOrAppendAxiosHeader(
        'x-verified-fp-visitor-id',
        `${fingerprint.value?.visitorId}`,
        config.headers,
      )

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    },
  )
}

export default fingerprintRequestInterceptor
