import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { supplementalFormRouteMap } from '@/config/supplementalFormRouteMap'
import { useSupplementalFormStore } from '@/storage/supplementalForms'
import { storeToRefs } from 'pinia'

const supplementalFormGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { setCurrentSupplementalForm, setRouteTo, setRouteFrom } =
    useSupplementalFormStore()
  const { routeFrom, forms } = storeToRefs(useSupplementalFormStore())

  const fromRoute = from.name === 'supplementalForm' ? routeFrom.value : from

  const supplemental = forms.value.find((form) =>
    supplementalFormRouteMap
      .get(form.after)
      ?.includes(fromRoute?.name as string),
  )

  const notCheckingOut =
    to.name === 'serviceSelection' && fromRoute?.name === 'serviceCheckout'

  if (to.name === 'supplementalForm' && forms.value.length === 0) {
    next({ name: routeFrom.value?.name as string })
  } else if (notCheckingOut) {
    next()
  } else if (to.name !== 'supplementalForm' && !!supplemental) {
    setCurrentSupplementalForm(supplemental)
    setRouteFrom({
      name: fromRoute?.name,
      params: fromRoute?.params,
    } as RouteLocationNormalized)
    setRouteTo({
      name: to.name as string,
      params: to.params,
    } as RouteLocationNormalized)

    next({
      name: 'supplementalForm',
    })
  } else {
    next()
  }
}

export { supplementalFormGuard }
