import axios from 'axios'
import ReportableException from '@/exceptions/ReportableException'
import { MessageResponse } from '@/services/api/user'

export const logout = async (): Promise<MessageResponse> => {
  try {
    const response = await axios.post(`/api/auth/logout`)
    return response?.data?.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new ReportableException('Logout error', { error: error }, ['Logout'])
  }
}
