<template>
  <div class="grid grid-cols-12">
    <section class="col-span-5">
      <CountryCodeSelector
        :default-country-code="defaultCountryCode"
        :disabled="disabled"
        class="country-code-selector"
        :classes="[{ 'rounded-r-none': true }]"
        @select-country="updateCountry"
      ></CountryCodeSelector>
    </section>
    <section class="col-span-7 pt-1">
      <BaseInput
        v-model:input-value="phoneNumber"
        :input-classes="[
          { 'rounded-l-none': true },
          { border: true },
          { 'border-l-0': true },
        ]"
        :required="true"
        input-mode="numeric"
        identifier="phone-number-input"
        label="Phone Number"
        :is-valid="isValid"
        pattern="[0-9]*"
        :maxlength="maxLength"
        :disabled="disabled"
        @update:input-value="phoneNumberChanged"
        @key-pressed="allowNumericOnly"
      ></BaseInput>
    </section>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import BaseInput from '@/components/BaseInput.vue'
  import CountryCodeSelector from '@/components/CountryCodeSelector.vue'
  import useCountry from '@/components/use/countries'
  import config, { country } from '@/config'

  export default defineComponent({
    name: 'PhoneNumberInput',
    components: { BaseInput, CountryCodeSelector },

    props: {
      defaultCountryCode: {
        type: String,
        default: 'AU',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      initialPhoneNumber: {
        type: String,
        default: '',
      },
    },

    emits: ['phoneNumberEntered', 'selectedCountry'],
    setup(props, { emit }) {
      const { getCountryByInternationalPhoneNumber } = useCountry()

      const internationalPhoneNumber = ref<string>('')
      const isValid = ref<boolean>(false)
      const phoneNumber = ref<string>('')
      const previousPhoneNumberLength = ref<number>(0)
      const selectedCountry = ref<country>(
        config.countries.find(
          (country) => country.countryCode == props.defaultCountryCode,
        ) || config.countries[0],
      )

      onMounted(() => {
        if (props.initialPhoneNumber.substr(0, 1) === '+') {
          // determine the country this phone number belongs to
          selectedCountry.value = getCountryByInternationalPhoneNumber(
            props.initialPhoneNumber,
          )?.value
          internationalPhoneNumber.value = props.initialPhoneNumber
          phoneNumber.value = convertToLocal(props.initialPhoneNumber)
        }

        phoneNumber.value =
          selectedCountry.value?.phoneNumberDisplayDecorator(
            phoneNumber.value.replace(/\s/g, ''),
          ) || ''
      })

      const maxLength = computed((): number | undefined => {
        return selectedCountry.value?.maxLength ?? Number.POSITIVE_INFINITY
      })

      watch(phoneNumber, () => {
        if (validatePhoneNumber(internationalPhoneNumber.value)) {
          isValid.value = true
          emit('phoneNumberEntered', internationalPhoneNumber.value)
        } else {
          isValid.value = false
          emit('phoneNumberEntered', '')
        }
      })

      watch(selectedCountry, () => {
        phoneNumber.value = ''
      })

      const allowNumericOnly = function (keyPressEvent: {
        key: string
        which: number
        returnValue: boolean
        target: { value: string }
      }): void {
        const key = keyPressEvent.key
        const regex = /[0-9]/
        if (!regex.test(key)) {
          keyPressEvent.returnValue = false
        }
      }

      const cleanUpPhoneNumber = function (phoneNumber: string): void {
        let cleanPhoneNumber: string
        cleanPhoneNumber = removeLeadingZero(phoneNumber)
        cleanPhoneNumber = removeSpace(cleanPhoneNumber)
        if (selectedCountry.value) {
          internationalPhoneNumber.value =
            selectedCountry.value.countryCallingCode.concat(cleanPhoneNumber)
        }
      }

      const convertToLocal = function (phoneNumber: string): string {
        if (
          selectedCountry.value?.countryCallingCode ===
          phoneNumber.substr(
            0,
            selectedCountry.value?.countryCallingCode.length,
          )
        ) {
          return (
            '0' +
            phoneNumber.substr(selectedCountry.value?.countryCallingCode.length)
          )
        }

        return phoneNumber
      }

      const phoneNumberChanged = function (phoneNumberEntered: string): void {
        cleanUpPhoneNumber(phoneNumberEntered)
        phoneNumber.value =
          selectedCountry.value?.phoneNumberDisplayDecorator(
            phoneNumberEntered.replace(/\s/g, ''),
          ) || ''
      }

      const removeLeadingZero = function (phoneNumber: string): string {
        if (phoneNumber.substring(0, 1) === '0') {
          return phoneNumber.substring(1)
        } else {
          return phoneNumber
        }
      }

      const removeSpace = function (phoneNumber: string): string {
        return phoneNumber.replace(/\s/g, '')
      }

      const updateCountry = function (country: country): void {
        selectedCountry.value = country
        emit('selectedCountry', country)
      }

      const validatePhoneNumber = function (phoneNumber: string): boolean {
        return selectedCountry.value?.validatePhoneNumber(phoneNumber) || false
      }

      return {
        allowNumericOnly,
        internationalPhoneNumber,
        isValid,
        maxLength,
        phoneNumber,
        phoneNumberChanged,
        previousPhoneNumberLength,
        updateCountry,
      }
    },
  })
</script>
