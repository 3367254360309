import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import SiteEntryConfirmation from '@/views/SiteEntryConfirmation.vue'
import { useCustomerStore } from '@/storage/customer'
import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
import { useUserStore } from '@/storage/user'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const canContinue = useSiteEntryConfirmationStore().canContinue
  const userIsCheckedIn = useUserStore().checkedInTime !== null

  useSiteEntryConfirmationStore().setCanCheckout(canContinue && userIsCheckedIn)

  if (useCustomerStore().shouldShowSiteEntry) {
    next()
  } else {
    next({ name: 'startPermit' })
  }
}

export default {
  beforeEnter: guard,
  component: SiteEntryConfirmation,
  meta: {
    loadingMessage: 'Loading site entry...',
    showMenu: false,
  },
  name: 'siteEntryConfirmation',
  path: '/site-entry-confirmation',
}
