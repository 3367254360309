export interface country {
  countryCallingCode: string
  countryCode: string
  maxLength: number
  minLength: number
  phoneNumberDisplayDecorator: phoneNumberDisplayDecoratorFunction
  validatePhoneNumber: validatePhoneNumberFunction
  showBtn: boolean
}

type validatePhoneNumberFunction = (phoneNumber: string) => boolean
type phoneNumberDisplayDecoratorFunction = (phoneNumber: string) => string

const australia: country = {
  countryCallingCode: '+61',
  countryCode: 'AU',
  maxLength: 12,
  minLength: 12,
  phoneNumberDisplayDecorator: function (phoneNumber: string): string {
    const regex = /([0-9]{1,4})([0-9]{0,3})([0-9]{0,3})/
    const matches = phoneNumber.match(regex) || []
    matches.shift()

    return matches.filter((match) => match !== '').join(' ')
  },
  showBtn: false,
  validatePhoneNumber: function (phoneNumber: string): boolean {
    const formatIsValid = /^\+614[0-9]{8}$/
    return formatIsValid.test(phoneNumber)
  },
}

const newZealand: country = {
  countryCallingCode: '+64',
  countryCode: 'NZ',
  maxLength: 13,
  minLength: 11,
  phoneNumberDisplayDecorator: function (phoneNumber: string): string {
    const regex = /([0-9]{1,4})([0-9]{0,3})([0-9]{0,4})/
    const matches = phoneNumber.match(regex) || []
    matches.shift()

    return matches.filter((match) => match !== '').join(' ')
  },
  showBtn: true,
  validatePhoneNumber: function (phoneNumber: string): boolean {
    const formatIsValid = /^\+642[0-9]{7,9}$/
    return formatIsValid.test(phoneNumber)
  },
}

export const countries = [australia, newZealand]
