import axios from 'axios'
import { User } from '@/services/api/user'
import ReportableException from '@/exceptions/ReportableException'

export async function registerVisitor(
  phoneNumber: string,
  firstName: string,
  lastName: string,
  pin: string,
) {
  return await axios.post('api/users/register', {
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    pin: pin,
  })
}

export async function validateMobileNumber(
  inactiveUserId: number,
  verificationCode: string,
): Promise<User> {
  const response = await axios.get(
    `api/users/register/${inactiveUserId}/verify/${verificationCode}`,
  )
  if (response.status === 204) {
    throw new ReportableException('Incorrect code', { response: response })
  }
  return response.data.data
}
