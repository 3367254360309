<template>
  <button
    class="secondary"
    :class="[identifier, buttonClasses]"
    :type="type"
    :disabled="disabled"
    @click.once="click"
  >
    <span
      class="w-full"
      :class="{ 'flex items-center justify-center space-x-3': icon }"
    >
      <span>
        {{ action }}
      </span>
      <Component :is="icon" v-if="icon" class="h-5 w-5" />
    </span>
  </button>
</template>

<script lang="ts">
  import {
    ButtonHTMLAttributes,
    defineComponent,
    type FunctionalComponent,
    type HTMLAttributes,
    PropType,
    type VNodeProps,
  } from 'vue'

  export default defineComponent({
    name: 'SecondaryButton',

    props: {
      action: {
        type: String,
        default: 'Continue',
      },

      buttonClasses: {
        type: String,
        default: '',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      icon: {
        default() {
          return null
        },

        type: Function as PropType<
          FunctionalComponent<HTMLAttributes & VNodeProps>
        >,
      },

      identifier: {
        type: String,
        default: '',
      },

      type: {
        type: String as PropType<ButtonHTMLAttributes['type']>,
        default: 'button',
      },
    },

    emits: ['click'],

    setup(props, { emit }) {
      const click = function (event: unknown) {
        emit('click', event)
      }

      return {
        click,
      }
    },
  })
</script>
