import axios from 'axios'

export type BypassReasonContract = {
  id: number
  name: string
  hasDate: boolean
  hasDescription: boolean
}

export const fetchBypassReasons = async (): Promise<BypassReasonContract[]> => {
  const response = await axios.get('/api/bypass-reasons')

  return response.data.data
}
