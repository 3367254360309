import axios from 'axios'

export type CountType = {
  count: number
  type: string
}
export type WorkOrder = {
  availableResolutions: string[]
  createdAt: string
  description: string
  id: number
  reference: string
  serviceIds: number[]
  status: WorkOrderStatus
  type: 'PREVENTATIVE' | 'REACTIVE'
}
export type WorkOrderStatus =
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'OPEN'
  | 'STARTED'
  | 'CANCELLED'
  | null
export type WorkOrderStatusResponse = {
  createdAt: string
  id: number
  status: WorkOrderStatus
  workOrderId: number
}

export const getWorkOrders = async (
  customerId: number,
  siteId: number,
  contractorCompanyId: number,
): Promise<WorkOrder[]> => {
  const response = await axios.get(`/api/customers/${customerId}/work-orders`, {
    params: {
      contractorCompanyId: contractorCompanyId,
      siteId: siteId,
    },
  })

  return response.data.data
}

export const getWorkOrdersCount = async (
  siteId: number,
  contractorCompanyId: number,
): Promise<CountType> => {
  const response = await axios.get(`/api/counts/work-orders`, {
    params: {
      contractorCompanyId: contractorCompanyId,
      siteId: siteId,
    },
  })

  return response.data.data
}

export const storeWorkOrderStatus = async (
  customerId: number,
  workOrderId: number,
  serviceRecordId: number | null,
  status: WorkOrderStatus,
): Promise<WorkOrderStatusResponse> => {
  const response = await axios.post(
    `/api/customers/${customerId}/work-orders/${workOrderId}/status`,
    {
      serviceRecordId: serviceRecordId,
      status: status,
    },
  )

  return response.data.data
}

export const updateWorkOrder = async (
  customerId: number,
  workOrderId: number,
  resolution: string,
  status: WorkOrderStatus,
  serviceRecordId: number,
): Promise<WorkOrder> => {
  const response = await axios.patch(
    `/api/customers/${customerId}/work-orders/${workOrderId}`,
    {
      resolution: resolution,
      serviceRecordId: serviceRecordId,
      status: status,
    },
  )

  return response.data.data
}
