<template>
  <div>
    <SiteBanner :site-name="siteNameStatic" :site-address="siteAddressStatic" />
    <div>
      <SiteEntryGrantedOrWarning
        v-if="canContinueStatic"
        :site-entry-checks="siteEntryChecksStatic"
        :pass-static="passStatic"
      />
      <SiteEntryDenied
        v-else
        :site-entry-checks="
          siteEntryChecksStatic.filter(
            (siteEntryCheck) => !siteEntryCheck.canContinue,
          )
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
  import SiteEntryGrantedOrWarning from '@/components/SiteEntryGrantedOrWarning.vue'
  import SiteEntryDenied from '@/components/SiteEntryDenied.vue'

  export default defineComponent({
    name: 'SiteEntryInformation',
    components: {
      SiteBanner,
      SiteEntryDenied,
      SiteEntryGrantedOrWarning,
    },

    setup() {
      const { name: siteName, address: siteAddress } =
        storeToRefs(useSiteStore())
      const { pass, siteEntryChecks, canContinue } = storeToRefs(
        useSiteEntryConfirmationStore(),
      )
      const passStatic = pass.value
      const canContinueStatic = canContinue.value
      const siteEntryChecksStatic = siteEntryChecks.value

      const siteNameStatic = siteName.value
      const siteAddressStatic = siteAddress.value

      return {
        canContinueStatic,
        passStatic,
        siteAddressStatic,
        siteEntryChecksStatic,
        siteNameStatic,
      }
    },
  })
</script>
