<template>
  <div>
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <FormGenerator
      :schema="schema"
      @update-component-visibility="updateComponentVisibilityEventHandler"
      @form-changed="formChangedEventHandler"
      @visibility-changed="updateSubmittableComponents"
      @persist-photo="storePhotoEventHandler"
      @remove-photo="removePhotoEventHandler"
    />
    <div class="m-4">
      <PrimaryButton
        :key="submitButtonKey"
        action="Submit"
        identifier="induction-submit"
        :disabled="isLoading"
        @click.once="submit"
      />
    </div>
    <LoadingView :is-loading="isLoading" :message="loadingMessage" />
    <ScrollToTop />
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, reactive, ref } from 'vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import LoadingView from '@/views/LoadingView.vue'
  import {
    Answer,
    checkAnswers,
    checkConditions,
    errorMessage,
    FormGenerator,
    ImageAnswer,
    removePhoto,
    renderConditions,
    SchemaType,
    scrollToFirstError,
    storePhoto,
    SupplementalFormType,
    updateComponentVisibility,
    updateVisibleComponents,
    validateFormSubmission,
  } from '@verifiedit/form-generator'
  import {
    getInductionForm,
    submitInductionForm,
  } from '@/services/api/inductionForm'
  import { useRouter } from 'vue-router'
  import {
    CheckType,
    SiteEntryCheckType,
    useSiteEntryConfirmationStore,
  } from '@/storage/siteEntryConfirmation'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { loadResource } from '@/components/use/form'
  import SupportableException from '@/exceptions/SupportableException'
  import { removeImage, storeImage } from '@/services/api/image'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'
  import { useSupplementalFormStore } from '@/storage/supplementalForms'
  import { useUserStore } from '@/storage/user'
  import ScrollToTop from '@/components/ScrollToTop.vue'

  export default defineComponent({
    name: 'InductionForm',
    components: {
      FormGenerator,
      LoadingView,
      PrimaryButton,
      ScrollToTop,
      SiteBanner,
    },

    async setup() {
      const {
        id: siteId,
        address: siteAddress,
        name: siteName,
        isSiteStoreDefault,
      } = storeToRefs(useSiteStore())
      const {
        userType,
        selectedContractorCompanyId,
        id: userId,
        isUserTypeDefault,
        isSelectedContractorCompanyIdDefault,
        isUserStoreDefault,
      } = storeToRefs(useUserStore())
      const { isVisitor } = storeToRefs(useUserLogin())
      const { id: customerId, isCustomerStoreDefault } =
        storeToRefs(useCustomerStore())
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const schema = ref<(SchemaType & errorMessage)[]>([])
      const isLoading = ref<boolean>(true)
      const loadingMessage = ref('')
      const submittableComponentNames = ref<string[]>([])
      const answers: Map<string, Answer> = reactive(new Map())
      const router = useRouter()

      await fetchForm()
      passInResourceFunction()

      async function fetchForm() {
        if (isSiteStoreDefault.value || isUserTypeDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              siteId: siteId.value,
              userId: userId.value,
              userType: userType.value,
            },
            ['InvalidStore', 'FetchInductionForm'],
          )
        }

        try {
          schema.value = (await getInductionForm(
            siteId.value,
            userType.value.id,
          )) as (SchemaType & errorMessage)[]

          schema.value.forEach((component) => {
            component.errorMessage = ''
          })
          isLoading.value = false
        } catch (e) {
          isLoading.value = false
          if (e instanceof SyntaxError) {
            throw new SupportableException(
              'Induction form error.',
              { error: e },
              ['SchemaError'],
            )
          } else {
            throw e
          }
        }
      }

      function passInResourceFunction() {
        schema.value
          .filter((component) => typeof component.resource !== 'undefined')
          .forEach((component) => {
            component['resourceFunction'] =
              loadResource as SchemaType['resourceFunction']
          })
      }

      function formChangedEventHandler(
        changedComponentName: string,
        userInput: string | (string | ImageAnswer)[],
      ) {
        checkAnswers(changedComponentName, userInput, schema.value, answers)
      }

      const submitButtonKey = ref<number>(0)

      async function submit() {
        submitButtonKey.value += 1
        const canSubmit = validateFormSubmission(
          schema.value,
          answers,
          submittableComponentNames.value,
        )

        if (!canSubmit) {
          // eslint-disable-next-line vue/next-tick-style
          await nextTick()
          scrollToFirstError()
          return
        }

        if (
          isSiteStoreDefault.value ||
          isUserTypeDefault.value ||
          (isSelectedContractorCompanyIdDefault.value && !isVisitor.value)
        ) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              selectedContractorCompany: selectedContractorCompanyId.value,

              siteId: siteId.value,
              userId: userId.value,
              userType: userType.value,
            },
            ['InvalidStore', 'StoreInductionFormSubmission'],
          )
        }

        loadingMessage.value = 'Saving induction...'
        isLoading.value = true

        const response = await submitInductionForm(
          siteId.value,
          userType.value.id,
          selectedContractorCompanyId.value,
          Object.fromEntries(answers),
        )

        if (response.status === 200) {
          const passed =
            response.data.data?.inductionPass ?? response.data.inductionPass
          useSiteEntryConfirmationStore().addSiteEntryCheck(
            createInductionCheck(passed as boolean),
          )
          pushSupplementalFormsToStore()
          await router.replace({ name: 'visitorArrival' })
        }

        isLoading.value = false
      }

      function createInductionCheck(
        canContinue: boolean,
      ): SiteEntryCheckType<CheckType> {
        return {
          canContinue: canContinue,
          checks: [
            {
              feedback: canContinue ? '' : 'Induction requirements failed',
              name: '',
              result: canContinue ? 'Pass' : 'Stop Working',
            },
          ],

          passed: canContinue,
          title: 'Induction',
        }
      }

      function updateSubmittableComponents(components: Map<string, string>) {
        submittableComponentNames.value = updateVisibleComponents(
          components,
          answers,
        )
      }

      function pushSupplementalFormsToStore() {
        const supplementalForms = getSupplementalFormsFromSchema()
        if (Array.isArray(supplementalForms)) {
          useSupplementalFormStore().setSupplementalForms(
            getValidSupplementalFormsToAdd(supplementalForms),
          )
        }
      }

      function getSupplementalFormsFromSchema():
        | SupplementalFormType[]
        | undefined {
        return schema.value.find((obj) =>
          Object.prototype.hasOwnProperty.call(obj, 'supplementals'),
        )?.supplementals
      }

      function getValidSupplementalFormsToAdd(
        supplementalForms: SupplementalFormType[],
      ): SupplementalFormType[] {
        const supplementalFormsToAdd = new Set<SupplementalFormType>()
        supplementalForms.forEach((supplemental) => {
          if (
            Object.prototype.hasOwnProperty.call(supplemental, 'conditions')
          ) {
            const results = checkConditions(
              supplemental.conditions as renderConditions[],
              answers,
            )
            for (const result of Object.values(results)) {
              if (result.value) {
                supplementalFormsToAdd.add(supplemental)
              }
            }
          } else {
            supplementalFormsToAdd.add(supplemental)
          }
        })

        return Array.from(supplementalFormsToAdd)
      }

      function updateComponentVisibilityEventHandler(
        identifier: string,
        result: { items: string[]; value: boolean },
        key: string,
      ) {
        updateComponentVisibility(identifier, result, key, schema.value)
      }

      async function storePhotoEventHandler(
        imageData: string,
        imageTypeId: number,
        sequenceOfImage: number,
        identifier: string,
      ) {
        if (
          isCustomerStoreDefault.value ||
          isSiteStoreDefault.value ||
          isUserStoreDefault.value
        ) {
          throw new InvalidStoreException(
            {
              customerId: customerId.value,
              fingerprint: fingerprint.value,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'StoreFormPhotoUpload'],
          )
        }

        loadingMessage.value = 'Saving image...'
        isLoading.value = true

        await storePhoto(
          imageData,
          imageTypeId,
          sequenceOfImage,
          identifier,
          schema.value,
          () =>
            storeImage(
              customerId.value,
              siteId.value,
              userId.value,
              imageData,
              imageTypeId,
              sequenceOfImage,
            ),
        )
        isLoading.value = false
      }

      async function removePhotoEventHandler(
        imageId: string,
        identifier: string,
      ) {
        isLoading.value = true
        loadingMessage.value = 'Removing image...'
        await removePhoto(
          imageId,
          identifier,
          schema.value,
          answers,
          removeImage,
        )
        isLoading.value = false
      }

      return {
        formChangedEventHandler,
        isLoading,
        loadingMessage,
        removePhotoEventHandler,
        schema,
        siteAddress,
        siteName,
        storePhotoEventHandler,
        submit,
        submitButtonKey,
        updateComponentVisibilityEventHandler,
        updateSubmittableComponents,
      }
    },
  })
</script>
