import EditAccreditation from '@/views/EditAccreditation.vue'

export default {
  component: EditAccreditation,
  meta: {
    loadingMessage: 'Loading accreditations',
    showMenu: true,
  },
  name: 'editAccreditation',
  path: '/accreditations/edit',
}
