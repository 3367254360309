import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { SystemNotification } from '@/services/api/notification'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export type ViewableSystemNotification = SystemNotification & {
  seen: boolean
}
export const useNotificationsStore = defineStore(
  'notifications',
  () => {
    /** state */
    const notifications = ref<ViewableSystemNotification[]>([])

    /** getters */
    const currentNotification: ComputedRef<
      ViewableSystemNotification | undefined
    > = computed(() =>
      notifications.value.find((notification) => !notification.seen),
    )

    /** actions */
    function setSeen(value: ViewableSystemNotification) {
      value.seen = true
    }

    function mergeNotifications(newNotifications: SystemNotification[]) {
      newNotifications.forEach((newNotification) => {
        const existingNotification: ViewableSystemNotification | undefined =
          notifications.value.find(
            (notification) => notification.id === newNotification.id,
          )

        if (existingNotification) {
          existingNotification.seen = !dateIsAfterDate(
            newNotification.updatedAt,
            existingNotification.updatedAt,
          )

          existingNotification.message = newNotification.message
          existingNotification.updatedAt = newNotification.updatedAt
        } else {
          notifications.value.push(convertToViewable(newNotification))
        }
      })

      removeExistingExpiredNotifications(newNotifications)
    }

    function removeExistingExpiredNotifications(
      currentNotifications: SystemNotification[],
    ) {
      const newIds = new Set(
        currentNotifications.map((notification) => notification.id),
      )
      notifications.value = notifications.value.filter((notification) =>
        newIds.has(notification.id),
      )
    }

    return {
      currentNotification,
      mergeNotifications,
      notifications,
      setSeen,
    }
  },
  {
    persist: true,
  },
)

function convertToViewable(
  systemNotification: SystemNotification,
): ViewableSystemNotification {
  return {
    ...systemNotification,
    seen: false,
  }
}

function dateIsAfterDate(newDate: string, oldDate: string): boolean {
  const format = 'YYYY/MM/DD hh:mm:ssZ'

  return dayjs(newDate, format).isAfter(dayjs(oldDate, format))
}
