import { AuditableEventType } from '@/handlers/terminateSession'

export interface ForceCheckoutEvent extends AuditableEventType {
  siteId: number
}

function forceCheckout(data: ForceCheckoutEvent) {
  // todo: notify user that they were checked out of previous site
  console.log('ForceCheckoutEvent occurred', data)
  return
}

export default forceCheckout
