import LocationHelp from '@/views/help/LocationHelp.vue'

export default {
  component: LocationHelp,
  meta: {
    loadingMessage: 'Loading help...',
    returnTo: 'login',
    showMenu: false,
  },
  name: 'helpLocationPermission',
  path: '/help/position/permission',
}
