import ContractorCompanySelection from '@/views/ContractorCompanySelection.vue'

export default {
  component: ContractorCompanySelection,
  meta: {
    loadingMessage: 'Loading companies...',
    returnTo: 'login',
    showMenu: true,
  },
  name: 'contractorCompanySelection',
  path: '/contractorCompanySelection',
}
