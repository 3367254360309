import BypassSiteRoute from '@/routes/bypassSite'
import ServiceCheckoutRoute from '@/routes/serviceCheckout'
import CheckoutRoute from '@/routes/checkout'
import ConfirmBypassSiteRoute from '@/routes/confirmBypassSite'
import ConfirmNearbySiteRoute from '@/routes/confirmNearbySite'
import ContractorCompanySelectionRoute from '@/routes/contractorCompanySelection'
import ForgotPinRoute from '@/routes/forgotPin'
import HelpLocationRoute from '@/routes/helpLocation'
import HelpNoPrivateBrowsing from '@/routes/helpNoPrivateBrowsing'
import HomeRoute from '@/routes/home'
import UserLoginRoute from '@/routes/userLogin'
import SelectBypassSitesRoute from '@/routes/selectBypassSites'
import SelectNearbySiteRoute from '@/routes/selectNearbySite'
import ServiceFormRoute from '@/routes/serviceForm'
import ServiceFrequencyRoute from '@/routes/serviceFrequency'
import ServiceSelectionRoute from '@/routes/serviceSelection'
import SupplementalFormRoute from '@/routes/supplementalForm'
import InductionFormRoute from '@/routes/inductionForm'
import VisitorRegistration from '@/routes/visitorRegistration'
import SiteEntryConfirmationRoute from '@/routes/siteEntryConfirmation'
import ReadyToCheckoutRoute from '@/routes/readyToCheckout'
import Representation from '@/routes/representation'
import ServiceSiblingSelectionRoute from '@/routes/serviceSiblingSelection'
import StartPermitForm from '@/routes/startPermitForm'
import EndPermitForm from '@/routes/endPermitForm'
import EditAccreditationRoute from '@/routes/editAccreditation'
import CheckAccreditationRoute from '@/routes/checkAccreditation'
import WorkOrdersRoute from '@/routes/workOrder'
import CheckWorkOrderRoute from '@/routes/checkWorkOrder'
import VisitorArrivalRoute from '@/routes/visitorArrival'

const routesMap = {
  BypassSiteRoute,
  CheckAccreditationRoute,
  CheckWorkOrderRoute,
  CheckoutRoute,
  ConfirmBypassSiteRoute,
  ConfirmNearbySiteRoute,
  ContractorCompanySelectionRoute,
  EditAccreditationRoute,
  EndPermitForm,
  ForgotPinRoute,
  HelpLocationRoute,
  HelpNoPrivateBrowsing,
  HomeRoute,
  InductionFormRoute,
  ReadyToCheckoutRoute,
  Representation,
  SelectBypassSitesRoute,
  SelectNearbySiteRoute,
  ServiceCheckoutRoute,
  ServiceFormRoute,
  ServiceFrequencyRoute,
  ServiceSelectionRoute,
  ServiceSiblingSelectionRoute,
  SiteEntryConfirmationRoute,
  StartPermitForm,
  SupplementalFormRoute,
  UserLoginRoute,
  VisitorArrivalRoute,
  VisitorRegistration,
  WorkOrdersRoute,
}

const routes = [
  BypassSiteRoute,
  CheckoutRoute,
  CheckAccreditationRoute,
  CheckWorkOrderRoute,
  ConfirmBypassSiteRoute,
  ConfirmNearbySiteRoute,
  ContractorCompanySelectionRoute,
  EditAccreditationRoute,
  EndPermitForm,
  ForgotPinRoute,
  HelpLocationRoute,
  HelpNoPrivateBrowsing,
  HomeRoute,
  InductionFormRoute,
  ReadyToCheckoutRoute,
  Representation,
  SelectBypassSitesRoute,
  SiteEntryConfirmationRoute,
  SelectNearbySiteRoute,
  ServiceCheckoutRoute,
  ServiceFormRoute,
  ServiceFrequencyRoute,
  ServiceSelectionRoute,
  ServiceSiblingSelectionRoute,
  StartPermitForm,
  SupplementalFormRoute,
  UserLoginRoute,
  VisitorArrivalRoute,
  VisitorRegistration,
  WorkOrdersRoute,
]

export { routes, routesMap }
