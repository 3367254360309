type Point = {
  latitude: number
  longitude: number
}

function getDistanceBetweenPoints(a: Point, b: Point): number {
  const earthRadius = 6378.137 // kilometers
  const dLat = (b.latitude * Math.PI) / 180 - (a.latitude * Math.PI) / 180
  const dLon = (b.longitude * Math.PI) / 180 - (a.longitude * Math.PI) / 180
  const sideA =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((a.latitude * Math.PI) / 180) *
      Math.cos((b.latitude * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const sideC = 2 * Math.atan2(Math.sqrt(sideA), Math.sqrt(1 - sideA))
  const distance = earthRadius * sideC
  return distance * 1000 // meters
}

export default function overlap(
  user: Point,
  site: Point,
  radiusForUser: number,
  radiusForSite: number,
): boolean {
  const distance = getDistanceBetweenPoints(user, site)

  return distance < radiusForUser + radiusForSite
}
