import axios from 'axios'
import { SiteSelectedData } from '@/components/use/siteSelected'

export type SiteSelectedResponse = {
  checkedInAt: string
  code: number
  message: string
  siteSessionId: string
  sessionTtl: number
}

export const postSiteSelectedData = async (
  siteSelectedData: SiteSelectedData,
): Promise<SiteSelectedResponse> => {
  try {
    const response = await axios.post(
      `/api/sites/${siteSelectedData.siteId}/checkin`,
      {
        bypass: siteSelectedData.bypass,
        contractorCompanyId: siteSelectedData.contractorCompanyId,
        location: siteSelectedData.location,
        userTypeId: siteSelectedData.userTypeId,
      },
    )

    return response?.data?.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw error.message
  }
}

export async function visitorCheckin(
  siteId: number,
  companyName: string,
  userTypeId: number,
  location: Position,
): Promise<SiteSelectedResponse> {
  const response = await axios.post(`/api/sites/${siteId}/visitor-checkin`, {
    companyName: companyName,
    location: location,
    userTypeId: userTypeId,
  })

  return response?.data?.data
}
