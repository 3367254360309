<template>
  <div>
    <div class="card space-y-6 pb-3">
      <div
        class="flex items-center justify-center space-x-3 rounded-t-md bg-red-600 py-2"
      >
        <XCircleIcon class="h-7 w-7 stroke-white" />
        <h1 class="text-lg text-white">Access Denied</h1>
      </div>
      <div class="px-4">
        <SiteEntryUserInformation />
        <ul class="my-5" data-test="checks-list">
          <li
            v-for="siteEntryCheck in siteEntryChecks"
            :key="siteEntryCheck.title"
          >
            <p class="text-center text-lg">{{ siteEntryCheck.title }} Failed</p>
            <ul
              v-if="siteEntryCheck.checks"
              class="mt-4 space-y-5"
              data-test="check-details"
            >
              <li
                v-for="check in siteEntryCheck.checks?.filter(
                  (check) => check.result.toLowerCase() === 'stop working',
                )"
                :key="
                  check.name !== ''
                    ? check.name
                    : `${siteEntryCheck.title}-fail-feedback`
                "
              >
                <p class="line-clamp-4 text-center">
                  <span class="font-bold">
                    {{ check['displayName'] ?? check.name }}
                  </span>
                  {{ getCheckFeedback(check) }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { XCircleIcon } from '@heroicons/vue/24/outline'
  import { PropType } from 'vue'
  import {
    CheckType,
    SiteEntryCheckType,
  } from '@/storage/siteEntryConfirmation'
  import { AccreditationCheckType } from '@/services/api/accreditation'
  import SiteEntryUserInformation from '@/components/SiteEntryUserInformation.vue'

  defineProps({
    siteEntryChecks: {
      required: true,
      type: Array as PropType<
        SiteEntryCheckType<CheckType | AccreditationCheckType>[]
      >,
    },
  })

  function getCheckFeedback(check: CheckType | AccreditationCheckType) {
    const hasDisplayName =
      Object.prototype.hasOwnProperty.call(check, 'displayName') &&
      !!check['displayName']
    const hasName =
      Object.prototype.hasOwnProperty.call(check, 'name') && !!check.name

    let feedback = ''

    if (Object.prototype.hasOwnProperty.call(check, 'feedback')) {
      feedback = check['feedback'] ?? ''
    }

    if (feedback !== '' && (hasDisplayName || hasName)) {
      feedback = ': ' + feedback
    }

    return feedback
  }
</script>
