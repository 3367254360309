<template>
  <div>
    <TitleBanner :title="title" />
    <div class="px-4">
      <form>
        <section class="mt-5">
          <div class="px-1">Enter phone number to begin:</div>
          <PhoneNumberInput
            :initial-phone-number="phoneNumber"
            :default-country-code="country.countryCode"
            :disabled="isLoading"
            @selected-country="countryUpdated"
            @phone-number-entered="phoneNumberEntered"
          />
        </section>
        <section v-if="country.showBtn && !pinVisible" class="relative mt-2">
          <PrimaryButton
            :key="continueButtonKey"
            type="button"
            action="Continue"
            class="continueButton"
            :disabled="!canContinue"
            @click.once="continueButtonClicked"
          />
        </section>
        <section v-if="pinVisible" class="mt-4">
          <div>
            <PinInput
              :pin-input-changed="pin"
              :pin-validation="isValid"
              autocomplete="current-password"
              @value-changed="validatePIN"
            />
            <div class="input-error error mt-1 pl-2">
              {{ errorMessage }}
            </div>
          </div>
          <div class="mt-4 text-right">
            <a
              :key="forgotPinKey"
              class="forgot-pin text-sm text-blue-400 hover:text-blue-500 active:text-blue-600"
              aria-label="Forgot PIN?"
              @click.once="routeToForgotPin"
            >
              Forgot PIN?
            </a>
          </div>
        </section>
      </form>
    </div>
    <LoadingView :is-loading="isLoading" message="Checking account..." />
  </div>
</template>

<script lang="ts">
  import { country } from '@/config'
  import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    ref,
    watch,
  } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PinInput from '@/components/PinInput.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import LoadingView from '@/views/LoadingView.vue'
  import PhoneNumberInput from '@/components/PhoneNumberInput.vue'
  import { useRouter } from 'vue-router'
  import useAuth from '@/components/use/auth'
  import useCountry from '@/components/use/countries'
  import setFocus from '@/components/helpers/setFocus'
  import { getCurrentEventRoute } from '@/utilities/session'
  import { useSessionStore } from '@/storage/session'
  import { useNativeScanStore } from '@/storage/nativeScan'
  import { getPlate } from '@/services/api/plate'
  import resetStore, { resetStoreExceptUser } from '@/components/use/resetStore'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import RecoverableException from '@/exceptions/RecoverableException'
  import Log from '@/utilities/Log'
  import ReportableException from '@/exceptions/ReportableException'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'UserLogin',
    components: {
      LoadingView,
      PhoneNumberInput,
      PinInput,
      PrimaryButton,
      TitleBanner,
    },

    setup() {
      const { identityIsValid } = storeToRefs(useUserStore())
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const { setPhoneNumber, setUserLoginExists, setUserLoginSessionExpired } =
        useUserLogin()
      const {
        phoneNumber,
        isPhoneNumberDefault,
        exists,
        firstName,
        isVisitor,
      } = storeToRefs(useUserLogin())
      const { checkUserExists, checkUserIdentity } = useAuth()
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const router = useRouter()
      const canContinue = computed(() => !!phoneNumber.value)
      const errorMessage = ref('')
      const country = getCountryByInternationalPhoneNumber(phoneNumber.value)
      const pinVisible = ref(exists.value)
      const isValid = computed(() => errorMessage.value === '')
      const title = computed(() => `Hi ${firstName.value}, Welcome`)
      const pin = ref('')
      const newVisitor = computed(() => !exists.value && isVisitor.value)
      const isLoading = ref(false)
      const continueButtonKey = ref<number>(0)
      const forgotPinKey = ref<number>(0)

      onMounted(() => {
        nextTick(function () {
          setFocus()
        })
      })

      function countryUpdated(c: country): void {
        setPhoneNumber('')
        setUserLoginExists(false)
        country.value = c
      }

      async function continueButtonClicked() {
        continueButtonKey.value++
        setPhoneNumber(phoneNumber.value)
        if (phoneNumber.value !== '') {
          await attemptFetchUser()
        }
      }

      async function attemptFetchUser() {
        isLoading.value = true
        if (isPhoneNumberDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              phoneNumber: phoneNumber.value,
            },
            ['InvalidStore', 'FetchCheckUserPhoneNumber'],
          )
        }
        await checkUserExists(phoneNumber.value)
        if (newVisitor.value && useNativeScanStore().uuid) {
          await handleUnregisteredVisitor()
        } else if (newVisitor.value) {
          await router.replace({ name: 'visitorRegistration' })
        }
        isLoading.value = false
      }

      async function handleUnregisteredVisitor() {
        const { resetSite, setSite } = useSiteStore()
        const { name: siteName } = storeToRefs(useSiteStore())
        const uuid = useNativeScanStore().uuid
        const { setCustomerInfo, resetCustomer } = useCustomerStore()

        try {
          const plate = await getPlate(uuid)
          resetStoreExceptUser()
          Log.storeResetExcludingUser('UserLogin.vue', 'getPlate')
          // reinstate uuid as required to confirm site
          useNativeScanStore().uuid = uuid
          setSite(plate.site)
          setCustomerInfo(plate.site.customer)
          /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (error: any) {
          resetSite()
          resetCustomer()
          Log.siteStoreReset('UserLogin.vue', 'getPlate')
          Log.customerStoreReset('UserLogin.vue', 'getPlate')
          throw new ReportableException(
            error.message ?? 'An unknown error occurred',
            { error: error },
            ['GetPlate', 'UserLogin'],
          )
        }

        if (useCustomerStore().hasVisitorManagement === false) {
          const errorMessage = `Site ${siteName.value} scanned does not allow visitors!`
          resetStore()
          Log.storeReset('UserLogin.vue', 'handleUnregisteredVisitor')
          throw new RecoverableException(errorMessage)
        } else {
          await router.replace({ name: 'visitorRegistration' })
        }
      }

      function phoneNumberEntered(phoneNumber: string): void {
        setPhoneNumber(phoneNumber)
      }

      function validatePIN(pinEntered: string): void {
        const lengthOfPin = pinEntered.length
        const areNumbers = /^[0-9]+$/
        if (lengthOfPin !== 4) {
          errorMessage.value = '4 digits are required'
        } else if (lengthOfPin === 4 && !areNumbers.test(pinEntered)) {
          errorMessage.value = 'Please enter numbers only'
        } else if (lengthOfPin === 4 && areNumbers.test(pinEntered)) {
          errorMessage.value = ''
          pin.value = pinEntered
        }
      }

      async function routeToForgotPin() {
        forgotPinKey.value++
        await router.replace({ name: 'forgotPin' })
      }

      watch(
        phoneNumber,
        async (newPhoneNumber: string, oldPhoneNumber: string) => {
          if (newPhoneNumber === '' || oldPhoneNumber !== newPhoneNumber) {
            setUserLoginExists(false)
          }
          if (!country.value.showBtn && newPhoneNumber !== '') {
            await attemptFetchUser()
          }
        },
      )

      watch(exists, (exists: boolean) => {
        pinVisible.value = exists
        nextTick(function () {
          setFocus()
        })
      })

      watch(pin, async (pin: string) => {
        if (pin === '') {
          useUserStore().setUserAuthenticationResult(false)
          return
        }
        isLoading.value = true
        if (isPhoneNumberDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              phoneNumber: phoneNumber.value,
            },
            ['InvalidStore', 'StoreUserLogin'],
          )
        }
        await checkUserIdentity(phoneNumber.value, pin)
      })

      watch(identityIsValid, async (): Promise<void> => {
        if (identityIsValid.value) {
          if (useSessionStore().events.length == 0) {
            await router.replace({ name: 'selectNearbySite' })
          } else {
            window.location.replace(router.resolve(getCurrentEventRoute()).href)
          }
        }
      })

      return {
        canContinue,
        continueButtonClicked,
        continueButtonKey,
        country,
        countryUpdated,
        errorMessage,
        forgotPinKey,
        isLoading,
        isValid,
        phoneNumber,
        phoneNumberEntered,
        pin,
        pinVisible,
        routeToForgotPin,
        setUserLoginSessionExpired,
        title,
        validatePIN,
      }
    },
  })
</script>
