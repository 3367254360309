import { AxiosHeaders } from 'axios'

function addOrAppendAxiosHeader(
  key: string,
  value: string,
  headers: AxiosHeaders | undefined,
): AxiosHeaders {
  const returnHeaders = headers ?? new AxiosHeaders()

  returnHeaders.set(key, value)

  return returnHeaders
}

export { addOrAppendAxiosHeader }
