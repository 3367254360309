import axios from 'axios'
import { Answer, SchemaType } from '@verifiedit/form-generator'

export type PermitForm = {
  id: number
  type: string
  form: {
    template: {
      schema: SchemaType[]
    }
  }
}

export type PermitFormSubmission = {
  createdAt: string
  formData: {
    $id: string
    $schema: string
    template: {
      schema: SchemaType[]
    }
  }[]
  id: number
  permitFormId: number
  visitLogId: number
  workOrderNumber: string
}

export async function fetchPermitForms(
  customerId: number,
  userTypeId: number,
): Promise<PermitForm[]> {
  const response = await axios.get(`api/customers/${customerId}/permits`, {
    params: {
      userTypeId: userTypeId,
    },
  })

  return response.data?.data
}

export async function submitPermitForm(
  contractorCompanyName: string,
  customerId: number,
  siteId: number,
  permitFormId: number,
  formData: Map<string, Answer>,
): Promise<PermitFormSubmission> {
  const response = await axios.post(
    `api/customers/${customerId}/permit-form-submissions`,
    {
      contractorCompanyName: contractorCompanyName,
      customerId: customerId,
      formData: Object.fromEntries(formData),
      permitFormId: permitFormId,
      siteId: siteId,
    },
  )

  return response.data.data
}
