import {
  StrategyInterface,
  StrategySelectorContract,
} from '@/types/strategyInterface'
import { useUserStore } from '@/storage/user'
import AuthenticatedUserNativeScanStrategy from '@/strategies/AuthenticatedUserNativeScanStrategy'

export default class AuthenticatedUserNativeScanStrategySelector
  implements StrategySelectorContract
{
  private searchParams

  constructor() {
    this.searchParams = new URLSearchParams(window.location.search)
  }

  can = (): boolean => {
    return (
      useUserStore().id > 0 &&
      useUserStore().identityIsValid &&
      this.searchParams.has('uuid')
    )
  }

  getStrategy = (): StrategyInterface => {
    const { uuid } = this.inputs()
    this.searchParams.delete('uuid')
    return new AuthenticatedUserNativeScanStrategy(uuid)
  }

  inputs = (): { uuid: string } => {
    return {
      uuid: this.searchParams.get('uuid') as string,
    }
  }
}
