import axios from 'axios'

export type CheckoutResponse = {
  code: number
  message: string
}

export const postCheckoutData = async (
  checkoutData,
  failedRequirements = false,
): Promise<CheckoutResponse> => {
  const config = {}
  if (failedRequirements) {
    Object.assign(config, { params: { failedRequirements: true } })
  }
  const response = await axios.post(
    `/api/sites/${checkoutData.siteId}/checkout`,
    {
      location: checkoutData.location,
    },
    config,
  )
  return response?.data?.data
}
