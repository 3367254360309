import ServiceCheckout from '@/views/ServiceCheckout.vue'

export default {
  component: ServiceCheckout,
  meta: {
    loadingMessage: 'Loading check out...',
    showMenu: true,
  },
  name: 'serviceCheckout',
  path: '/checkout',
}
