import { CheckoutResponse } from '@/services/api/checkout'
import InvalidStoreException from '@/exceptions/InvalidStoreException'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'
import { useFingerprintStore } from '@/storage/fingerprint'
import { useUserPositionStore } from '@/storage/userPosition'
import { useSiteStore } from '@/storage/site'
import { useUserStore } from '@/storage/user'

type DataAcquisitionFunction = () => CheckoutData

export type CheckoutData = {
  siteId: number
  location: Position
}

type ApiCallFunction = (data: CheckoutData) => Promise<CheckoutResponse>

export function acquireRequiredData(): CheckoutData {
  const { isSiteStoreDefault, id: siteId } = storeToRefs(useSiteStore())
  const { isUserPositionEmpty, position } = storeToRefs(useUserPositionStore())

  if (
    isSiteStoreDefault.value ||
    (!useUserLogin().isVisitor && isUserPositionEmpty.value)
  ) {
    throw new InvalidStoreException(
      {
        fingerprint: useFingerprintStore().fingerprint,
        siteId: siteId.value,
        userId: useUserStore().id,
        userPosition: position.value,
      },
      ['InvalidStore', 'StoreUserCheckout'],
    )
  }

  return {
    location: {
      accuracy: position.value.accuracy,
      altitude: position.value.altitude,
      latitude: position.value.latitude,
      longitude: position.value.longitude,
    },

    siteId: siteId.value,
  }
}

export const checkout = async function (
  acquireData: DataAcquisitionFunction,
  apiCall: ApiCallFunction,
): Promise<CheckoutResponse> {
  return await apiCall(await acquireData())
}
