import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import SelectBypassSite from '@/views/SelectBypassSite.vue'
import { useCustomerStore } from '@/storage/customer'
import { useSiteStore } from '@/storage/site'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { resetSite } = useSiteStore()
  const { resetCustomer } = useCustomerStore()

  if (
    from.name === 'confirmBypassSite' ||
    from.name === 'contractorCompanySelection'
  ) {
    resetSite()
    resetCustomer()
  }

  next()
}

export default {
  beforeEnter: guard,
  component: SelectBypassSite,
  meta: {
    loadingMessage: 'Loading sites...',
    requiresLocation: true,
    returnTo: 'bypass',
    showMenu: true,
  },
  name: 'selectBypassSites',
  path: '/sites/bypass',
}
