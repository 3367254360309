import axios from 'axios'
import config from '@/config'
import {
  ChannelAuthorizationCallback,
  ChannelAuthorizationRequestParams,
} from 'pusher-js/types/src/core/auth/options'
import { useAppStore } from '@/storage/app'
import { version } from '~/package.json'

export const authoriseChannel = (
  params: ChannelAuthorizationRequestParams,
  callback: ChannelAuthorizationCallback,
) => {
  const instance = axios.create({
    headers: {
      'x-verified-capture-version': version,
      'x-verified-token': `Bearer ${useAppStore().token}`,
    },
  })

  instance
    .post(config.api.capture + '/broadcasting/auth', {
      channel_name: params.channelName,
      socket_id: params.socketId,
    })
    .then((response) => {
      callback(null, response.data)
    })
    .catch((error) => {
      callback(error, null)
    })
}
