import resetStore from '@/components/use/resetStore'
import { isSameFingerprint } from '@/utilities/session'
import config from '@/config'
import Log from '@/utilities/Log'
import { storeToRefs } from 'pinia'
import { useFingerprintStore } from '@/storage/fingerprint'
import { useUserStore } from '@/storage/user'

export interface AuditableEventType {
  visitorId: string
  requestId: string
  timezone: string
  occurredAt: string
}

async function terminateSession(data: AuditableEventType) {
  // We should only ever be terminating session that were open previous to this event
  const checkInSameTimeOrLaterThanEvent =
    new Date(useUserStore().checkedInTime ?? 0) >= new Date(data.occurredAt)

  if (checkInSameTimeOrLaterThanEvent) {
    return
  }

  const { fingerprint } = storeToRefs(useFingerprintStore())

  if (
    !isSameFingerprint(
      data.requestId,
      data.visitorId,
      fingerprint.value?.requestId,
      fingerprint.value?.visitorId,
    )
  ) {
    resetStore()
    Log.storeReset('terminateSession.ts')
  }

  window.location.replace(config.redirects.sessionExpired)
}

export default terminateSession
