export const redirects = {
  captureLoginPage:
    import.meta.env.VITE_CAPTURE_LOGIN_PAGE || 'https://verified.com.au',
  desktop:
    import.meta.env.VITE_DESKTOP_DETECTED_REDIRECT || 'https://verified.com.au',
  sessionCompleted:
    import.meta.env.VITE_SESSION_COMPLETED_REDIRECT ||
    'https://verified.com.au',
  sessionExpired:
    import.meta.env.VITE_SESSION_ENDED_REDIRECT ||
    'https://webcontent.verified.com.au/session-ended/',
}
