<template>
  <div>
    <LoadingView :is-loading="isLoading" message="Checking work orders" />
  </div>
</template>

<script setup lang="ts">
  import LoadingView from '@/views/LoadingView.vue'
  import { useRouter } from 'vue-router'
  import { ref } from 'vue'
  import { getWorkOrdersCount } from '@/services/api/workOrder'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { getCustomerWorkOrderUserTypes } from '@/services/api/customer'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useSiteStore } from '@/storage/site'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
  import { getSiteEntryCheck } from '@/components/use/siteEntryConfirmation'
  import { useUserStore } from '@/storage/user'

  const { selectedContractorCompanyId, userType } = storeToRefs(useUserStore())
  const { id: siteId } = storeToRefs(useSiteStore())
  const { removeSiteEntryCheck, addSiteEntryCheck } =
    useSiteEntryConfirmationStore()

  const router = useRouter()
  const { id: customerId, isCustomerStoreDefault } =
    storeToRefs(useCustomerStore())
  const isLoading = ref(false)

  isLoading.value = true

  const workOrderUserTypes = await getCustomerWorkOrderUserTypes(
    customerId.value,
  )

  if (
    workOrderUserTypes.some(
      (workOrderUserType) => workOrderUserType.id === userType.value.id,
    )
  ) {
    await checkWorkOrder()
  }
  routeToNext()
  isLoading.value = false

  function handleSiteEntryChecks(count: number) {
    const siteEntryCheck = getSiteEntryCheck('Work Order')

    if (siteEntryCheck !== undefined) {
      removeSiteEntryCheck(siteEntryCheck)
    }

    addSiteEntryCheck({
      canContinue: count > 0,
      checks: [
        {
          feedback: 'No work orders found for this site for your company',
          name: '',
          result: count > 0 ? 'Pass' : 'Stop Working',
        },
      ],
      passed: count > 0,
      title: 'Work Order',
    })
  }

  async function checkWorkOrder() {
    if (isCustomerStoreDefault.value) {
      throw new InvalidStoreException(
        {
          contractorCompanyId: selectedContractorCompanyId.value,
          customerId: customerId.value,
          siteId: siteId.value,
        },
        ['WorkOrders'],
        'No customer selected',
      )
    }

    const workOrdersCount = await getWorkOrdersCount(
      siteId.value,
      selectedContractorCompanyId.value,
    )

    handleSiteEntryChecks(workOrdersCount.count)
  }

  async function routeToNext() {
    if (!useSiteEntryConfirmationStore().canContinue) {
      await router.replace({ name: 'siteEntryConfirmation' })
    } else {
      await router.replace({ name: 'checkAccreditation' })
    }
  }
</script>
