import axios from 'axios'
import { Answer, SchemaType } from '@verifiedit/form-generator'
import { getCustomerDocumentMeta } from '@/services/api/customer'
import { getSiteDocumentMeta } from '@/services/api/site'

const resourceFunction = {
  getCustomerDocumentContent: getCustomerDocumentMeta,
  getSiteDocumentContent: getSiteDocumentMeta,
}

export default resourceFunction

export async function getInductionForm(
  siteId: number,
  userTypeId: number,
): Promise<SchemaType[]> {
  const response = await axios.get('api/induction-forms', {
    params: {
      siteId: siteId,
      userTypeId: userTypeId,
    },
  })

  if (typeof response?.data?.data?.schema === 'string') {
    return JSON.parse(response?.data?.data?.schema).data.schema
  }

  return response.data.data.template.schema
}

export async function submitInductionForm(
  siteId: number,
  userTypeId: number,
  contractorCompanyId: number,
  formData: { [key: string]: Answer },
) {
  return await axios.post('api/induction-forms', {
    contractorCompanyId: contractorCompanyId,
    formData: formData,
    siteId: siteId,
    userTypeId: userTypeId,
  })
}

export async function submitSupervisorSignOff(
  siteId: number,
  supervisorName: string,
  supervisorSignature: string,
) {
  const { data: res } = await axios.post('api/supervisor-signoff', {
    siteId: siteId,
    supervisorName: supervisorName,
    supervisorSignature: supervisorSignature,
  })

  return res.data
}
