<template>
  <div>
    <TitleBanner title="Please allow access to Location" />
    <div id="location-instructions" class="flex flex-col px-8">
      <ul class="mt-10 flex flex-col gap-4">
        <li
          v-for="(instruction, index) in instructions"
          :key="index"
          class="flex items-center justify-items-start gap-4"
        >
          <img
            v-if="instruction.icon"
            :src="instruction.icon"
            :alt="instruction.content"
            class="h-10 w-10"
          />
          <span v-else class="h-10 w-10" />
          <p>{{ instruction.content }}</p>
        </li>
      </ul>
      <div class="ml-auto mt-4 w-1/2">
        <PrimaryButton
          :key="doneButtonKey"
          action="Done"
          type="button"
          @click="attemptRestore"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import TitleBanner from '@/components/TitleBanner.vue'
  import { defineComponent, ref } from 'vue'
  import { instruction } from '@/config'
  import locationSettingsInstructions from '@/config/help/locationSettingsInstructions'
  import { isIOS } from '@/utilities/device'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { storeToRefs } from 'pinia'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'LocationHelp',
    components: { PrimaryButton, TitleBanner },

    setup() {
      const { getBrowser } = storeToRefs(useFingerprintStore())
      const router = useRouter()
      const route = useRoute()

      const getInstructions = (): instruction[] => {
        if (isIOS() && getBrowser.value?.includes('Safari')) {
          return locationSettingsInstructions.iosSafari
        }
        if (!isIOS() && getBrowser.value?.includes('Chrome')) {
          return locationSettingsInstructions.androidChrome
        }
        if (!isIOS() && getBrowser.value?.includes('Samsung')) {
          return locationSettingsInstructions.androidSamsung
        }
        return [
          { content: 'Turn precise location on' },
          { content: "Refer to your mobile's manual for instructions" },
        ]
      }

      const doneButtonKey = ref<number>(0)
      const attemptRestore = () => {
        doneButtonKey.value++
        router.replace({
          name: route.query.to as string,
        })
      }

      return {
        attemptRestore,
        doneButtonKey,
        instructions: getInstructions(),
      }
    },
  })
</script>
