import axios from 'axios'
import { type DocumentMeta } from '@/services/api/site'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/storage/app'
import { useFingerprintStore } from '@/storage/fingerprint'
import Log from '@/utilities/Log'
import ReportableException from '@/exceptions/ReportableException'

export type UserType = {
  id: number
  name: string
}

export async function getCustomerVisitorUserTypes(
  customerId: number,
): Promise<UserType[]> {
  const response = await axios.get(
    `/api/customers/${customerId}/visitor-user-types`,
  )

  return response.data.data
}

export async function getCustomerWorkOrderUserTypes(
  customerId: number,
): Promise<UserType[]> {
  const response = await axios.get(
    `api/customers/${customerId}/work-order-user-types`,
  )

  return response.data.data
}

export const getCustomerDocumentMeta = async (
  customerId: number,
  documentId: number,
): Promise<DocumentMeta> => {
  const { token } = storeToRefs(useAppStore())
  const { getOs } = storeToRefs(useFingerprintStore())

  const url = `/api/documents/${documentId}`
  const response = (await axios.get(url)).data.data

  response.images = response.images.map((url) => url + `?token=${token.value}`)
  response.origin += `?token=${token.value}&os=${getOs.value?.toLowerCase()}`

  if (response.images.length === 0) {
    Log.notify(
      new ReportableException(
        'Image content missing',
        {
          customerId: customerId,
          documentId: documentId,
        },
        ['CustomerDocumentMissing'],
      ),
      'CustomerDocumentMissing',
    )
  }

  return response
}
