import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useCustomerStore } from '@/storage/customer'
import CheckWorkOrder from '@/views/CheckWorkOrder.vue'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'

const guard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const store = useCustomerStore()
  const { isVisitor } = storeToRefs(useUserLogin())

  if (!isVisitor.value && store.hasWorkOrder) {
    next()
  } else {
    next({ name: 'checkAccreditation' })
  }
}

export default {
  beforeEnter: guard,
  component: CheckWorkOrder,
  meta: {
    loadingMessage: 'Checking work order...',
    showMenu: true,
  },
  name: 'checkWorkOrder',
  path: '/workOrder/check',
}
