import axios from 'axios'
import config from '@/config'
import RecoverableException from '@/exceptions/RecoverableException'
import { useAppStore } from '@/storage/app'
import { storeToRefs } from 'pinia'
import { useCustomerStore } from '@/storage/customer'
import { useFingerprintStore } from '@/storage/fingerprint'
import Log from '@/utilities/Log'
import ReportableException from '@/exceptions/ReportableException'
import { UserType } from '@/services/api/customer'

export type Site = {
  address: string
  customer: Customer
  id: number
  latitude: number
  longitude: number
  name: string
  siteDocuments: VerifiedDocument[]
  siteHazards: SiteHazard[]
}
export type Customer = {
  email: string
  hasAccreditation: boolean
  hasCheckList: boolean
  hasGPS: boolean
  hasInduction: boolean
  hasNotice: boolean
  hasSpotChecks: boolean
  hasUnregisteredContractor: boolean
  hasVisitorArrivalNotification: boolean
  hasWorkOrder: boolean
  id: number
  name: string
  needsPermitPerService: boolean
  phone: string
  postcode: string
  requiresSupervisorSignature: boolean
  state: string
  street: string
  town: string
  customerDocuments?: VerifiedDocument[]
  supervisorChecklist?: string[]
}
export type VerifiedDocument = {
  documentName: string
  id: number
  documentEndpoint?: string
}
export type SiteHazard = {
  description: string
  id: number
}
export type AuthorisedContractorCompany = {
  id: number
  name: string
  userTypes: UserType[]
}
export let controller: AbortController = new AbortController()

export type DocumentMeta = {
  createdAt: string
  images: string[]
  mime: string
  name: string
  origin: string
  total: number
  uuid: string
}

export const getSites = async (
  userId: string,
  location: Position,
  pageNumber: string,
  searchQuery: string | null = null,
  withinRadius: number | null = null,
): Promise<Site[] | undefined> => {
  controller = new AbortController()
  try {
    const response = await axios.get('/api/sites', {
      params: {
        'location.accuracy': location.accuracy,
        'location.altitude': location.altitude,
        'location.latitude': location.latitude,
        'location.longitude': location.longitude,
        pageNumber: pageNumber,
        perPage: config.pagination.sites.perPage,
        searchQuery: searchQuery,
        userId: userId,
        withinRadius: withinRadius,
      },
      signal: controller.signal,
    })

    if (response) {
      return response?.data?.data
    }
    return []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message !== 'canceled') {
      return []
    }
  }
}

export const getNearbySites = async (
  userId: string,
  location: Position,
  withinRadius: number | null = null,
): Promise<Site[] | undefined> => {
  const response = await axios.get('/api/sites', {
    params: {
      'location.accuracy': location.accuracy,
      'location.altitude': location.altitude,
      'location.latitude': location.latitude,
      'location.longitude': location.longitude,
      userId: userId,
      withinRadius: withinRadius,
    },
  })

  if (response) {
    return response?.data?.data
  }
  return []
}

export const getAuthorisedContractorCompanies = async (
  siteId: number,
  userId: number,
  isBypass = false,
): Promise<AuthorisedContractorCompany[]> => {
  const response = await axios.get(
    '/api/users/' + userId + '/contractor-companies',
    {
      params: {
        siteId: siteId,
      },
    },
  )
  const data = response?.data?.data

  if (
    data.length === 0 &&
    (!useCustomerStore().hasVisitorManagement || isBypass)
  ) {
    throw new RecoverableException(
      'This phone number is associated with a company that has no access to this site. Please contact your company administrator.',
    )
  }

  return data
}

export const getSiteDocumentMeta = async (
  siteId: number,
  documentId: number,
): Promise<DocumentMeta> => {
  const { token } = storeToRefs(useAppStore())
  const { getOs } = storeToRefs(useFingerprintStore())

  const url = `/api/documents/${documentId}/${siteId}`
  const response: DocumentMeta = (await axios.get(url)).data.data

  response.images = response.images.map((url) => url + `?token=${token.value}`)
  response.origin += `?token=${token.value}&os=${getOs.value?.toLowerCase()}`

  if (response.images.length === 0) {
    Log.notify(
      new ReportableException(
        'Image content missing',
        {
          documentId: documentId,
          siteId: siteId,
        },
        ['SiteDocumentMissing'],
      ),
      'SiteDocumentMissing',
    )
  }

  return response
}
