import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useErrorStore = defineStore('error', () => {
  /** state */
  const message = ref<string>('')
  const isSupportable = ref<boolean>(false)
  const isWarning = ref<boolean>(false)
  const requiresReload = ref<boolean>(false)
  const type = ref<string>('danger')

  /** getters */

  /** actions */
  function setMessage(value: string) {
    message.value = value
  }
  function setIsSupportable(value: boolean) {
    isSupportable.value = value
  }

  function setRequiresReload(value: boolean) {
    requiresReload.value = value
  }

  function setType(value: string) {
    type.value = value
  }

  function resetError() {
    message.value = ''
    isSupportable.value = false
    isWarning.value = false
    requiresReload.value = false
    type.value = 'danger'
  }

  return {
    isSupportable,
    isWarning,
    message,
    requiresReload,
    resetError,
    setIsSupportable,
    setMessage,
    setRequiresReload,
    setType,
    type,
  }
})
