<template>
  <TitleBanner title="Private browsing not supported" />
  <div class="flex items-center justify-center">
    <section class="m-10">
      <p>
        Due to the advanced features this application provides, you will not be
        able to continue in private browsing mode.
      </p>
      <p class="mt-4">
        Please open a standard browsing window to continue using Verified.
      </p>
    </section>
  </div>
</template>

<script lang="ts">
  import TitleBanner from '@/components/TitleBanner.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'NoPrivateBrowsingHelp',
    components: { TitleBanner },
  })
</script>
