<template>
  <section>
    <SiteEntryInformation />
  </section>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import SiteEntryInformation from '@/components/SiteEntryInformation.vue'

  export default defineComponent({
    name: 'SiteEntryConfirmationOverlay',
    components: {
      SiteEntryInformation,
    },
  })
</script>
