import axios from 'axios'

export type SystemNotification = {
  id: number
  message: string
  updatedAt: string
}

export async function getNotifications(): Promise<SystemNotification[]> {
  const response = await axios.get('api/notifications')

  return response.data.data
}
