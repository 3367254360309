import AppEntryPoint from '@/views/AppEntryPoint.vue'

export default {
  component: AppEntryPoint,
  meta: {
    loadingMessage: 'Initialising application...',
    showMenu: false,
  },
  name: 'home',
  path: '/',
}
