import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import CheckoutView from '@/views/CheckoutView.vue'
import { usePermitFormStore } from '@/storage/permitForm'
import { storeToRefs } from 'pinia'
import { useSupplementalFormStore } from '@/storage/supplementalForms'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { setCurrentSupplementalForm, setRouteTo, setRouteFrom } =
    useSupplementalFormStore()
  const { forms, routeFrom } = storeToRefs(useSupplementalFormStore())

  const { hasSubmittedStartPermit, hasEndPermit } =
    storeToRefs(usePermitFormStore())

  const fromRoute = from.name === 'supplementalForm' ? routeFrom.value : from

  const shouldShowEndPermit =
    hasSubmittedStartPermit.value && hasEndPermit.value

  if (shouldShowEndPermit) {
    next({ name: 'endPermit' })
  } else if (forms.value.length !== 0) {
    setCurrentSupplementalForm(forms.value[0])
    setRouteFrom({
      name: fromRoute?.name as string,
      params: fromRoute?.params,
    } as RouteLocationNormalized)
    setRouteTo({
      name: to.name as string,
      params: to.params,
    } as RouteLocationNormalized)

    next({
      name: 'supplementalForm',
    })
  } else {
    next()
  }
}

export default {
  beforeEnter: guard,
  component: CheckoutView,
  meta: {
    loadingMessage: 'Loading check out...',
    requiresLocation: true,
    returnTo: '',
    showMenu: true,
  },
  name: 'checkout',
  path: '/checkout',
}
