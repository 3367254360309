<template>
  <div data-test="hazard-overlay">
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <div class="flex h-[75svh] flex-col px-4">
      <h1 class="mt-4 text-center text-2xl">Site Notice / Hazard</h1>
      <ul class="mx-6 mb-4 mt-10 space-y-2 overflow-y-scroll">
        <li
          v-for="hazard in siteHazards"
          :key="hazard.id"
          data-test="hazard-item"
        >
          <p>{{ hazard.description }}</p>
        </li>
      </ul>
      <div class="mt-auto px-4">
        <PrimaryButton action="Continue" @click="hideOverlay" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onBeforeUnmount } from 'vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import overlay from '@/components/use/overlay'
  import { useSiteStore } from '@/storage/site'
  import { storeToRefs } from 'pinia'

  export default defineComponent({
    name: 'SiteHazardOverlay',
    components: { PrimaryButton, SiteBanner },

    setup() {
      const { setSeenHazards } = useSiteStore()
      const {
        name: siteName,
        address: siteAddress,
        siteHazards,
      } = storeToRefs(useSiteStore())
      const { hideOverlay } = overlay()

      onBeforeUnmount(() => {
        setSeenHazards(true)
      })

      return {
        hideOverlay,
        siteAddress,
        siteHazards,
        siteName,
      }
    },
  })
</script>
