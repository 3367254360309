import SupplementalForm from '@/views/SupplementalForm.vue'

export default {
  component: SupplementalForm,
  meta: {
    loadingMessage: 'Loading form...',
    showMenu: true,
  },
  name: 'supplementalForm',
  path: '/supplemental/form',
}
