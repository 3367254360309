<template>
  <div
    class="text-grey-900 bg-white px-6 pb-4 pt-6 text-center font-sans text-lg font-light font-normal not-italic drop-shadow-md filter"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>
    <h2 v-if="subTitle" class="pt-2 text-center text-sm">{{ subTitle }}</h2>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'TitleBanner',
    props: {
      subTitle: {
        type: String,
        default: '',
      },

      title: {
        type: String,
        default: '',
      },
    },
  })
</script>
