interface Config {
  endpoint: string
  scriptUrlPattern: string
  token: string
  ttl: number
}

const oneWeek = 1000 * 60 * 60 * 24 * 7

export const fingerprint: Config = {
  endpoint:
    import.meta.env.VITE_FINGERPRINT_ENDPOINT ||
    'https://metrics.verified.com.au',
  scriptUrlPattern: import.meta.env.VITE_FINGERPRINT_SCRIPT_URL_PATTERN || '',
  token: import.meta.env.VITE_FINGERPRINT_TOKEN || '',
  ttl: parseInt(import.meta.env.VITE_FINGERPRINT_TTL) || oneWeek,
}
