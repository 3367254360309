import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'

export const useUserLogin = defineStore(
  'user/login',
  () => {
    /** state */
    const exists = ref(false)
    const firstName = ref('There')
    const isVisitor = ref(false)
    const phoneNumber = ref('')
    const sessionExpired = ref(false)

    /** getters */
    const isPhoneNumberDefault: ComputedRef<boolean> = computed(
      () => phoneNumber.value === '',
    )

    /** actions */
    function setUserLoginExists(value: boolean): void {
      exists.value = value
    }

    function setUserLoginFirstName(value: string): void {
      firstName.value = value
    }

    function setUserLoginIsVisitor(value: boolean): void {
      isVisitor.value = value
    }

    function setUserLoginSessionExpired(value: boolean): void {
      sessionExpired.value = value
    }

    function setPhoneNumber(value: string): void {
      phoneNumber.value = value
    }

    function resetUserLogin() {
      exists.value = false
      firstName.value = 'There'
      isVisitor.value = false
      phoneNumber.value = ''
      sessionExpired.value = false
    }

    return {
      exists,
      firstName,
      isPhoneNumberDefault,
      isVisitor,
      phoneNumber,
      resetUserLogin,
      sessionExpired,
      setPhoneNumber,
      setUserLoginExists,
      setUserLoginFirstName,
      setUserLoginIsVisitor,
      setUserLoginSessionExpired,
    }
  },
  {
    persist: true,
  },
)
