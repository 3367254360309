import ConfirmBypassSite from '@/views/ConfirmBypassSite.vue'

export default {
  component: ConfirmBypassSite,
  meta: {
    loadingMessage: 'Loading confirmation...',
    requiresLocation: true,
    returnTo: 'selectBypassSites',
    showMenu: true,
  },
  name: 'confirmBypassSite',
  path: '/sites/bypass/confirm',
}
