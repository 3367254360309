import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useFingerprintStore } from '@/storage/fingerprint'
import { storeToRefs } from 'pinia'

const noPrivateBrowsingGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isPrivate } = storeToRefs(useFingerprintStore())

  // private (incognito) browsing detected
  if (to.name !== 'helpNoPrivateBrowsing' && isPrivate.value) {
    next({ name: 'helpNoPrivateBrowsing' })
  } else {
    next()
  }
}

export { noPrivateBrowsingGuard }
