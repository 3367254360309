<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div
          class="flex h-full justify-center p-4 text-center sm:p-0"
          :class="{ 'items-center': !isFullScreen }"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative flex min-w-full transform flex-col overflow-y-auto rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8"
            >
              <div>
                <div class="pt-6 text-center sm:mt-5">
                  <div
                    class="sticky top-0 z-20 flex justify-center bg-white py-2 pl-6 align-top"
                  >
                    <DialogTitle
                      as="h3"
                      class="line-clamp-2 flex-1 text-lg font-medium leading-6 text-gray-900"
                    >
                      {{ title }}
                    </DialogTitle>
                    <XMarkIcon
                      class="h-6 w-6 text-blue-500"
                      @click="closeModal"
                    />
                  </div>
                  <div class="mt-2">
                    <slot></slot>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { XMarkIcon } from '@heroicons/vue/24/solid'

  export default defineComponent({
    name: 'BaseModal',
    components: {
      // eslint-disable-next-line vue/no-reserved-component-names
      Dialog,
      DialogPanel,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      XMarkIcon,
    },

    props: {
      isFullScreen: {
        type: Boolean,
        default: false,
      },

      title: {
        type: String,
        default: '',
      },
    },

    emits: ['close'],

    setup(props, { emit }) {
      const open = ref(true)

      function closeModal() {
        emit('close')
      }

      return {
        closeModal,
        open,
      }
    },
  })
</script>
