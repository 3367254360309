import ServiceForm from '@/views/ServiceForm.vue'

export default {
  component: ServiceForm,
  meta: {
    loadingMessage: 'Loading service form...',
    returnTo: 'serviceSelection',
    showMenu: true,
  },
  name: 'serviceForm',
  path: '/services/form',
}
