<template>
  <div>
    <SiteBanner :site-address="siteAddress" :site-name="siteName" />
    <WorkOrderDetails v-if="hasSelectedWorkOrder" class="mt-4" />
    <div class="p-4">
      <h1 class="mx-4 my-2 pt-1 text-center text-lg">
        {{ selectedService?.name }}
      </h1>
      <div v-if="frequencies.length > 0">
        <h4 class="my-3">Please select one or more frequencies</h4>
        <div
          v-for="frequency in frequencies"
          :key="frequencies.indexOf(frequency)"
          class="relative mb-3"
        >
          <input
            :id="'frequency' + '-' + frequency.id"
            v-model="selectedFrequency"
            type="checkbox"
            class="rounded-sm"
            :value="frequency.id"
            @change="newOptionSelected($event.target)"
          />
          <label :for="'frequency' + '-' + frequency.id" class="pl-3">
            {{ frequency.description }}
          </label>
          <span
            v-if="frequencyCompletedBefore(frequency.id)"
            class="absolute right-2 top-1 text-blue-500"
          >
            <CheckCircleIcon class="h-5 w-5" />
          </span>
        </div>
        <div class="mt-7">
          <PrimaryButton
            :key="continueBtnKey"
            type="button"
            :disabled="selectedFrequency?.length === 0"
            action="CONTINUE"
            identifier="confirmFrequencies"
            @click.once="checkForDuplicateAndContinue"
          />
        </div>
      </div>
      <div v-else>
        <p class="mx-2 my-6 text-center">
          There are no valid service intervals, please select another service
        </p>
        <PrimaryButton
          action="OK"
          @click="router.replace({ name: 'serviceSelection' })"
        />
      </div>
    </div>
    <DuplicateServiceModal
      :show-duplicate-service-modal="showDuplicateServiceModal"
      @close="showDuplicateServiceModal = false"
      @confirm="confirmFrequencies"
      @open-history="openMyHistory"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Frequency,
    getServiceFrequencies,
    getSiteServiceFrequencies,
  } from '@/services/api/serviceFrequency'
  import { CheckCircleIcon } from '@heroicons/vue/24/solid'
  import SiteBanner from '@/components/SiteBanner.vue'
  import DuplicateServiceModal from '@/components/DuplicateServiceModal.vue'
  import overlay from '@/components/use/overlay'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import UserHistory from '@/views/UserHistory.vue'
  import WorkOrderDetails from '@/components/WorkOrderDetails.vue'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useFrequenciesStore } from '@/storage/frequencies'
  import { useServicesStore } from '@/storage/services'
  import { useSiteStore } from '@/storage/site'
  import { useWorkOrderStore } from '@/storage/workOrder'

  export default defineComponent({
    name: 'ServiceFrequency',
    components: {
      CheckCircleIcon,
      DuplicateServiceModal,
      PrimaryButton,
      SiteBanner,
      WorkOrderDetails,
    },

    async setup() {
      const {
        address: siteAddress,
        name: siteName,
        id: siteId,
        isSiteStoreDefault,
      } = storeToRefs(useSiteStore())
      const { setSelectedFrequency } = useFrequenciesStore()
      const { previouslyCompleted } = storeToRefs(useFrequenciesStore())
      const { selectedService, isSelectedServiceDefault } =
        storeToRefs(useServicesStore())
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const router = useRouter()
      const route = useRoute()

      const canContinue = ref(false)
      const continueBtnKey = ref(0)
      const serviceId = selectedService.value?.id ?? null
      const subServiceId = selectedService.value?.subServiceId ?? null
      const selectedFrequency = ref<number[]>([])
      const frequencies = ref<Frequency[]>([])
      const showDuplicateServiceModal = ref<boolean>(false)
      const { hasSelectedWorkOrder, selectedWorkOrderType } =
        storeToRefs(useWorkOrderStore())

      if (route?.params.serviceType === 'activeService') {
        if (isSiteStoreDefault.value || isSelectedServiceDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              serviceId: serviceId,
              siteId: siteId.value,
              subServiceId: subServiceId,
            },
            ['InvalidStore', 'FetchSiteServiceFrequencies'],
          )
        }
        await fetchSiteServiceFrequencies(siteId.value, serviceId, subServiceId)
        checkIfSkipPage()
      } else {
        if (isSelectedServiceDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              serviceId: serviceId,
              siteId: siteId.value,
            },
            ['InvalidStore', 'FetchNonSiteServiceFrequencies'],
          )
        }
        await fetchServiceFrequencies(serviceId)
        checkIfSkipPage()
      }

      async function fetchSiteServiceFrequencies(
        siteId: number,
        serviceId: number | null,
        subServiceId: number | null,
      ): Promise<void> {
        frequencies.value = await getSiteServiceFrequencies(
          siteId,
          serviceId,
          subServiceId,
        )
        filterFrequenciesForWorkOrder()
      }

      async function fetchServiceFrequencies(
        serviceId: number | null,
      ): Promise<void> {
        frequencies.value = await getServiceFrequencies(serviceId)
        filterFrequenciesForWorkOrder()
      }

      function filterFrequenciesForWorkOrder() {
        if (hasSelectedWorkOrder.value) {
          frequencies.value = frequencies.value.filter((frequency) => {
            if (selectedWorkOrderType.value === 'REACTIVE') {
              return frequency.description === 'Repair'
            }
            return frequency.description !== 'Repair'
          })
        }
      }

      async function confirmFrequencies(): Promise<void> {
        continueBtnKey.value += 1
        setSelectedFrequency(selectedFrequency.value)
        await router.replace({ name: 'serviceForm' })
      }

      function newOptionSelected(event: EventTarget | null): void {
        const frequencyId = (event as HTMLInputElement)?.value
        if (frequencyId === '34') {
          selectedFrequency.value = [34]
        } else if (selectedFrequency.value.includes(34)) {
          selectedFrequency.value.splice(selectedFrequency.value.indexOf(34))
          selectedFrequency.value.push(parseInt(frequencyId))
        }
      }

      function frequencyCompletedBefore(frequencyId: number) {
        const completionRecExists = previouslyCompleted.value
          .filter((record) => record.serviceId === serviceId)
          .filter((record) => record.subServiceId === subServiceId)
          .find((record) => record.frequencyId.includes(frequencyId))

        return !!completionRecExists
      }

      function checkIfSkipPage() {
        if (frequencies.value.length === 1) {
          selectedFrequency.value = [frequencies.value[0].id]
          checkForDuplicateAndContinue()
        }
      }

      function serviceIsCompleted(): boolean {
        return selectedFrequency.value.every((frequency) =>
          frequencyCompletedBefore(frequency),
        )
      }

      function checkForDuplicateAndContinue() {
        continueBtnKey.value += 1
        if (serviceIsCompleted()) {
          showDuplicateServiceModal.value = true
        } else {
          confirmFrequencies()
        }
      }

      function openMyHistory() {
        showDuplicateServiceModal.value = false
        overlay().setOverlayComponent(UserHistory)
      }

      return {
        canContinue,
        checkForDuplicateAndContinue,
        confirmFrequencies,
        continueBtnKey,
        frequencies,
        frequencyCompletedBefore,
        hasSelectedWorkOrder,
        newOptionSelected,
        openMyHistory,
        router,
        selectedFrequency,
        selectedService,
        showDuplicateServiceModal,
        siteAddress,
        siteName,
      }
    },
  })
</script>
