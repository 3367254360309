<template>
  <div>
    <LoadingView :is-loading="isLoading" message="Checking accreditation" />
  </div>
</template>

<script setup lang="ts">
  import LoadingView from '@/views/LoadingView.vue'
  import { useRouter } from 'vue-router'
  import {
    AccreditationApiResponse,
    AccreditationCheckType,
    checkAccreditations,
    CustomerAccreditationType,
    getActiveCustomerAccreditations,
  } from '@/services/api/accreditation'
  import { ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useSiteStore } from '@/storage/site'
  import {
    CheckType,
    useSiteEntryConfirmationStore,
  } from '@/storage/siteEntryConfirmation'
  import { getSiteEntryCheck } from '@/components/use/siteEntryConfirmation'
  import { useUserStore } from '@/storage/user'
  import ReportableException from '@/exceptions/ReportableException'

  const { id: customerId } = storeToRefs(useCustomerStore())
  const { selectedContractorCompanyId, userType } = storeToRefs(useUserStore())
  const { id: siteId } = storeToRefs(useSiteStore())
  const { removeSiteEntryCheck, addSiteEntryCheck } =
    useSiteEntryConfirmationStore()

  const router = useRouter()
  const isLoading = ref(false)
  const accreditationsForUserType = ref<CustomerAccreditationType[]>([])
  const isFinalCheck = router.currentRoute.value.query?.finalCheck === '1'

  isLoading.value = true
  await startAccreditationCheck()
  routeToNext()
  isLoading.value = false

  async function startAccreditationCheck(): Promise<void> {
    accreditationsForUserType.value =
      await getCustomerAccreditationsForUserType(customerId.value)

    if (accreditationsForUserType.value.length > 0) {
      const accreditationCheck = await checkAccreditations(
        customerId.value,
        accreditationsForUserType.value,
        siteId.value,
        selectedContractorCompanyId.value,
        isFinalCheck,
      )

      handleSiteEntryChecks(accreditationCheck)
    }
  }

  async function getCustomerAccreditationsForUserType(customerId: number) {
    const customerAccreditations =
      await getActiveCustomerAccreditations(customerId)

    return customerAccreditations.filter(
      (accreditation: CustomerAccreditationType) => {
        return accreditation.userTypes.some(
          (accreditationUserType) =>
            accreditationUserType.id === userType.value.id,
        )
      },
    )
  }

  function handleSiteEntryChecks(accreditationCheck: AccreditationApiResponse) {
    const siteEntryCheck = getSiteEntryCheck('Accreditation')

    if (siteEntryCheck !== undefined) {
      removeSiteEntryCheck(siteEntryCheck)
    }

    addSiteEntryCheck({
      canContinue: accreditationCheck.meta.canContinue,
      checks: accreditationCheck.data,
      passed: accreditationCheck.meta.passed,
      title: 'Accreditation',
    })
  }

  async function routeToNext(): Promise<void> {
    if (canEditAccreditations()) {
      await router.replace({ name: 'editAccreditation' })
    } else {
      await router.replace({ name: 'inductionForm' })
    }
  }

  function isAccreditationCheckType(
    checks: (CheckType | AccreditationCheckType)[] | undefined,
  ): checks is AccreditationCheckType[] {
    return (
      checks !== undefined &&
      (checks[0] as AccreditationCheckType)?.accreditationId !== undefined
    )
  }

  function canEditAccreditations(): boolean {
    const siteEntryCheck = getSiteEntryCheck('Accreditation')

    if (siteEntryCheck === undefined) {
      return false
    }

    if (siteEntryCheck.checks?.length === 0) {
      return false
    }

    if (!isAccreditationCheckType(siteEntryCheck.checks)) {
      throw new ReportableException(
        'Unexpected check type on accreditation check',
        {
          check: siteEntryCheck,
        },
      )
    }

    const noAccreditationRequired = accreditationsForUserType.value.length === 0
    const updateAllowed = siteEntryCheck.checks.some(
      (check) => check.updateAllowed,
    )

    return (
      !noAccreditationRequired &&
      !siteEntryCheck.passed &&
      !isFinalCheck &&
      updateAllowed
    )
  }
</script>
