<template>
  <section class="text-center">
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <div class="mx-4 my-6 text-lg">
      <h1 class="my-8 text-2xl">{{ greeting }} {{ fullName }}</h1>
      <p v-if="allowVisitorsToSubmitMultiplePermits">
        Do you have more work orders to complete?
      </p>
      <div v-else>
        <p>Your sign in is now complete</p>
        <p>When you are ready to leave site, please checkout</p>
      </div>
    </div>
    <div class="mx-3 flex flex-1 text-base">
      <!--   allow visitors to submit multiple permit forms when they work on work orders that don't exist in our system     -->
      <SecondaryButton
        v-if="allowVisitorsToSubmitMultiplePermits"
        :key="goToPermitButtonKey"
        identifier="more-permits-button"
        action="MORE WORK ORDERS"
        @click="goToPermit"
      />
      <PrimaryButton
        :key="checkoutButtonKey"
        :disabled="isLoading"
        action="CHECKOUT"
        @click="goToCheckout"
      />
    </div>
    <LoadingView :is-loading="isLoading" />
  </section>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import SiteBanner from '@/components/SiteBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import LoadingView from '@/views/LoadingView.vue'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { usePermitFormStore } from '@/storage/permitForm'
  import { useSessionStore } from '@/storage/session'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'ReadyToCheckout',
    components: { LoadingView, PrimaryButton, SecondaryButton, SiteBanner },

    setup() {
      const router = useRouter()
      const { address: siteAddress, name: siteName } =
        storeToRefs(useSiteStore())
      const { fullName, isLoggedIn } = storeToRefs(useUserStore())
      const { addSessionEvent } = useSessionStore()
      const { currentEvent } = storeToRefs(useSessionStore())
      const { needsPermitPerService } = storeToRefs(useCustomerStore())
      const { hasSubmittedStartPermit } = storeToRefs(usePermitFormStore())

      const goToPermitButtonKey = ref(0)
      const checkoutButtonKey = ref(0)
      const hours = new Date().getHours()
      const greeting = computed(() => {
        return hours < 12
          ? 'Good Morning'
          : hours < 18
            ? 'Good Afternoon'
            : 'Good Evening'
      })
      const allowVisitorsToSubmitMultiplePermits = computed(
        () => needsPermitPerService.value && hasSubmittedStartPermit.value,
      )

      onMounted(() => {
        if (isLoggedIn.value && currentEvent.value !== 'ReadyToCheckoutEvent') {
          addSessionEvent('ReadyToCheckoutEvent')
        }
      })

      const isLoading = ref(false)
      async function goToCheckout() {
        isLoading.value = true
        checkoutButtonKey.value++

        await router.replace({ name: 'endPermit' })
        isLoading.value = false
      }

      async function goToPermit() {
        isLoading.value = true

        goToPermitButtonKey.value++
        await router.replace({ name: 'startPermit' })

        isLoading.value = false
      }

      return {
        allowVisitorsToSubmitMultiplePermits,
        checkoutButtonKey,
        fullName,
        goToCheckout,
        goToPermit,
        goToPermitButtonKey,
        greeting,
        isLoading,
        siteAddress,
        siteName,
      }
    },
  })
</script>
