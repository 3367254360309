import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useCustomerStore } from '@/storage/customer'
import InductionForm from '@/views/InductionForm.vue'
import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'

const guard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!useSiteEntryConfirmationStore().canContinue) {
    next({ name: 'siteEntryConfirmation' })
  } else if (useCustomerStore().hasInduction) {
    next()
  } else {
    next({ name: 'visitorArrival' })
  }
}

export default {
  beforeEnter: guard,
  component: InductionForm,
  meta: {
    loadingMessage: 'Loading induction...',
    showMenu: true,
  },
  name: 'inductionForm',
  path: '/induction/form',
}
