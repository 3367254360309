import { Ref, ref } from 'vue'
import config, { country } from '@/config'

export default function useCountry(): {
  getCountryByInternationalPhoneNumber: (phoneNumber: string) => Ref<country>
} {
  function getCountryByInternationalPhoneNumber(phoneNumber: string) {
    return ref(resolveCountry(phoneNumber, config.countries[0]))
  }

  function resolveCountry(
    phoneNumber: string,
    defaultCountry: country,
  ): country {
    for (let i = 4; i > 1; i--) {
      if (phoneNumber.length >= i) {
        const codeTest = phoneNumber.substr(0, i)

        const country = config.countries.find((country) => {
          return country.countryCallingCode === codeTest
        })

        if (country !== undefined) {
          return country
        }
      }
    }

    return defaultCountry
  }

  return {
    getCountryByInternationalPhoneNumber,
  }
}
