<template>
  <section class="mx-auto w-11/12 rounded-md border border-gray-300 p-3">
    <div class="mb-2 flex items-center justify-between">
      <h2 class="text-lg font-medium">Work Order: {{ workOrder.reference }}</h2>
      <button
        type="button"
        class="rounded-md border border-verified-blue bg-white px-3 py-1 text-center text-verified-blue opacity-70 transition-all duration-100 ease-out active:bg-opacity-80 disabled:opacity-20"
        @click="cancelWorkOrder"
      >
        CANCEL
      </button>
    </div>
    <p class="relative max-h-32 overflow-y-auto">
      {{ workOrder.description }}
    </p>
    <LoadingView :is-loading="loading" message="Returning to work orders..." />
  </section>
</template>

<script setup lang="ts">
  import { storeWorkOrderStatus, WorkOrder } from '@/services/api/workOrder'
  import { useRouter } from 'vue-router'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import LoadingView from '@/views/LoadingView.vue'
  import { ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useSiteStore } from '@/storage/site'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { useUserStore } from '@/storage/user'

  const workOrder = useWorkOrderStore().selectedWorkOrder as WorkOrder
  const router = useRouter()
  const { id: customerId, isCustomerStoreDefault } =
    storeToRefs(useCustomerStore())
  const loading = ref(false)

  function checkStoreState() {
    if (isCustomerStoreDefault.value) {
      throw new InvalidStoreException(
        {
          contractorCompanyId: useUserStore().selectedContractorCompanyId,
          customerId: customerId.value,
          siteId: useSiteStore().id,
        },
        ['WorkOrders'],
        'No customer selected',
      )
    }
  }

  async function cancelWorkOrder() {
    checkStoreState()

    loading.value = true
    await storeWorkOrderStatus(
      customerId.value,
      workOrder.id,
      null,
      'CANCELLED',
    )

    useWorkOrderStore().resetWorkOrder()

    await router.replace({ name: 'workOrder' })
    loading.value = false
  }
</script>
