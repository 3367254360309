import axios from 'axios'
import { UserType } from '@/services/api/customer'

export type AccreditationApiResponse = {
  data: AccreditationCheckType[]
  meta: {
    canContinue: boolean
    passed: boolean
  }
}

export type AccreditationCheckType = {
  accreditationId: number
  active: boolean
  description: string | null
  expiresAt: string | null
  feedback: string | null
  isStopWork: boolean
  name: string
  referenceId: string
  result: string
  type: string
  updateAllowed: boolean
  userAccreditationId: number
  validFrom: string
}

export type CustomerAccreditationType = {
  id: number
  isThirdParty: boolean
  name: string
  stopWork: boolean
  type: string
  userTypes: UserType[]
  accreditation: AccreditationType
}

export type UserAccreditationType = {
  accreditationId: number
  active: boolean
  description: string | null
  document: string | null
  expiresAt: string | null
  id: number
  isValid: boolean
  referenceId: string | null
  userId: number
  validFrom: string | null
}

export type UserAccreditationRequestType = Record<
  string,
  boolean | number | string
>

export type AccreditationType = {
  id: number
  name: string
  isThirdParty: boolean
  descriptionRequired: boolean
  referenceIdRequired: boolean
  validFromRequired: boolean
  expiresAtRequired: boolean
  documentRequired: boolean
  updateAllowed: boolean
  displayName: string
  userAccreditations: UserAccreditationType[]
}

export async function checkAccreditations(
  customerId: number,
  accreditations: CustomerAccreditationType[],
  siteId: number,
  contractorCompanyId: number,
  isFinalCheck: boolean,
): Promise<AccreditationApiResponse> {
  const response = await axios.get(`api/accreditationChecks`, {
    params: {
      accreditations: accreditations.map(
        (accreditation) => accreditation.accreditation.id,
      ),
      contractorCompanyId: contractorCompanyId,
      customerId: customerId,
      isFinalCheck: isFinalCheck,
      siteId: siteId,
    },
  })

  return response.data
}

export async function getActiveCustomerAccreditations(
  customerId: number,
): Promise<CustomerAccreditationType[]> {
  const response = await axios.get(`api/customers/${customerId}/accreditations`)

  return response.data.data
}

export async function getAccreditationWithUserAccreditations(
  accreditationIds: number[],
  userAccreditationIds: number[],
): Promise<AccreditationType[]> {
  const response = await axios.get(`api/accreditations`, {
    params: {
      accreditationIds: accreditationIds,
      userAccreditationIds: userAccreditationIds,
    },
  })

  return response.data.data
}

export async function storeUserAccreditation(
  data: UserAccreditationRequestType,
): Promise<UserAccreditationType> {
  data['active'] = true
  const response = await axios.post(
    `api/users/${data.userId}/accreditations`,
    data,
  )

  return response.data.data
}

export async function updateUserAccreditation(
  data: UserAccreditationRequestType,
): Promise<UserAccreditationType> {
  data['active'] = true
  const response = await axios.patch(
    `api/users/${data.userId}/accreditations/${data.userAccreditationId}`,
    data,
  )

  return response.data.data
}
