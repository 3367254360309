import axios, { AxiosResponse } from 'axios'
import { eventMap } from '@/utilities/session'
import { useSessionStore } from '@/storage/session'

const eventResponseInterceptor = (): void => {
  axios.interceptors.response.use(function (response: AxiosResponse) {
    const { addSessionEvent } = useSessionStore()
    if (response && response.headers['x-verified-capture-event']) {
      const captureEvent = response.headers['x-verified-capture-event']

      if (eventMap().has(captureEvent)) {
        addSessionEvent(captureEvent)
      }
    }

    return response
  })
}

export default eventResponseInterceptor
