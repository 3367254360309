import fetchUserByPhone, { authenticateUser } from '@/services/api/user'
import { useUserLogin } from '@/storage/userLogin'
import { useUserStore } from '@/storage/user'

export default function useAuth(): {
  checkUserExists: (phoneNumber: string) => Promise<void>
  checkUserIdentity: (phoneNumber: string, pin: string) => Promise<void>
} {
  const { setUserLoginExists, setUserLoginFirstName, setUserLoginIsVisitor } =
    useUserLogin()

  const {
    setUserAuthenticationResult,
    setUserFirstName,
    setUserId,
    setUserPhoneNumber,
    setUserContractorEmployeeId,
    setUserContractorCompanies,
    setEmployeePhoto,
    setUserLastName,
  } = useUserStore()

  async function checkUserExists(phoneNumber: string) {
    const user = await fetchUserByPhone(phoneNumber)
    setUserLoginExists(user.id > 0)
    setUserLoginFirstName(user.firstname)
    setUserLoginIsVisitor(user.isVisitor)
  }

  async function checkUserIdentity(phoneNumber: string, pin: string) {
    const response = await authenticateUser(phoneNumber, pin)
    setUserAuthenticationResult(true)
    setUserFirstName(response.firstname)
    setUserLastName(response.lastname)
    setUserId(response.id)
    setUserPhoneNumber(response.phone)
    setUserContractorEmployeeId(response.contractorEmployee.id)
    setUserContractorCompanies(response.contractorCompanies)
    setUserLoginIsVisitor(response.contractorCompanies.length === 0)
    setEmployeePhoto(response.contractorEmployee.employeePhoto)
  }

  return {
    checkUserExists,
    checkUserIdentity,
  }
}
