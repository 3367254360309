const setFocus = function () {
  const inputFields = document.querySelectorAll('input')
  Array.from(inputFields).some((el) => {
    if (el.value.length === 0) {
      el.focus()
      return true
    }
  })
}

export default setFocus
