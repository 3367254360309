<template>
  <div v-if="message" class="page-container">
    <div class="flex-container md:block">
      <TitleBanner class="header" title="Error occurred" />
      <div class="content mx-4 mt-4 justify-center overflow-y-scroll">
        <AlertBanner :content="message" :component-style="type" />
      </div>
      <div class="footer m-4">
        <div
          v-if="isSupportable"
          data-test="supportSection"
          class="mb-4 rounded-sm border border-verified-blue text-center"
        >
          <div class="my-4 text-center">
            <span class="text-lg">Please call Verified</span>
          </div>
          <div class="my-4">
            <a class="text-blue-500 underline" href="tel:1300133950"
              ><span class="font-bold">AUS:</span> 1300 133 950
            </a>
          </div>
          <div class="my-4">
            <a class="text-blue-500 underline" href="tel:0800000854"
              ><span class="font-bold">NZ:</span> 0800 000 854
            </a>
          </div>
        </div>
        <PrimaryButton
          data-test="closeErrorButton"
          action="OK"
          @click.once="closeErrorView"
        ></PrimaryButton>
      </div>
    </div>
  </div>
  <slot v-else></slot>
</template>

<script lang="ts">
  import { defineComponent, onErrorCaptured } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import redirect from '@/routes/errorRoutes'
  import handleError from '@/utilities/handleError'
  import AlertBanner from '@/components/AlertBanner.vue'
  import router from '@/router'
  import { useErrorStore } from '@/storage/error'
  import { storeToRefs } from 'pinia'

  export default defineComponent({
    name: 'ErrorView',

    components: { AlertBanner, PrimaryButton, TitleBanner },

    setup() {
      const { message, isSupportable, requiresReload, type } =
        storeToRefs(useErrorStore())

      onErrorCaptured((err, instance) => {
        handleError(err).then((errorHandled) => {
          if (errorHandled) {
            const errorOccurredOn = instance?.$route.name

            if (typeof errorOccurredOn === 'string') {
              redirect(errorOccurredOn)
            }
          } else {
            router.replace({ name: 'login' })
          }
        })

        return false
      })

      function closeErrorView() {
        if (requiresReload.value) {
          window.location.reload()
        }
        useErrorStore().resetError()
      }

      return {
        closeErrorView,
        isSupportable,
        message,
        type,
      }
    },
  })
</script>
