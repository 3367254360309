import axios from 'axios'

export type SiteEmployeesType = {
  id: number
  firstName: string
  lastName: string
  email: string
}

export async function retrieveSiteEmployees(
  siteId: number,
  userTypeId: number,
): Promise<SiteEmployeesType[] | false> {
  const response = await axios.get(
    `api/sites/${siteId}/employees?userTypeId=${userTypeId}`,
  )

  return response.data.data
}

export async function saveVisitorArrival(
  siteId: number,
  visitorCompanyName: string,
  employeeIds: number[],
  userTypeId: number,
  contactSiteRepresentative: boolean,
): Promise<void> {
  await axios.post(`api/sites/${siteId}/employees/visitor-arrival`, {
    contactSiteRepresentative: contactSiteRepresentative,
    employeeIds: employeeIds,
    userTypeId: userTypeId,
    visitorCompanyName: visitorCompanyName,
  })
}
