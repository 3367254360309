<template>
  <BaseModal v-if="shouldShow" title="Changes to permit / hazard check">
    <p class="mb-6">
      Are there any changes to the previous permit/hazard check?
    </p>
    <div class="flex flex-1">
      <SecondaryButton
        :key="removePermitDataButtonKey"
        button-classes="bg-opacity-0 active:bg-opacity-20 text-verified-blue"
        action="YES"
        @click="removePreviousPermitData"
      />
      <PrimaryButton
        :key="populatePermitDataButtonKey"
        action="NO"
        @click="populatePreviousPermitData"
      />
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import BaseModal from '@/components/BaseModal.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import SecondaryButton from '@/components/SecondaryButton.vue'

  const props = defineProps({
    show: {
      required: true,
      type: Boolean,
    },
  })

  const emit = defineEmits(['populate', 'remove'])

  const shouldShow = ref(false)
  const removePermitDataButtonKey = ref(0)
  const populatePermitDataButtonKey = ref(0)

  onMounted(() => {
    shouldShow.value = props.show
  })

  function removePreviousPermitData() {
    emit('remove')
    removePermitDataButtonKey.value++
    shouldShow.value = false
  }

  function populatePreviousPermitData() {
    emit('populate')
    populatePermitDataButtonKey.value++
    shouldShow.value = false
  }
</script>
