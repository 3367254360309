<template>
  <div>
    <TitleBanner title="Notifications" />
    <div class="px-4">
      <div class="mt-6 flow-root">
        <ul class="-my-5 divide-y divide-gray-200">
          <li
            v-for="notification in notifications"
            :key="notification.id"
            class="py-4"
          >
            {{ notification.message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TitleBanner from '@/components/TitleBanner.vue'
  import { storeToRefs } from 'pinia'
  import { useNotificationsStore } from '@/storage/notifications'

  const { notifications } = storeToRefs(useNotificationsStore())
</script>
