import axios from 'axios'

export type SessionValidationResponse = {
  sessionIsValid: boolean
}

export async function checkSessionIsValid(): Promise<SessionValidationResponse> {
  const response = await axios.get('api/session-validation')

  return response?.data?.data
}
