<template>
  <div class="relative">
    <TitleBanner title="Forgot PIN" />
    <div class="p-4">
      <section class="mt-7">
        <PhoneNumberInput
          :initial-phone-number="phoneNumber"
          :default-country-code="countryCode"
          :disabled="true"
        />
      </section>
      <section class="flex space-x-4 pt-3">
        <SecondaryButton
          action="Cancel"
          identifier="cancel-sending-sms"
          type="button"
          @click.once="backToLogin"
        ></SecondaryButton>
        <PrimaryButton
          action="Send SMS"
          identifier="send-sms-button"
          type="submit"
          :disabled="smsBtnDisabled"
          @click.once="sendSMS"
        ></PrimaryButton>
      </section>
      <BaseModal v-if="pinSent" title="Pin Sent" @close="backToLogin">
        <p>Your pin has been sent in an SMS</p>
        <div class="ml-auto mt-4 w-1/2">
          <PrimaryButton
            action="Back to Login"
            identifier="close-sms-modal"
            @click="backToLogin"
          />
        </div>
      </BaseModal>
    </div>
    <LoadingView :is-loading="smsBtnDisabled" message="Sending PIN..." />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import PhoneNumberInput from '@/components/PhoneNumberInput.vue'
  import { pinReset } from '@/services/api/user'
  import useCountry from '@/components/use/countries'
  import LoadingView from '@/views/LoadingView.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import BaseModal from '@/components/BaseModal.vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'ForgotPin',
    components: {
      BaseModal,
      LoadingView,
      PhoneNumberInput,
      PrimaryButton,
      SecondaryButton,
      TitleBanner,
    },

    setup() {
      const { getCountryByInternationalPhoneNumber } = useCountry()
      const { phoneNumber, isPhoneNumberDefault } = storeToRefs(useUserLogin())
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const router = useRouter()

      const countryCode = getCountryByInternationalPhoneNumber(
        phoneNumber.value,
      )?.value.countryCode
      const pinSent = ref(false)
      const smsBtnDisabled = ref(false)

      async function sendSMS() {
        smsBtnDisabled.value = true

        if (isPhoneNumberDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              phoneNumber: phoneNumber.value,
            },
            ['InvalidStore', 'StoreForgotPin'],
          )
        }

        const response = await pinReset(phoneNumber.value)
        pinSent.value = response.code === 0
        smsBtnDisabled.value = false
      }

      const backToLogin = async (): Promise<void> => {
        await router.replace({ name: 'login' })
      }

      return {
        backToLogin,
        countryCode,
        phoneNumber,
        pinSent,
        sendSMS,
        smsBtnDisabled,
      }
    },
  })
</script>
