<template>
  <div>
    <h3 class="mb-6 font-bold">LinkSafe</h3>
    <p class="my-5">Unable to connect to LinkSafe, continue to retry</p>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, PropType } from 'vue'
  import { AccreditationType } from '@/services/api/accreditation'

  const props = defineProps({
    accreditation: {
      required: true,
      type: Object as PropType<AccreditationType>,
    },
  })
  const emit = defineEmits(['doesntHaveAccreditation'])

  onMounted(() => {
    emit('doesntHaveAccreditation', props.accreditation?.id)
  })
</script>
