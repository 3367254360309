import axios from 'axios'
import { Answer } from '@verifiedit/form-generator'

export async function submitSupplementalForm(
  supplementalFormId: number,
  formData: { [key: string]: Answer },
  siteId: number,
) {
  return await axios.post('api/supplemental-forms', {
    formData: formData,
    siteId: siteId,
    supplementalFormId: supplementalFormId,
  })
}
