import { defineStore } from 'pinia'
import { computed, ComputedRef, Ref, ref } from 'vue'
import { Service } from '@/services/api/services'

export type CompletableService = Service & { completed: boolean }

export const useServicesStore = defineStore(
  'services',
  () => {
    /** state */
    const allServices = ref([] as CompletableService[])
    const servicesAtSite = ref([] as Service[])
    const selectedService: Ref<Service | null> = ref(null)
    const selectedServiceType = ref('')
    const serviceSiblings = ref<Service[]>([])

    /** getters */
    const hasSelectedService: ComputedRef<boolean> = computed(
      () => selectedService.value?.id !== null,
    )

    const isSelectedServiceDefault: ComputedRef<boolean> = computed(
      () => selectedService.value === null,
    )
    /** actions */
    function setServiceSiblings(value: Service[]): void {
      serviceSiblings.value = value
    }

    function setSelectedService(value: Service): void {
      selectedService.value = value
    }

    function setSelectedServiceType(value: string): void {
      selectedServiceType.value = value
    }

    function setServices(services: CompletableService[]) {
      services.forEach((service) => {
        const identical = allServices.value.filter(
          (serviceInStore: Service) =>
            serviceInStore.id === service.id &&
            serviceInStore.subServiceId === service.subServiceId,
        )
        if (identical.length === 0) {
          allServices.value.push(service)
        }
      })
    }

    const setSiteServices = (services: Service[]) => {
      services.forEach((service) => {
        const identical = servicesAtSite.value.filter(
          (serviceInStore: Service) =>
            serviceInStore.id === service.id &&
            serviceInStore.subServiceId === service.subServiceId,
        )

        if (identical.length === 0) {
          servicesAtSite.value.push(service)
        }
      })
    }

    function setCompleted(
      serviceId: number | null,
      subServiceId: number | null,
    ) {
      const service = allServices.value.find(
        (service) =>
          service.id === serviceId && service.subServiceId === subServiceId,
      )
      if (service !== undefined) {
        service.completed = true
      }
    }

    function resetServiceSiblings(): void {
      serviceSiblings.value = []
    }

    function resetServices() {
      allServices.value = []
      selectedService.value = null
      selectedServiceType.value = ''
      serviceSiblings.value = []
      servicesAtSite.value = []
    }

    return {
      allServices,
      hasSelectedService,
      isSelectedServiceDefault,
      resetServiceSiblings,
      resetServices,
      selectedService,
      selectedServiceType,
      serviceSiblings,
      servicesAtSite,
      setCompleted,
      setSelectedService,
      setSelectedServiceType,
      setServiceSiblings,
      setServices,
      setSiteServices,
    }
  },
  {
    persist: true,
  },
)
