import axios from 'axios'

export type LocationRequestedData = {
  location: Position
}

export type LocationRequestedResponse = {
  accuracy: number | null
  altitude: number | null
  latitude: number | null
  longitude: number | null
}

export const postLocation = async (
  locationData: LocationRequestedData,
): Promise<LocationRequestedResponse> => {
  try {
    const response = await axios.post(`/api/locations`, {
      location: locationData.location,
    })

    return response?.data?.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw error.message
  }
}
