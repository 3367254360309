import axios from 'axios'

export type Frequency = {
  description: string
  id: number
}

export const getSiteServiceFrequencies = async (
  siteId: number,
  serviceId: number | null,
  subServiceId: number | null,
): Promise<Frequency[]> => {
  const response = await axios.get(
    '/api/sites/' + siteId + '/services/' + serviceId + '/frequencies',
    {
      params: {
        subServiceId: subServiceId,
      },
    },
  )

  return response?.data.data ?? []
}

export const getServiceFrequencies = async (
  serviceId: number | null,
): Promise<Frequency[]> => {
  const response = await axios.get(
    '/api/services/' + serviceId + '/frequencies',
  )

  return response?.data.data ?? []
}
