import ServiceSiblingSelection from '../views/ServiceSiblingSelection.vue'

export default {
  component: ServiceSiblingSelection,
  meta: {
    loadingMessage: 'Loading services...',
    showMenu: true,
  },
  name: 'serviceSiblingSelection',
  path: '/related-services',
}
