<template>
  <div class="w-full">
    <Listbox v-model="selectedCountry">
      <div class="relative mt-1">
        <ListboxLabel
          class="absolute left-2 top-1 pl-1 text-xs text-blue-400"
          :class="{ 'opacity-50': disabled }"
        >
          Country Code
        </ListboxLabel>
        <ListboxButton
          class="selector-button h-12 w-full rounded-md border border-gray-200 px-2 py-1 text-left text-gray-800 duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:opacity-50"
          :class="classes"
          :disabled="disabled"
        >
          <span class="block truncate pt-4">
            <span class="pl-1.5"></span>
            <CountryFlag :iso="selectedCountry.countryCode"></CountryFlag>
            <span class="pr-1"></span>
            {{ selectedCountry.countryCode }}
            <span class="pr-1"></span>
            {{ selectedCountry.countryCallingCode }}
          </span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <Transition name="country-code-fade">
          <ListboxOptions
            class="absolute z-10 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base"
          >
            <ListboxOption
              v-for="country in countries"
              v-slot="{ active, selected }"
              :key="country.countryCode"
              :value="country"
              as="template"
              @click="selectCountry"
            >
              <li
                :class="[
                  active ? 'bg-blue-500 text-amber-900' : 'text-gray-900',
                  'relative cursor-default select-none',
                ]"
              >
                <div class="flex p-2">
                  <CountryFlag :iso="country.countryCode"></CountryFlag>
                  <span class="pr-1"></span>
                  <span
                    :class="[
                      active ? 'text-indigo-200' : 'text-gray-500',
                      'ml-1 truncate',
                    ]"
                  >
                    {{ country.countryCode }}
                    <span class="pr-1"></span>
                    {{ country.countryCallingCode }}
                  </span>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-black' : 'text-blue-500',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </div>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
  } from '@headlessui/vue'
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/solid'
  import config, { country } from '@/config'
  import CountryFlag from '@/components/CountryFlag.vue'

  export default defineComponent({
    name: 'CountryCodeSelector',
    components: {
      CheckIcon,
      ChevronUpDownIcon,
      CountryFlag,
      Listbox,
      ListboxButton,
      ListboxLabel,
      ListboxOption,
      ListboxOptions,
    },

    props: {
      classes: {
        type: Array,
        default: () => [],
      },

      defaultCountryCode: {
        type: String,
        default: 'AU',
      },

      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['selectCountry'],

    setup(props, { emit }) {
      const countries = config.countries
      const selectedCountry = ref<country>(
        countries.find(
          (country) => country.countryCode == props.defaultCountryCode,
        ) || countries[0],
      )

      const selectCountry = function (): void {
        emit('selectCountry', selectedCountry.value)
      }

      return {
        countries,
        selectCountry,
        selectedCountry,
      }
    },
  })
</script>

<style>
  .country-code-fade-leave-active-class {
    @apply transition duration-100 ease-in;
  }

  .country-code-fade-leave-from-class {
    @apply opacity-100;
  }

  .country-code-fade-leave-to-class {
    @apply opacity-0;
  }
</style>
