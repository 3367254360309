<template>
  <div class="flex justify-between gap-4 py-2">
    <a
      class="w-3/4 truncate text-blue-600 underline"
      target="_blank"
      rel="noopener noreferrer"
      :href="documentEndpoint"
    >
      {{ document.documentName }}
    </a>
    <a :href="documentEndpoint + '&download'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="mr-4 h-6 w-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
        />
      </svg>
    </a>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from 'vue'
  import { getSiteDocumentMeta, VerifiedDocument } from '@/services/api/site'
  import { getCustomerDocumentMeta } from '@/services/api/customer'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'DocumentListItem',

    props: {
      document: {
        type: Object as PropType<VerifiedDocument>,
        required: true,
      },

      documentType: {
        type: String,
        default: '',
        required: true,
      },
    },

    async setup(props) {
      const { id: siteId, isSiteStoreDefault } = storeToRefs(useSiteStore())
      const { id: customerId, isCustomerStoreDefault } =
        storeToRefs(useCustomerStore())
      const documentEndpoint = ref('')
      documentEndpoint.value = await getDocumentEndpoint()

      async function getDocumentEndpoint() {
        let endpoint = ''

        if (props.documentType === 'site') {
          if (isSiteStoreDefault.value) {
            throw new InvalidStoreException(
              {
                documentId: props.document.id,
                fingerprint: useFingerprintStore().fingerprint,
                siteId: siteId.value,
                userId: useUserStore().id,
              },
              ['InvalidStore', 'FetchSiteDocuments'],
            )
          }

          const meta = await getSiteDocumentMeta(
            siteId.value,
            props.document.id,
          )
          endpoint = meta.origin
        }

        if (props.documentType === 'customer') {
          if (isCustomerStoreDefault.value) {
            throw new InvalidStoreException(
              {
                customer: customerId.value,
                documentId: props.document.id,
                fingerprint: useFingerprintStore().fingerprint,
                siteId: siteId.value,
                userId: useUserStore().id,
              },
              ['InvalidStore', 'FetchCustomerDocuments'],
            )
          }

          const meta = await getCustomerDocumentMeta(
            customerId.value,
            props.document.id,
          )
          endpoint = meta.origin
        }

        return endpoint
      }

      return {
        documentEndpoint,
      }
    },
  })
</script>
