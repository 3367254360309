import {
  ResolvableRouteInterface,
  StrategyInterface,
} from '@/types/strategyInterface'
import StrategySelector from '@/strategies/StrategySelector'
import router from '@/router'
import { RouteLocationNamedRaw } from 'vue-router'

function isResolvable(
  strategy: ResolvableRouteInterface | StrategyInterface,
): strategy is ResolvableRouteInterface {
  return (<ResolvableRouteInterface>strategy).resolve !== undefined
}

function strategyResolver() {
  const setupStrategy = new StrategySelector().select()
  setupStrategy.setup()
  if (isResolvable(setupStrategy)) {
    const routeTo = setupStrategy.resolve() as RouteLocationNamedRaw

    if (!(router.currentRoute.value.name === routeTo.name)) {
      router.replace(routeTo)
    }
  }
}

export default strategyResolver
