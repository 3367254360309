import {
  ResolvableRouteInterface,
  StrategyInterface,
} from '@/types/strategyInterface'
import { useNativeScanStore } from '@/storage/nativeScan'
import { RouteLocationRaw } from 'vue-router'
import resetStore from '@/components/use/resetStore'

class NoStateNativeScanStrategy
  implements StrategyInterface, ResolvableRouteInterface
{
  private readonly uuid

  constructor(uuid: string) {
    this.uuid = uuid
  }

  setup() {
    resetStore()
    useNativeScanStore().setUuid(this.uuid)
  }

  resolve(): RouteLocationRaw {
    return { name: 'login' } as RouteLocationRaw
  }
}

export default NoStateNativeScanStrategy
