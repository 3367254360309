import {
  ResolvableRouteInterface,
  StrategyInterface,
} from '@/types/strategyInterface'
import resetStore from '@/components/use/resetStore'
import { RouteLocationRaw } from 'vue-router'
import Log from '@/utilities/Log'

export default class DefaultStrategy
  implements StrategyInterface, ResolvableRouteInterface
{
  setup(): void {
    resetStore()
    Log.storeReset('DefaultStrategy.ts', 'setup')
  }

  resolve(): RouteLocationRaw {
    return { name: 'login' } as RouteLocationRaw
  }
}
