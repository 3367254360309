import { NavigationFailure } from 'vue-router'
import router from '@/router'

interface RouteTo {
  errorRouteName: string
  to: () => Promise<void | NavigationFailure | undefined>
}

const routeToMap: RouteTo[] = [
  {
    errorRouteName: 'confirmNearbySite',
    to: async () => await router.replace({ name: 'selectNearbySite' }),
  },
  {
    errorRouteName: 'confirmBypassSite',
    to: async () => await router.replace({ name: 'selectBypassSites' }),
  },
  {
    errorRouteName: 'contractorCompanySelection',
    to: async () => await router.replace({ name: 'selectNearbySite' }),
  },
]

async function redirect(errorOccurredOn: string): Promise<void> {
  const routeTo = routeToMap.find(
    (routeTo) => routeTo.errorRouteName === errorOccurredOn,
  )

  if (routeTo) {
    await routeTo.to()
  }
}

export default redirect
