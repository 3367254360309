import { instruction } from '@/config/'
import locationServicesIOS from '@/assets/location-services-ios.svg'
import privacyIOS from '@/assets/privacy-ios.png'
import radioSelectAndroid from '@/assets/radio-select-android.png'
import buttonSliderAndroid from '@/assets/button-slider-android.png'
import buttonSliderIOS from '@/assets/button-slider-ios.png'
import chromeIcon from '@/assets/chrome.png'
import settingsAndroid from '@/assets/settings-android.svg'
import locationPin from '@/assets/location-pin.svg'
import samsungInternetIcon from '@/assets/samsung-internet.svg'
import safariIcon from '@/assets/safari.jpg'
import settingIcon from '@/assets/setting.png'

const androidChrome: instruction[] = [
  {
    content: 'Settings',
    icon: settingsAndroid,
  },
  {
    content: 'Location',
    icon: locationPin,
  },
  {
    content: "Use Location Set to 'On'",
    icon: buttonSliderAndroid,
  },
  {
    content: 'App permissions',
  },
  {
    content: 'Select Browser',
    icon: chromeIcon,
  },
  {
    content: 'Allow while using the app',
    icon: radioSelectAndroid,
  },
  {
    content: "Precise Location 'On'",
    icon: buttonSliderAndroid,
  },
]

const androidSamsung: instruction[] = [
  {
    content: 'Settings',
    icon: settingsAndroid,
  },
  {
    content: 'Location',
    icon: locationPin,
  },
  {
    content: "Use Location Set to 'On'",
    icon: buttonSliderAndroid,
  },
  {
    content: 'App permissions',
  },
  {
    content: 'Select Browser',
    icon: samsungInternetIcon,
  },
  {
    content: 'Allow while using the app',
    icon: radioSelectAndroid,
  },
  {
    content: "Precise Location 'On'",
    icon: buttonSliderAndroid,
  },
]

const iosSafari: instruction[] = [
  {
    content: 'Settings',
    icon: settingIcon,
  },
  {
    content: 'Privacy',
    icon: privacyIOS,
  },
  {
    content: 'Location Services',
    icon: locationServicesIOS,
  },
  {
    content: 'Set Safari Website to',
    icon: safariIcon,
  },
  {
    content: "'Allow while using'",
  },
  {
    content: 'Precise Location On',
    icon: buttonSliderIOS,
  },
]

const iosDeviceList = ['ios', 'mac os x']

export default {
  androidChrome: androidChrome,
  androidSamsung: androidSamsung,
  iosDeviceList: iosDeviceList,
  iosSafari: iosSafari,
}
