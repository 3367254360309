import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFormsStore = defineStore(
  'forms',
  () => {
    /** state */
    const serviceRecordId = ref<number>(0)

    /** getters */

    /** actions */
    function setServiceRecordId(value: number) {
      serviceRecordId.value = value
    }

    function resetForms() {
      serviceRecordId.value = 0
    }

    return {
      resetForms,
      serviceRecordId,
      setServiceRecordId,
    }
  },
  {
    persist: true,
  },
)
