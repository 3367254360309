import {
  StrategyInterface,
  StrategySelectorContract,
} from '@/types/strategyInterface'
import DefaultStrategy from '@/strategies/DefaultStrategy'

export default class DefaultStrategySelector
  implements StrategySelectorContract
{
  can(): boolean {
    return true
  }

  getStrategy(): StrategyInterface {
    return new DefaultStrategy()
  }
}
