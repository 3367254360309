<template>
  <div class="h-80">
    <LMap v-model:zoom="zoom" :center="center" :use-global-leaflet="false">
      <LTileLayer
        :attribution="attribution"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></LTileLayer>
      <LMarker :lat-lng="center"></LMarker>
    </LMap>
  </div>
</template>

<script setup lang="ts">
  import 'leaflet/dist/leaflet.css'
  import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
  import { ref } from 'vue'

  defineProps({
    center: {
      required: true,
      type: Object,
    },
  })
  const attribution =
    '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors'
  const zoom = ref(15)
</script>
