import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useCustomerStore } from '@/storage/customer'
import CheckAccreditation from '@/views/CheckAccreditation.vue'

const guard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const store = useCustomerStore()

  if (store.hasAccreditation) {
    next()
  } else {
    next({ name: 'inductionForm' })
  }
}

export default {
  beforeEnter: guard,
  component: CheckAccreditation,
  meta: {
    loadingMessage: 'Checking accreditations...',
    showMenu: true,
  },
  name: 'checkAccreditation',
  path: '/accreditations/check',
}
