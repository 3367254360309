import Echo, { Channel as EchoChannel } from 'laravel-echo'
import { ForceCheckoutEvent } from '@/handlers/forceCheckout'

type EventData = ForceCheckoutEvent

export interface handler {
  (data: EventData): void
}

interface listener {
  event: string
  handler: handler
}

export default class UserChannel {
  private channel: EchoChannel | undefined
  private echo: Echo | undefined

  constructor(echo: Echo | undefined) {
    this.echo = echo
  }

  join(channelName: string): UserChannel {
    this.channel = this.echo?.private(channelName)

    return this
  }

  attachListeners(listeners: listener[]) {
    listeners.forEach((listener) => {
      this.channel?.listen(listener.event, listener.handler)
    })
  }
}
