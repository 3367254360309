import axios, { AxiosError, AxiosResponse } from 'axios'
import config from '@/config'
import { useAppStore } from '@/storage/app'

const tokenResponseInterceptor = (): void => {
  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      if (response && response.headers['x-verified-token']) {
        // update the x-verified-token in store for future use
        const appStore = useAppStore()
        appStore.token = response.headers['x-verified-token'].substring(7)
      }

      return response
    },
    function (error: AxiosError) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger

      // if a 401 (Unauthorized) is returned from the API then the token (or lack
      // there of) is invalid and the user has accessed the site by an invalid url
      if (error?.response?.status === 401) {
        window.location.replace(config.unauthorized.redirect.url)
      }

      // TODO: implement honeybadger logging of 5xx and 4xx errors

      return Promise.reject(error)
    },
  )
}

export default tokenResponseInterceptor
