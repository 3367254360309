import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNativeScanStore = defineStore(
  'native/scan',
  () => {
    /** state */
    const uuid = ref('')

    /** getters */

    /** actions */
    function setUuid(value: string): void {
      uuid.value = value
    }

    function resetNativeScan() {
      uuid.value = ''
    }

    return {
      resetNativeScan,
      setUuid,
      uuid,
    }
  },
  {
    persist: true,
  },
)
