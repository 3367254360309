import { useFingerprintStore } from '@/storage/fingerprint'

const isIPadDevice = () =>
  navigator.vendor === 'Apple Computer, Inc.' && navigator.maxTouchPoints > 0

const isIOS = () => {
  return useFingerprintStore().getOs === 'iOS' || isIPadDevice()
}

export { isIOS }
