import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import WorkOrderSelection from '@/views/WorkOrderSelection.vue'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'
import { useCustomerStore } from '@/storage/customer'
import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'

const guard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { hasWorkOrder } = storeToRefs(useCustomerStore())
  const { isVisitor } = storeToRefs(useUserLogin())
  const { passedWorkOrderCheck } = storeToRefs(useSiteEntryConfirmationStore())

  if (!isVisitor.value && hasWorkOrder.value && passedWorkOrderCheck.value) {
    next()
  } else {
    next({ name: 'startPermit' })
  }
}

export default {
  beforeEnter: guard,
  component: WorkOrderSelection,
  meta: {
    loadingMessage: 'Loading work orders...',
    showMenu: true,
  },
  name: 'workOrder',
  path: '/workOrder',
}
