import { defineStore } from 'pinia'
import { ref } from 'vue'
import { SupplementalFormType } from '@verifiedit/form-generator'
import { RouteLocationNormalized } from 'vue-router'

export const useSupplementalFormStore = defineStore(
  'supplementalForm',
  () => {
    /** state */
    const forms = ref<SupplementalFormType[]>([])
    const currentSupplementalForm = ref<SupplementalFormType | null>(null)
    const routeFrom = ref<RouteLocationNormalized | null>(null)
    const routeTo = ref<RouteLocationNormalized | null>(null)

    /** getters */

    /** actions */
    function setSupplementalForms(value: SupplementalFormType[]): void {
      forms.value = value
    }

    function addSupplementalForm(value: SupplementalFormType): void {
      if (
        forms.value.find((formInStore) => formInStore.title === value.title)
      ) {
        return
      }
      forms.value.push(value)
    }

    function removeSupplementalForm(value: SupplementalFormType): void {
      const supplemental = forms.value.find(
        (supplemental) => supplemental.title === value.title,
      )
      if (supplemental) {
        forms.value.splice(forms.value.indexOf(supplemental), 1)
      }
    }

    function setCurrentSupplementalForm(value: SupplementalFormType) {
      currentSupplementalForm.value = value
    }

    function setRouteFrom(value: RouteLocationNormalized) {
      routeFrom.value = value
    }

    function setRouteTo(value: RouteLocationNormalized) {
      routeTo.value = value
    }

    function resetSupplementalForm() {
      currentSupplementalForm.value = null
      forms.value = []
      routeFrom.value = null
      routeTo.value = null
    }
    return {
      addSupplementalForm,
      currentSupplementalForm,
      forms,
      removeSupplementalForm,
      resetSupplementalForm,
      routeFrom,
      routeTo,
      setCurrentSupplementalForm,
      setRouteFrom,
      setRouteTo,
      setSupplementalForms,
    }
  },
  {
    persist: true,
  },
)
