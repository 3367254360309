<template>
  <div v-if="show" class="fixed bottom-4 w-full">
    <div
      id="snackbar"
      class="mx-auto flex w-11/12 items-center justify-between rounded-md bg-slate-700 px-3 py-2 text-gray-50 md:w-[450px]"
    >
      <p>
        {{ currentNotification?.message }}
      </p>
      <button type="button" class="px-4 py-2 text-green-600" @click="close">
        OK
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import {
    useNotificationsStore,
    ViewableSystemNotification,
  } from '@/storage/notifications'

  const show = ref(true)
  const { currentNotification } = storeToRefs(useNotificationsStore())
  const { setSeen } = useNotificationsStore()

  let timeout

  onMounted(() => {
    timeout = setTimeout(() => {
      close()
    }, 30000)
  })

  function close() {
    clearTimeout(timeout)
    setSeen(currentNotification.value as ViewableSystemNotification)
    show.value = false
  }
</script>
