import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import overlay from '@/components/use/overlay'
import ReadyToCheckout from '@/views/ReadyToCheckout.vue'
import SiteHazardOverlay from '@/views/SiteHazardOverlay.vue'
import { useSiteStore } from '@/storage/site'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (useSiteStore().siteHazards.length > 0 && !useSiteStore().seenHazards) {
    overlay().setOverlayComponent(SiteHazardOverlay)
  }

  next()
}

export default {
  beforeEnter: guard,
  component: ReadyToCheckout,
  meta: {
    loadingMessage: 'Loading check out...',
    showMenu: true,
  },
  name: 'readyToCheckout',
  path: '/ready-to-checkout',
}
