<template>
  <div>
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <section class="px-4">
      <h1 class="supplemental-title mt-4 text-center text-lg">
        {{ supplemental?.title }}
      </h1>
      <FormGenerator
        :key="formGeneratorKey"
        :schema="schema"
        @form-changed="formChangedEventHandler"
        @visibility-changed="updateVisibleComponentsEventHandler"
        @update-component-visibility="updateComponentVisibilityEventHandler"
        @persist-photo="storePhotoEventHandler"
        @remove-photo="removePhotoEventHandler"
      />
      <PrimaryButton
        :key="submitButtonKey"
        identifier="supplemental-form-submit"
        action="Submit"
        class="mt-6 w-full rounded-sm bg-blue-500 p-3 text-white hover:opacity-50"
        type="submit"
        @click.once="submitForm"
      />
    </section>
    <LoadingView :is-loading="isLoading" :message="loadingMessage" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, reactive, ref, toRaw, watch } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import { RouteLocationNormalized, useRouter } from 'vue-router'
  import {
    Answer,
    checkAnswers,
    errorMessage,
    FormGenerator,
    ImageAnswer,
    removePhoto,
    SchemaType,
    scrollToFirstError,
    storePhoto,
    SupplementalFormType,
    TrolleyCollection,
    updateComponentVisibility,
    updateVisibleComponents,
    validateFormSubmission,
  } from '@verifiedit/form-generator'
  import { submitSupplementalForm } from '@/services/api/supplementalForm'
  import LoadingView from '@/views/LoadingView.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { removeImage, storeImage } from '@/services/api/image'
  import { loadResource } from '@/components/use/form'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'
  import { useSupplementalFormStore } from '@/storage/supplementalForms'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'SupplementalForm',

    components: {
      FormGenerator,
      LoadingView,
      PrimaryButton,
      SiteBanner,
    },

    setup() {
      const {
        address: siteAddress,
        name: siteName,
        id: siteId,
        isSiteStoreDefault,
      } = storeToRefs(useSiteStore())
      const { id: userId, isUserStoreDefault } = storeToRefs(useUserStore())
      const { id: customerId, isCustomerStoreDefault } =
        storeToRefs(useCustomerStore())
      const { currentSupplementalForm, routeTo } = storeToRefs(
        useSupplementalFormStore(),
      )
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const router = useRouter()
      const supplemental = ref<SupplementalFormType | null>(null)
      const schema = ref<(SchemaType & errorMessage)[]>([])
      const submittableComponentNames = ref<string[]>([])
      const answers: Map<string, Answer> = reactive(new Map())
      const formGeneratorKey = ref<number>(0)

      function updateSupplementalForm() {
        supplemental.value = JSON.parse(
          JSON.stringify(toRaw(currentSupplementalForm.value)),
        )

        schema.value = supplemental.value?.template.schema as (SchemaType &
          errorMessage)[]

        schema.value.forEach((component) => {
          component.errorMessage = ''
        })

        passInResourceFunction()

        formGeneratorKey.value++
      }

      updateSupplementalForm()

      watch(currentSupplementalForm, () => {
        if (currentSupplementalForm.value !== null) {
          updateSupplementalForm()
        }
      })

      function passInResourceFunction() {
        schema.value
          .filter((component) => typeof component.resource !== 'undefined')
          .forEach((component) => {
            component.resourceFunction =
              loadResource as SchemaType['resourceFunction']
          })
      }

      const isLoading = ref<boolean>(false)
      const loadingMessage = ref<string>('Loading form...')
      const submitButtonKey = ref<number>(0)

      async function submitForm(): Promise<void> {
        submitButtonKey.value += 1
        const canSubmit = validateFormSubmission(
          schema.value as (SchemaType & errorMessage)[],
          answers,
          submittableComponentNames.value,
        )

        if (!canSubmit) {
          // eslint-disable-next-line vue/next-tick-style
          await nextTick()
          scrollToFirstError()
          return
        }

        if (isSiteStoreDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'StoreSupplementalFormSubmission'],
          )
        }
        loadingMessage.value = 'Saving form...'
        isLoading.value = true

        const response = await submitSupplementalForm(
          supplemental.value?.identifier as number,
          Object.fromEntries(answers),
          siteId.value,
        )
        if (response.data.data.code === 200) {
          useSupplementalFormStore().removeSupplementalForm(
            supplemental.value as SupplementalFormType,
          )
          await router.replace(routeTo.value as RouteLocationNormalized)
        }
        isLoading.value = false
      }

      function formChangedEventHandler(
        changedComponentName: string,
        userInput: string | (string | ImageAnswer | TrolleyCollection)[],
      ) {
        checkAnswers(changedComponentName, userInput, schema.value, answers)
      }

      function updateVisibleComponentsEventHandler(
        components: Map<string, string>,
      ) {
        submittableComponentNames.value = updateVisibleComponents(
          components,
          answers,
        )
      }

      function updateComponentVisibilityEventHandler(
        identifier: string,
        result: { items: string[]; value: boolean },
        key: string,
      ) {
        updateComponentVisibility(identifier, result, key, schema.value)
      }

      async function storePhotoEventHandler(
        imageData: string,
        imageTypeId: number,
        sequenceOfImage: number,
        identifier: string,
      ) {
        if (
          isCustomerStoreDefault.value ||
          isSiteStoreDefault.value ||
          isUserStoreDefault.value
        ) {
          throw new InvalidStoreException(
            {
              customerId: customerId.value,
              fingerprint: fingerprint.value,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'StoreFormPhotoUpload'],
          )
        }

        loadingMessage.value = 'Saving image...'
        isLoading.value = true

        await storePhoto(
          imageData,
          imageTypeId,
          sequenceOfImage,
          identifier,
          schema.value,
          () =>
            storeImage(
              customerId.value,
              siteId.value,
              userId.value,
              imageData,
              imageTypeId,
              sequenceOfImage,
            ),
        )
        isLoading.value = false
      }

      async function removePhotoEventHandler(
        imageId: string,
        identifier: string,
      ) {
        isLoading.value = true
        loadingMessage.value = 'Removing image...'
        await removePhoto(
          imageId,
          identifier,
          schema.value,
          answers,
          removeImage,
        )
        isLoading.value = false
      }

      return {
        answers,
        formChangedEventHandler,
        formGeneratorKey,
        isLoading,
        loadingMessage,
        removePhotoEventHandler,
        schema,
        siteAddress,
        siteName,
        storePhotoEventHandler,
        submitButtonKey,
        submitForm,
        supplemental,
        updateComponentVisibilityEventHandler,
        updateVisibleComponentsEventHandler,
      }
    },
  })
</script>
