import RuntimeException from '@/exceptions/RuntimeException'
import resetStore from '@/components/use/resetStore'
import Log from '@/utilities/Log'
import config from '@/config'
import SupportableException from '@/exceptions/SupportableException'
import RecoverableException from '@/exceptions/RecoverableException'
import Honeybadger from '@honeybadger-io/js'
import { useErrorStore } from '@/storage/error'

export default async function handleError(err: Error) {
  const honeybadger = Honeybadger.configure(config.honeyBadger)
  let handled = true
  switch (err.constructor.name) {
    case 'MaintenanceModeException':
      useErrorStore().setType('information')
      break
    case 'NewMajorVersionException':
      useErrorStore().setRequiresReload(true)
      useErrorStore().setType('warning')
      break
    default:
      useErrorStore().setType('danger')
      break
  }
  useErrorStore().setMessage(err.message)
  useErrorStore().setIsSupportable(err instanceof SupportableException)

  if (!(err instanceof RecoverableException)) {
    honeybadger?.notify(err)
    if (err instanceof RuntimeException) {
      resetStore()
      Log.storeReset('handleError.ts', 'handleError')
      handled = false
    }
  }

  return handled
}
