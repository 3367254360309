import VisitorRegistration from '../views/VisitorRegistration.vue'

export default {
  component: VisitorRegistration,
  meta: {
    loadingMessage: 'Loading registration...',
    returnTo: 'login',
    showMenu: false,
  },
  name: 'visitorRegistration',
  path: '/register',
}
