<template>
  <section>
    <div class="mt-4 flex justify-center" @click="toggleShow">
      <h2
        data-test="workOrdersTitle"
        class="my-2 text-center text-xl font-bold"
      >
        {{ title }}
      </h2>
      <ChevronDownIcon
        v-if="workOrders.length > 0"
        class="ml-2 w-4 stroke-2"
        :class="{ 'rotate-180': showWorkOrders }"
      />
    </div>
    <div v-if="showWorkOrders">
      <div v-if="workOrders.length === 0" class="border-y border-gray-400">
        <p data-test="noWorkOrders" class="mx-4 my-2">
          There are no {{ title }} work orders for this site.
        </p>
      </div>
      <ul v-else class="mx-2 space-y-4">
        <li v-for="workOrder in workOrders" :key="workOrder.id">
          <WorkOrderItem
            :work-order="workOrder"
            @click="selectWorkOrder(workOrder)"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
  import { PropType, ref } from 'vue'
  import { WorkOrder } from '@/services/api/workOrder'
  import WorkOrderItem from '@/components/WorkOrderItem.vue'
  import { ChevronDownIcon } from '@heroicons/vue/24/outline'

  const props = defineProps({
    title: {
      required: true,
      type: String,
    },
    workOrders: {
      default: () => [],
      type: Array as PropType<WorkOrder[]>,
    },
  })

  const emit = defineEmits<{
    workOrderSelected: [workOrder: WorkOrder]
  }>()

  const showWorkOrders = ref(true)
  function selectWorkOrder(workOrder: WorkOrder) {
    emit('workOrderSelected', workOrder)
  }

  function toggleShow() {
    if (props.workOrders?.length === 0) {
      return
    }
    showWorkOrders.value = !showWorkOrders.value
  }
</script>
