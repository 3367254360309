<template>
  <span
    v-if="iso"
    :class="[flag, mode]"
    :title="title || iso"
    class="fi"
  ></span>
</template>

<script lang="ts">
  import 'flag-icons/css/flag-icons.css'
  import { defineComponent, computed } from 'vue'

  export default defineComponent({
    name: 'CountryFlag',

    props: {
      iso: {
        required: true,
        type: String,
      },

      squared: {
        default: false,
        type: Boolean,
      },

      title: {
        default: null,
        type: String,
      },
    },

    setup(props) {
      const flag = computed((): string => {
        return 'fi-' + props.iso.toLowerCase()
      })

      const mode = computed((): string => {
        return props.squared ? 'fis' : ''
      })

      return {
        flag,
        mode,
      }
    },
  })
</script>
