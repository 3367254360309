import ConfirmNearbySite from '@/views/ConfirmNearbySite.vue'

export default {
  component: ConfirmNearbySite,
  meta: {
    loadingMessage: 'Loading site...',
    requiresLocation: true,
    returnTo: 'selectNearbySite',
    showMenu: true,
  },
  name: 'confirmNearbySite',
  path: '/sites/nearby/confirm',
}
