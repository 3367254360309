import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { BypassReasonContract } from '@/services/api/bypassReason'

export const useBypassStore = defineStore(
  'bypass',
  () => {
    /** state */
    const selectedBypassReason = ref<BypassReasonContract>({
      hasDate: false,
      hasDescription: false,
      id: 0,
      name: '',
    })
    const checkInDate = ref('')
    const reasonDescription = ref('')
    const scanBypassed = ref(false)

    /** getters */
    const getBypass: ComputedRef<Bypass | null> = computed(() => {
      if (scanBypassed.value) {
        const reason = reasonDescription.value

        return {
          checkInDate: checkInDate.value || null,
          reason: (reason !== '' && reason) || selectedBypassReason.value.name,
        }
      }

      return null
    })

    /** actions */
    function setCheckInDate(value: string): void {
      checkInDate.value = value
    }

    function setReasonDescription(value: string): void {
      reasonDescription.value = value
    }

    function setScanBypassed(value: boolean): void {
      scanBypassed.value = value
    }

    function setSelectedBypassReason(bypassReason: BypassReasonContract): void {
      selectedBypassReason.value = bypassReason
    }

    function resetBypass() {
      selectedBypassReason.value = {
        hasDate: false,
        hasDescription: false,
        id: 0,
        name: '',
      }
      checkInDate.value = ''
      reasonDescription.value = ''
      scanBypassed.value = false
    }

    return {
      checkInDate,
      getBypass,
      reasonDescription,
      resetBypass,
      scanBypassed,
      selectedBypassReason,
      setCheckInDate,
      setReasonDescription,
      setScanBypassed,
      setSelectedBypassReason,
    }
  },
  {
    persist: true,
  },
)
