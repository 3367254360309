<template>
  <div class="relative">
    <label v-if="label" for="location" :class="[labelClasses]">
      {{ label }}
    </label>
    <select
      id="location"
      v-model="selected"
      name="location"
      :disabled="disabled"
      :class="[selectElementStyle, { 'text-gray-500': !selected }]"
    >
      <option v-if="placeholder" selected hidden :value="null">
        {{ placeholder }}
      </option>
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
  import { SelectOption } from '@/config'

  export default defineComponent({
    name: 'BaseSelector',

    props: {
      defaultOption: {
        type: [String, Number, null] as PropType<string | number | null>,
        default: null,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      label: {
        type: String,
        default: '',
      },

      labelClasses: {
        type: Array,
        default: () => [],
      },

      options: {
        type: Array as PropType<SelectOption[]>,
        default: () => [],
      },

      placeholder: {
        type: String,
        default: '',
      },

      selectElementStyle: {
        type: String,
        default:
          'block border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 mt-1 pl-3 pr-10 py-3 rounded-md text-sm sm:text-base w-full',
      },

      shouldAutoSelect: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['optionChanged'],

    setup(props, { emit }) {
      const selected = ref<string | number | null>(null)

      onMounted(() => {
        if (props.defaultOption) {
          selected.value = props.defaultOption
        }

        if (props.shouldAutoSelect && props.options?.length === 1) {
          selected.value = props.options[0].id
        }
      })

      watch(selected, () => {
        emit('optionChanged', selected.value)
      })

      return {
        selected,
      }
    },
  })
</script>
