import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { useSiteStore } from '@/storage/site'
import overlap from '@/components/use/distance'
import config from '@/config'
import { useAppStore } from '@/storage/app'

export const useUserPositionStore = defineStore(
  'user/position',
  () => {
    /** state */
    const position = ref({
      accuracy: 0,
      altitude: 0,
      latitude: 0,
      longitude: 0,
    } as Position)

    /** getters */
    const isUserOnSite: ComputedRef<boolean> = computed(() => {
      const siteStore = useSiteStore()
      const site = {
        latitude: siteStore.latitude,
        longitude: siteStore.longitude,
      }
      const user = {
        latitude: position.value.latitude,
        longitude: position.value.longitude,
      }
      return overlap(
        user,
        site,
        config.distance.radius.user,
        config.distance.radius.site,
      )
    })

    const isUserPositionEmpty: ComputedRef<boolean> = computed(
      () => position.value.latitude === 0 || position.value.longitude === 0,
    )

    /** actions */
    async function setUserPosition(): Promise<void> {
      try {
        setIsLoading(true)
        const currentPosition = await generateNewPosition()
        position.value = {
          accuracy: currentPosition.coords.accuracy,
          altitude: currentPosition.coords.altitude,
          latitude: currentPosition.coords.latitude,
          longitude: currentPosition.coords.longitude,
        }
      } catch (error: unknown) {
        resetUserPosition()
        throw error
      } finally {
        setIsLoading(false)
      }
    }

    function setIsLoading(value: boolean) {
      const appStore = useAppStore()
      appStore.loading.set('position', value)
    }

    const resetUserPosition = () => {
      position.value = {
        accuracy: 0,
        altitude: 0,
        latitude: 0,
        longitude: 0,
      }
    }

    return {
      isUserOnSite,
      isUserPositionEmpty,
      position,
      resetUserPosition,
      setIsLoading,
      setUserPosition,
    }
  },
  {
    persist: true,
  },
)

function generateNewPosition() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      resolve,
      ({ code, message }) =>
        reject(
          Object.assign(new Error(message), {
            code: code,
            name: 'PositionError',
          }),
        ),
      {
        enableHighAccuracy: true,
        maximumAge: 0, // 0 = disable cache to request new location each time
        timeout: 8000, // 8 seconds
      },
    )
  }) as Promise<GeolocationPosition>
}
