import { defineStore } from 'pinia'
import { ref, computed, ComputedRef } from 'vue'
import { Answer, errorMessage, SchemaType } from '@verifiedit/form-generator'

export const usePermitFormStore = defineStore(
  'permitForm',
  () => {
    /** state */
    const startPermit = ref<(SchemaType & errorMessage)[]>([])
    const endPermit = ref<(SchemaType & errorMessage)[]>([])
    const answers = ref<{ [key: string]: Answer }>({})
    const endPermitId = ref<number | null>(null)
    const startPermitId = ref<number | null>(null)

    /** getters */
    const getPermitAnswers: ComputedRef<Map<string, Answer>> = computed(
      () => new Map(Object.entries(answers.value)),
    )

    const hasEndPermit: ComputedRef<boolean> = computed(
      () => endPermit.value.length !== 0,
    )

    const hasSubmittedStartPermit: ComputedRef<boolean> = computed(
      () => Object.keys(answers.value).length !== 0,
    )

    /** actions */
    function setPermitAnswers(value: { [key: string]: Answer }): void {
      answers.value = value
    }

    function setStartPermitId(value: number): void {
      startPermitId.value = value
    }

    function setEndPermitId(value: number): void {
      endPermitId.value = value
    }

    function setStartPermit(value: SchemaType[]): void {
      value.forEach((component) => {
        component.errorMessage = ''
      })
      startPermit.value = value as (SchemaType & errorMessage)[]
    }

    function setEndPermit(value: SchemaType[]): void {
      value.forEach((component) => {
        component.errorMessage = ''
      })
      endPermit.value = value as (SchemaType & errorMessage)[]
    }
    function resetPermitForm(): void {
      answers.value = {}
      endPermit.value = []
      startPermit.value = []
      endPermitId.value = null
      startPermitId.value = null
    }

    return {
      answers,
      endPermit,
      endPermitId,
      getPermitAnswers,
      hasEndPermit,
      hasSubmittedStartPermit,
      resetPermitForm,
      setEndPermit,
      setEndPermitId,
      setPermitAnswers,
      setStartPermit,
      setStartPermitId,
      startPermit,
      startPermitId,
    }
  },
  { persist: true },
)
