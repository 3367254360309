import { version } from '~/package.json'

interface Config {
  apiKey: string
  breadcrumbsEnabled: boolean
  environment: string
  revision: string
  reportData: boolean
}

export const honeyBadger: Config = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY || '',
  breadcrumbsEnabled: true,
  environment: import.meta.env.VITE_HONEYBADGER_ENV,
  reportData:
    import.meta.env.VITE_HONEYBADGER_ENV === 'production' ||
    import.meta.env.VITE_HONEYBADGER_REPORT_DATA === 'true' ||
    false,
  revision: version,
}
