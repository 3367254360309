<template>
  <div v-if="show" class="page-container">
    <div class="flex-container md:block">
      <TitleBanner class="header" title="Network issues detected" />
      <div class="content mx-4 mt-4 justify-center">
        <AlertBanner
          content="Please move to an area with network coverage."
          component-style="warning"
        />
      </div>
      <div class="footer m-4">
        <PrimaryButton
          :key="retryButtonKey"
          action="RETRY"
          type="button"
          @click="attemptRestore"
        />
      </div>
    </div>
  </div>
  <slot v-else></slot>
</template>

<script lang="ts">
  import TitleBanner from '@/components/TitleBanner.vue'
  import { defineComponent, ref } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import AlertBanner from '@/components/AlertBanner.vue'

  export default defineComponent({
    name: 'NetworkError',
    components: { AlertBanner, PrimaryButton, TitleBanner },

    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },

    setup() {
      const retryButtonKey = ref<number>(0)
      const attemptRestore = () => {
        retryButtonKey.value++
        window.location.reload()
      }

      return {
        attemptRestore,
        retryButtonKey,
      }
    },
  })
</script>

<style scoped>
  .warning-colour {
    background-color: #fff2d9;
  }
</style>
