<template>
  <div
    :class="[
      `${componentStyle}-banner`,
      'flex w-full items-center rounded-md shadow-lg',
    ]"
  >
    <div :class="[`${componentStyle}-banner`, 'flex']">
      <Component :is="iconComponent" class="mx-1 h-6 w-6 md:h-8 md:w-8" />
    </div>
    <div class="w-full bg-white px-2 py-4">
      <p class="font-bold">{{ bannerMap.get(componentStyle)?.heading }}</p>
      <p class="text-sm" data-test="alert-message">{{ content }}</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XCircleIcon,
  } from '@heroicons/vue/24/outline'

  export default defineComponent({
    name: 'AlertBanner',
    components: {
      CheckCircleIcon,
      ExclamationTriangleIcon,
      InformationCircleIcon,
      XCircleIcon,
    },

    props: {
      componentStyle: {
        type: String,
        default: 'information',
        validator: (value: string) => {
          return ['information', 'warning', 'success', 'danger'].includes(value)
        },
      },

      content: {
        type: String,
        default: '',
      },
    },

    setup(props) {
      const bannerMap = new Map<string, { heading: string; component: string }>(
        [
          [
            'information',
            { component: 'InformationCircleIcon', heading: 'Info' },
          ],
          [
            'warning',
            { component: 'ExclamationTriangleIcon', heading: 'Warning' },
          ],
          ['danger', { component: 'XCircleIcon', heading: 'Error' }],
          ['success', { component: 'CheckCircleIcon', heading: 'Success' }],
        ],
      )

      const iconComponent = computed((): string => {
        return bannerMap.get(props.componentStyle)?.component || ''
      })

      return {
        bannerMap,
        iconComponent,
      }
    },
  })
</script>

<style scoped>
  .information-banner {
    background-color: #d4e3fb;
  }

  .success-banner {
    background-color: #dfedd7;
  }

  .warning-banner {
    background-color: #fff2d9;
  }

  .danger-banner {
    background-color: #fcd2e0;
  }

  .information-banner > svg {
    @apply stroke-blue-800;
  }

  .warning-banner > svg {
    @apply stroke-yellow-800;
  }

  .danger-banner > svg {
    @apply stroke-red-800;
  }

  .success-banner > svg {
    @apply stroke-green-800;
  }
</style>
