import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'

export const usePermitFormSubmissionsStore = defineStore(
  'permitFormSubmissions',
  () => {
    /** state */
    const id = ref<number | null>(null)

    /** getters */
    const permitFormSubmitted: ComputedRef<boolean> = computed(
      () => id.value !== null,
    )

    /** actions */
    function setId(value: number) {
      id.value = value
    }

    function resetPermitFormSubmissions() {
      id.value = null
    }

    return {
      id,
      permitFormSubmitted,
      resetPermitFormSubmissions,
      setId,
    }
  },
  {
    persist: true,
  },
)
