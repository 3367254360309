import {
  StrategyInterface,
  StrategySelectorContract,
} from '@/types/strategyInterface'
import { useSessionStore } from '@/storage/session'
import CurrentSessionStrategy from '@/strategies/CurrentSessionStrategy'
import { useUserStore } from '@/storage/user'

export default class CurrentSessionStrategySelector
  implements StrategySelectorContract
{
  can = (): boolean => {
    return useUserStore().isLoggedIn && useSessionStore().events.length > 0
  }

  getStrategy = (): StrategyInterface => {
    return new CurrentSessionStrategy()
  }
}
