<template>
  <button
    class="primary"
    :class="[identifier, buttonClasses]"
    :type="type"
    :disabled="disabled"
    @click.once="click"
  >
    {{ action }}
  </button>
</template>

<script lang="ts">
  import { ButtonHTMLAttributes, defineComponent, PropType } from 'vue'

  export default defineComponent({
    name: 'PrimaryButton',

    props: {
      action: {
        type: String,
        default: 'Continue',
      },

      buttonClasses: {
        type: String,
        default: '',
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      identifier: {
        type: String,
        default: '',
      },

      type: {
        type: String as PropType<ButtonHTMLAttributes['type']>,
        default: 'button',
      },
    },

    emits: ['click'],

    setup(props, { emit }) {
      const click = function (event: unknown) {
        emit('click', event)
      }

      return {
        click,
      }
    },
  })
</script>
