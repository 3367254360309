<template>
  <div class="relative z-[5] grid grid-cols-10 bg-verified-blue py-5">
    <div class="pl-2">
      <ChevronLeftIcon
        v-if="showBackArrow"
        class="h-6 w-6 text-white"
        @click="routeTo"
      />
      <XMarkIcon
        v-if="isOverlay && closeableOverlay"
        class="h-6 w-6 text-white"
        @click="hideOverlay"
      />
    </div>
    <div class="back-arrow col-span-8 text-center">
      <VerifiedLogo :height="logoHeight" :width="logoWidth" class="mb-1 mt-1" />
    </div>
    <div class="z-10 text-right">
      <HamburgerMenu :with-menu="showHamburgerMenu"></HamburgerMenu>
    </div>
    <div
      v-if="hasBanner()"
      class="fixed -right-8 top-1.5 flex rotate-30 overflow-hidden truncate p-0 leading-3"
    >
      <p
        class="m-0 w-24 flex-none bg-white px-4 pt-0.5 text-center font-['Syncopate'] text-verified-xs text-verified-blue"
      >
        {{ banner }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { ChevronLeftIcon, XMarkIcon } from '@heroicons/vue/24/solid'
  import VerifiedLogo from '@/components/VerifiedLogo.vue'
  import { computed, defineComponent, ref } from 'vue'
  import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
  import HamburgerMenu from '@/components/HamburgerMenu.vue'
  import config from '@/config'
  import overlay from '@/components/use/overlay'
  import { getCurrentEventRoute } from '@/utilities/session'

  export default defineComponent({
    name: 'NavigationSlot',
    components: { ChevronLeftIcon, HamburgerMenu, VerifiedLogo, XMarkIcon },
    props: {
      isOverlay: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const router = useRouter()
      const route = useRoute()
      const logoHeight = 30
      const logoWidth = 150
      const banner = ref(config.app.bannerText)
      const { hideOverlay, getCloseable } = overlay()

      function hasBanner() {
        return !!banner.value
      }

      function getBackRoute(): RouteLocationRaw {
        return route.meta.returnTo
          ? ({ name: route.meta.returnTo } as RouteLocationRaw)
          : getCurrentEventRoute()
      }

      async function routeTo(): Promise<void> {
        await router.replace(getBackRoute())
      }

      const showHamburgerMenu = computed(() => {
        return !!route.meta.showMenu
      })

      const showBackArrow = computed(() => {
        return route.meta.returnTo !== undefined && !props.isOverlay
      })

      return {
        banner,
        closeableOverlay: getCloseable(),
        hasBanner,
        hideOverlay,
        logoHeight,
        logoWidth,
        routeTo,
        showBackArrow,
        showHamburgerMenu,
      }
    },
  })
</script>
