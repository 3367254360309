<template>
  <div>
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <div class="space-y-2 p-4 text-center text-lg">
      <span class="block">Form Completed</span>
      <span class="block">Conf No. {{ serviceRecordId }}</span>
    </div>
    <hr class="border-1 text-gray-300" />
    <div class="space-y-2 p-4 text-center text-lg">
      <span class="mb-6 block">
        Do you have more
        {{ hasWorkOrder && passedWorkOrderCheck ? 'work orders' : 'services' }}
        to complete?
      </span>
      <div class="grid grid-cols-2 gap-2">
        <SecondaryButton
          identifier="more-services-button"
          :action="
            hasWorkOrder && passedWorkOrderCheck
              ? 'MORE WORK ORDERS'
              : 'MORE SERVICES'
          "
          @click="goToServiceSelection"
        />
        <PrimaryButton
          identifier="check-out-button"
          action="CHECKOUT"
          @click="checkout"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useFormsStore } from '@/storage/forms'
  import { useServicesStore } from '@/storage/services'
  import { useSiteStore } from '@/storage/site'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'

  export default defineComponent({
    name: 'ServiceCheckout',
    components: {
      PrimaryButton,
      SecondaryButton,
      SiteBanner,
    },

    setup() {
      const { address: siteAddress, name: siteName } =
        storeToRefs(useSiteStore())
      const { hasWorkOrder } = storeToRefs(useCustomerStore())
      const { selectedService } = storeToRefs(useServicesStore())
      const { serviceRecordId } = storeToRefs(useFormsStore())
      const { passedWorkOrderCheck } = storeToRefs(
        useSiteEntryConfirmationStore(),
      )
      const router = useRouter()

      onMounted(() => {
        nextTick(() => {
          window.scrollTo(0, 0)
        })
      })

      async function checkout(): Promise<void> {
        await router.replace({ name: 'endPermit' })
      }

      async function goToServiceSelection(): Promise<void> {
        await router.replace({ name: 'workOrder' })
      }

      return {
        checkout,
        goToServiceSelection,
        hasWorkOrder,
        passedWorkOrderCheck,
        router,
        selectedService,
        serviceRecordId,
        siteAddress,
        siteName,
      }
    },
  })
</script>
