<template>
  <div class="mb-2 space-y-2">
    <fieldset>
      <legend class="mb-2">Is this work order finished?</legend>
      <div>
        <input
          id="workOrderComplete"
          class="my-1.5 mr-4 text-verified-blue ring-verified-blue"
          type="radio"
          name="workOrderStatus"
          value="COMPLETED"
          @click="updateWorkOrderCompleteStatus"
        />
        <label for="workOrderComplete">Yes - Close work order</label>
      </div>
      <div>
        <input
          id="workOrderIncomplete"
          class="my-1.5 mr-4 text-verified-blue ring-verified-blue"
          type="radio"
          name="workOrderStatus"
          value="IN_PROGRESS"
          @click="updateWorkOrderCompleteStatus"
        />
        <label for="workOrderIncomplete">No - I have not finished</label>
      </div>
    </fieldset>
    <BaseSelector
      v-if="workOrderCompleteStatus === 'COMPLETED'"
      placeholder="Resolution reason"
      :options="availableResolutions"
      @option-changed="setWorkOrderResolutionDescription"
    />
    <span v-if="hasErrors" class="error">Select a resolution reason.</span>
  </div>
</template>

<script setup lang="ts">
  import BaseSelector from '@/components/BaseSelector.vue'
  import { computed, toRef } from 'vue'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { storeToRefs } from 'pinia'

  const props = defineProps({
    showErrors: {
      default: false,
      type: Boolean,
    },
  })

  const { setWorkOrderResolutionDescription, setWorkOrderCompleteStatus } =
    useWorkOrderStore()
  const { selectedWorkOrder, workOrderCompleteStatus, canSubmitWorkOrder } =
    storeToRefs(useWorkOrderStore())

  const hasErrors = computed(
    () => toRef(props.showErrors).value && !canSubmitWorkOrder.value,
  )

  const availableResolutions =
    selectedWorkOrder.value?.availableResolutions.map((resolution) => {
      return {
        id: resolution,
        name: resolution,
      }
    })

  function updateWorkOrderCompleteStatus(event: Event) {
    const value = (event.target as HTMLInputElement).value as
      | 'IN_PROGRESS'
      | 'COMPLETED'
    if (value === 'IN_PROGRESS') {
      setWorkOrderResolutionDescription(null)
    }
    setWorkOrderCompleteStatus(value)
  }
</script>
