<template>
  <div>
    <SiteBanner :site-address="siteAddress" :site-name="siteName" />
    <div class="my-4 flex gap-x-4 px-4">
      <BaseSearchBar
        class="flex-grow"
        data-test="workOrderSearch"
        placeholder="Search Work Order Number"
        @search="searchQueryChanged"
      />
      <button
        type="button"
        class="self-center rounded-full border border-verified-blue"
        :disabled="loading"
        aria-label="Refresh work orders"
        @click="loadWorkOrders"
      >
        <ArrowPathIcon
          class="m-2 h-8 w-8 rounded-full p-1 text-verified-blue opacity-80"
        />
      </button>
    </div>
    <div class="mb-10 space-y-5 divide-y">
      <WorkOrderList
        title="Preventative / Routine"
        :work-orders="preventativeWorkOrders"
        @work-order-selected="workOrderSelected"
      />
      <WorkOrderList
        title="Reactive / Repair"
        :work-orders="reactiveWorkOrders"
        @work-order-selected="workOrderSelected"
      />
    </div>
    <LoadingView :is-loading="loading" :message="loadingMessage" />
  </div>
</template>

<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import BaseSearchBar from '@/components/BaseSearchBar.vue'
  import {
    getWorkOrders,
    storeWorkOrderStatus,
    WorkOrder,
  } from '@/services/api/workOrder'
  import WorkOrderList from '@/components/WorkOrderList.vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import LoadingView from '@/views/LoadingView.vue'
  import { useRouter } from 'vue-router'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { ArrowPathIcon } from '@heroicons/vue/24/outline'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  const allWorkOrders = ref<WorkOrder[]>([])
  const filteredWorkOrders = ref<WorkOrder[]>([])
  const preventativeWorkOrders = computed<WorkOrder[]>(() =>
    filteredWorkOrders.value.filter(
      (workOrder) => workOrder.type === 'PREVENTATIVE',
    ),
  )
  const reactiveWorkOrders = computed<WorkOrder[]>(() =>
    filteredWorkOrders.value.filter(
      (workOrder) => workOrder.type === 'REACTIVE',
    ),
  )
  const loading = ref(false)
  const loadingMessage = ref('Loading work orders...')
  const router = useRouter()
  const { setSelectedWorkOrder, resetWorkOrder } = useWorkOrderStore()
  const { selectedContractorCompanyId } = storeToRefs(useUserStore())
  const {
    id: siteId,
    address: siteAddress,
    name: siteName,
  } = storeToRefs(useSiteStore())
  const { id: customerId, isCustomerStoreDefault } =
    storeToRefs(useCustomerStore())

  onMounted(async () => {
    await loadWorkOrders()
    resetWorkOrder()
  })

  function checkStoreState() {
    if (isCustomerStoreDefault.value) {
      throw new InvalidStoreException(
        {
          contractorCompanyId: selectedContractorCompanyId.value,
          customerId: customerId.value,
          siteId: siteId.value,
        },
        ['WorkOrders'],
        'No customer selected',
      )
    }
  }

  function moveCompletedWorkOrdersToEnd(workOrders: WorkOrder[]) {
    const completedWorkOrders = workOrders.filter(
      (workOrder) => workOrder.status === 'COMPLETED',
    )

    workOrders = workOrders.filter(
      (workOrder) => workOrder.status !== 'COMPLETED',
    )

    workOrders.push(...completedWorkOrders)

    return workOrders
  }

  async function loadWorkOrders(): Promise<void> {
    loadingMessage.value = 'Loading work orders...'
    loading.value = true
    checkStoreState()

    let response = await getWorkOrders(
      customerId.value as number,
      siteId.value,
      selectedContractorCompanyId.value,
    )

    if (useWorkOrderStore().selectedWorkOrder) {
      ;(
        response.find(
          (order) => order.id === useWorkOrderStore().selectedWorkOrder?.id,
        ) as WorkOrder
      ).status = useWorkOrderStore().workOrderCompleteStatus
    }

    if (response.length === 0) {
      await router.replace({ name: 'startPermit' })
      loading.value = false
      return
    }

    response = moveCompletedWorkOrdersToEnd(response)

    allWorkOrders.value = filteredWorkOrders.value = response

    loading.value = false
  }

  function searchQueryChanged(search: string): void {
    filteredWorkOrders.value = allWorkOrders.value.filter((workOrder) =>
      workOrder.reference.includes(filterPrefix(search.toLowerCase())),
    )
  }

  function filterPrefix(search: string): string {
    if (search.startsWith('wo ')) {
      return search.substring(3)
    }
    if (search.startsWith('wo')) {
      return search.substring(2)
    }
    if (search.startsWith('w')) {
      return search.substring(1)
    }
    return search
  }

  async function workOrderSelected(workOrder: WorkOrder) {
    setSelectedWorkOrder(workOrder)
    checkStoreState()

    loadingMessage.value = 'Selecting work order...'
    loading.value = true
    await storeWorkOrderStatus(
      customerId.value as number,
      workOrder.id,
      null,
      'STARTED',
    )

    await router.replace({ name: 'startPermit' })
    loading.value = false
  }
</script>
