<template>
  <div class="bg-white py-4 text-center drop-shadow-md">
    <p class="text-lg font-bold">
      {{ siteName }}
    </p>
    <p class="text-sm">
      {{ siteAddress }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SiteBanner',

    props: {
      siteAddress: {
        type: String,
        default: '',
      },

      siteName: {
        type: String,
        default: '',
      },
    },
  })
</script>
