import resetStore from '@/components/use/resetStore'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import Log from '@/utilities/Log'
import BypassSite from '@/views/BypassSite.vue'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isVisitor } = storeToRefs(useUserLogin())
  if (isVisitor.value) {
    resetStore()
    Log.storeReset('bypassSite.ts', 'guard')
    next({ name: 'login' })
  } else {
    next()
  }
}

export default {
  beforeEnter: guard,
  component: BypassSite,
  meta: {
    loadingMessage: 'Loading selections...',
    returnTo: 'selectNearbySite',
    showMenu: true,
  },
  name: 'bypass',
  path: '/bypass',
}
