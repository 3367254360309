import FingerprintJS, {
  defaultEndpoint,
  defaultScriptUrlPattern,
} from '@fingerprintjs/fingerprintjs-pro'
import config from '@/config'
import ReportableException from '@/exceptions/ReportableException'
import handleError from '@/utilities/handleError'
import { useFingerprintStore } from '@/storage/fingerprint'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/storage/user'

export async function initialiseFingerprint(): Promise<boolean> {
  const { resetFingerprint, setFingerprint, setIsLoading } =
    useFingerprintStore()
  const { hasExpired, hasFingerprint } = storeToRefs(useFingerprintStore())
  const { isLoggedIn } = storeToRefs(useUserStore())

  if (hasFingerprint.value && hasExpired.value && !isLoggedIn.value) {
    resetFingerprint()
  }

  if (!hasFingerprint.value) {
    setIsLoading(true)
    try {
      const fingerprint = await FingerprintJS.load({
        apiKey: config.fingerprint.token,
        endpoint: [config.fingerprint.endpoint, defaultEndpoint],
        scriptUrlPattern: [
          config.fingerprint.scriptUrlPattern,
          defaultScriptUrlPattern,
        ],
      })
      setFingerprint(await fingerprint.get({ extendedResult: true }))
    } catch (e) {
      resetFingerprint()

      await handleError(
        new ReportableException(
          'Error occurred trying to load application. Please try again.',
          { error: e },
          ['FingerprintError'],
        ),
      )
    } finally {
      setIsLoading(false)
    }
  }
  return hasFingerprint.value
}
