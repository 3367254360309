<template>
  <main class="px-4">
    <h1 class="my-6 text-center text-2xl">Select your host</h1>
    <div>
      <section v-if="siteEmployees?.length !== 0">
        <ComboboxInput
          :options="siteEmployees"
          label="Who are you visiting?"
          identifier="visitor-arrival-employee-selection"
          :is-valid="errorMessage === ''"
          :disabled="isLoading"
          :allow-multi-selection="true"
          placeholder="Select or type employee name"
          @option-changed="employeeSelected"
          @max-selection-reached="maxSelectionReached"
        >
          <span v-if="errorMessage" class="error m-1 !my-0 block">
            {{ errorMessage }}
          </span>
        </ComboboxInput>
      </section>
      <section v-else>
        <p>
          There is no available site employee. Please speak to the site
          representative.
        </p>
      </section>
    </div>
    <div>
      <PrimaryButton
        :key="'visitor-arrival-continue-button-' + continueButtonKey"
        class="mt-4"
        identifier="accreditation-form-continue"
        action="Continue"
        :disabled="
          siteEmployees?.length !== 0 && selectedSiteEmployees?.length === 0
        "
        @click.once="notifySiteEmployees"
      />
    </div>
    <LoadingView :is-loading="isLoading" message="Sending notifications..." />
  </main>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import ComboboxInput from '@/components/ComboboxInput.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import {
    retrieveSiteEmployees,
    saveVisitorArrival,
    SiteEmployeesType,
  } from '@/services/api/visitorArrival'
  import { storeToRefs } from 'pinia'
  import { useUserStore } from '@/storage/user'
  import { useSiteStore } from '@/storage/site'
  import { useRouter } from 'vue-router'
  import LoadingView from '@/views/LoadingView.vue'
  import { useUserVisitorCompaniesStore } from '@/storage/userVisitorCompanies'

  const { userType } = storeToRefs(useUserStore())
  const { id: siteId } = storeToRefs(useSiteStore())
  const { lastSelected: visitorCompanyName } = storeToRefs(
    useUserVisitorCompaniesStore(),
  )

  const selectedSiteEmployees = ref<{ id: number; name: string }[]>([])
  const siteEmployees = ref<{ id: number; name: string }[]>([])
  const errorMessage = ref('')
  const isLoading = ref(false)
  const router = useRouter()
  const continueButtonKey = ref(0)

  isLoading.value = true
  await getSiteEmployees()
  isLoading.value = false

  async function getSiteEmployees() {
    const siteEmployeeData = await retrieveSiteEmployees(
      siteId.value,
      userType.value.id,
    )

    if (typeof siteEmployeeData === 'boolean' && !siteEmployeeData) {
      await router.replace({ name: 'siteEntryConfirmation' })
      return
    }

    siteEmployees.value = siteEmployeeData.map(
      (employee: SiteEmployeesType) => {
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
        }
      },
    )
  }

  function employeeSelected(employee: { id: number; name: string }[]) {
    selectedSiteEmployees.value = employee
  }

  async function notifySiteEmployees() {
    continueButtonKey.value += 1
    if (errorMessage.value !== '') {
      return
    }

    const employeeIds = selectedSiteEmployees.value.map(
      (employee) => employee.id,
    )

    isLoading.value = true

    await saveVisitorArrival(
      siteId.value,
      visitorCompanyName.value,
      employeeIds,
      userType.value.id,
      siteEmployees.value?.length === 0,
    )
    await router.replace({ name: 'siteEntryConfirmation' })

    isLoading.value = false
  }

  function maxSelectionReached(limitReached: boolean): void {
    if (limitReached) {
      errorMessage.value = `Up to 5 employees can be selected.`
      return
    }

    errorMessage.value = ''
  }
</script>
