import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import VisitorArrival from '@/views/VisitorArrival.vue'
import { useCustomerStore } from '@/storage/customer'
import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'

const guard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!useSiteEntryConfirmationStore().canContinue) {
    next({ name: 'siteEntryConfirmation' })
  } else if (useCustomerStore().hasVisitorArrivalNotification) {
    next()
  } else {
    next({ name: 'siteEntryConfirmation' })
  }
}

export default {
  beforeEnter: guard,
  component: VisitorArrival,
  meta: {
    loadingMessage: 'Loading options...',
    showMenu: true,
  },
  name: 'visitorArrival',
  path: '/visitor-arrival',
}
