import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { Customer, VerifiedDocument } from '@/services/api/site'

export const useCustomerStore = defineStore(
  'customer',
  () => {
    /** state */
    const email = ref('')
    const hasAccreditation = ref(false)
    const hasCheckList = ref(false)
    const hasGPS = ref(false)
    const hasInduction = ref(false)
    const hasSpotChecks = ref(false)
    const hasNotice = ref(false)
    const hasVisitorManagement = ref(false)
    const hasVisitorArrivalNotification = ref(false)
    const hasWorkOrder = ref(false)
    const id = ref<number>(0)
    const name = ref('')
    const phone = ref('')
    const postcode = ref('')
    const state = ref('')
    const street = ref('')
    const town = ref('')
    const customerDocuments = ref([] as VerifiedDocument[])
    const requiresMultiplePermitSubmissions = ref(false)
    const requiresSupervisorSignature = ref(false)
    const supervisorChecklist = ref<string[]>([])
    const needsPermitPerService = ref(false)

    /** getters */
    const getNeedsPermitPerService: ComputedRef<boolean> = computed(
      () => hasCheckList.value && needsPermitPerService.value,
    )

    const getHasSpotChecks: ComputedRef<boolean> = computed(
      () => hasCheckList.value && hasSpotChecks.value,
    )

    const shouldShowSiteEntry: ComputedRef<boolean> = computed(
      () => hasAccreditation.value || hasInduction.value || hasWorkOrder.value,
    )

    const isCustomerStoreDefault: ComputedRef<boolean> = computed(
      () => id.value === 0,
    )

    /** actions */
    function setCustomerDocuments(value: VerifiedDocument[]) {
      customerDocuments.value = value
    }

    function setCustomerInfo(customer: Customer): void {
      id.value = customer.id
      name.value = customer.name
      street.value = customer.street
      town.value = customer.town
      postcode.value = customer.postcode
      state.value = customer.state
      phone.value = customer.phone
      email.value = customer.email
      hasInduction.value = customer.hasInduction
      hasSpotChecks.value = customer.hasSpotChecks
      hasWorkOrder.value = customer.hasWorkOrder
      hasVisitorManagement.value = customer.hasUnregisteredContractor
      hasCheckList.value = customer.hasCheckList
      hasNotice.value = customer.hasNotice
      hasGPS.value = customer.hasGPS
      hasAccreditation.value = customer.hasAccreditation
      hasVisitorArrivalNotification.value =
        customer.hasVisitorArrivalNotification
      requiresSupervisorSignature.value = customer.requiresSupervisorSignature
      supervisorChecklist.value =
        customer.supervisorChecklist !== undefined
          ? customer.supervisorChecklist
          : []
      needsPermitPerService.value = customer.needsPermitPerService
      if (customer.customerDocuments !== undefined) {
        customerDocuments.value = customer.customerDocuments
      } else {
        customerDocuments.value = []
      }
    }

    function resetCustomer() {
      email.value = ''
      hasAccreditation.value = false
      hasCheckList.value = false
      hasGPS.value = false
      hasInduction.value = false
      hasSpotChecks.value = false
      hasNotice.value = false
      hasVisitorManagement.value = false
      hasWorkOrder.value = false
      hasVisitorArrivalNotification.value = false
      id.value = 0
      name.value = ''
      phone.value = ''
      postcode.value = ''
      state.value = ''
      street.value = ''
      town.value = ''
      customerDocuments.value = []
      requiresMultiplePermitSubmissions.value = false
      requiresSupervisorSignature.value = false
      supervisorChecklist.value = []
      needsPermitPerService.value = false
    }

    return {
      customerDocuments,
      email,
      getHasSpotChecks,
      getNeedsPermitPerService,
      hasAccreditation,
      hasCheckList,
      hasGPS,
      hasInduction,
      hasNotice,
      hasSpotChecks,
      hasVisitorArrivalNotification,
      hasVisitorManagement,
      hasWorkOrder,
      id,
      isCustomerStoreDefault,
      name,
      needsPermitPerService,
      phone,
      postcode,
      requiresMultiplePermitSubmissions,
      requiresSupervisorSignature,
      resetCustomer,
      setCustomerDocuments,
      setCustomerInfo,
      shouldShowSiteEntry,
      state,
      street,
      supervisorChecklist,
      town,
    }
  },
  {
    persist: true,
  },
)
