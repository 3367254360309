import { ref, Ref } from 'vue'

export function useTabFocus(): Ref<boolean> {
  const tabIsFocused = ref(false)
  const inView = ref(false)

  window.addEventListener('focus', onWindowFocusChange)
  window.addEventListener('blur', onWindowFocusChange)
  window.addEventListener('pageshow', onWindowFocusChange)
  window.addEventListener('pagehide', onWindowFocusChange)

  function onWindowFocusChange(e: Event) {
    if (
      e.type.toLowerCase() === 'focus' ||
      e.type.toLowerCase() === 'pageshow'
    ) {
      if (inView.value) {
        return
      }
      tabIsFocused.value = true
      inView.value = true
    } else if (inView.value) {
      tabIsFocused.value = !tabIsFocused.value
      inView.value = false
    }
  }

  return tabIsFocused
}
