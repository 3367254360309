import axios from 'axios'
import { addOrAppendAxiosHeader } from '@/services/axios'

const socketRequestInterceptor = (socketId: string): void => {
  axios.interceptors.request.use((config) => {
    config.headers = addOrAppendAxiosHeader(
      'X-Socket-ID',
      socketId,
      config.headers,
    )

    return config
  })
}

export default socketRequestInterceptor
