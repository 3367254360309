<template>
  <div class="flex items-center justify-around gap-x-4">
    <div class="size-[38px] min-w-0 overflow-hidden rounded-full">
      <img :src="customerLogo" class="object-contain" :alt="siteName" />
    </div>
    <div class="min-w-0 flex-1">
      <p class="truncate font-medium text-gray-900">
        {{ siteName }}
      </p>
      <p class="truncate text-sm text-gray-500">
        {{ siteAddress }}
      </p>
    </div>
    <div
      class="inline-flex items-center bg-white px-2.5 py-0.5 font-medium leading-5 text-gray-700"
    >
      <ChevronRightIcon class="h-6 w-6" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ChevronRightIcon } from '@heroicons/vue/24/outline'

  export default defineComponent({
    name: 'SiteListItem',
    components: {
      ChevronRightIcon,
    },

    props: {
      customerLogo: {
        type: String,
        required: true,
      },

      siteAddress: {
        type: String,
        default: '',
      },

      siteName: {
        type: String,
        default: '',
      },
    },
  })
</script>
