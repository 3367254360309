import UserLogin from '@/views/UserLogin.vue'

export default {
  component: UserLogin,
  meta: {
    loadingMessage: 'Loading login...',
    showMenu: false,
  },
  name: 'login',
  path: '/login',
}
