<template>
  <button
    type="button"
    aria-label="Work Order"
    class="h-[120px] max-h-[120px] w-full text-left"
    :disabled="workOrder.status === 'COMPLETED'"
    @click="showModal = true"
  >
    <span class="flex h-full gap-x-2 rounded-md pl-2 shadow">
      <span class="flex w-0 items-center justify-center text-center text-xs">
        <span
          class="min-w-[120px] -rotate-90 rounded-t-md text-white"
          :class="[statusBackground]"
        >
          {{ statusText }}
        </span>
      </span>
      <span class="container-content flex flex-1 rounded-r-md bg-white">
        <span class="m-2 flex-1">
          <span
            class="mb-1 flex flex-1 justify-between border-b border-gray-200"
          >
            <span class="flex-grow pb-1 font-bold">
              WO {{ workOrder.reference }}
            </span>
            <span class="flex-grow text-right text-sm">
              {{ workOrder.createdAt }}
            </span>
          </span>
          <span class="line-clamp-3">
            {{ workOrder.description }}
          </span>
        </span>
      </span>
    </span>
    <WorkOrderStartModal
      v-if="showModal"
      :work-order="workOrder"
      :status-colour="statusBackground"
      :status-text="statusText"
      @start-work-order="startWorkOrder"
      @close="showModal = false"
    />
  </button>
</template>

<script setup lang="ts">
  import { computed, PropType, ref } from 'vue'
  import { WorkOrder, WorkOrderStatus } from '@/services/api/workOrder'
  import WorkOrderStartModal from '@/components/WorkOrderStartModal.vue'

  const props = defineProps({
    workOrder: {
      required: true,
      type: Object as PropType<WorkOrder>,
    },
  })

  const emit = defineEmits<{
    click: [workOrder: WorkOrder]
  }>()

  const statusTextMap = new Map<WorkOrderStatus, WorkOrderStatus>([
    ['OPEN', 'OPEN'],
    ['STARTED', 'IN_PROGRESS'],
    ['IN_PROGRESS', 'IN_PROGRESS'],
    ['CANCELLED', 'OPEN'],
    ['COMPLETED', 'COMPLETED'],
  ])

  const statusText = computed(() =>
    statusTextMap.get(props.workOrder?.status)?.replace('_', ' '),
  )

  const statusColorMap = new Map<WorkOrderStatus, string>([
    ['IN_PROGRESS', 'bg-amber-600'],
    ['COMPLETED', 'bg-gray-600'],
    ['OPEN', 'bg-green-600'],
    [null, ''],
  ])

  const statusBackground = computed(() => {
    const status = statusTextMap.get(props.workOrder?.status)
    return statusColorMap.get(status ?? null)
  })

  const showModal = ref(false)

  function startWorkOrder() {
    emit('click', props.workOrder as WorkOrder)
    showModal.value = false
  }
</script>

<style scoped>
  button:disabled .container-content > * {
    @apply opacity-50;
  }
</style>
