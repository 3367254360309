import { api } from '@/config/api'
import { bannerText } from '@/config/app'
import type { country } from '@/config/countries'
import { countries } from '@/config/countries'
import { debug } from '@/config/debug'
import { echoBroadcaster } from '@/config/echoBroadcaster'
import { fingerprint } from '@/config/fingerprint'
import { honeyBadger } from '@/config/honeyBadger'
import { radius } from '@/config/distance'
import { perPage as sitesPerPage } from '@/config/showSitesPerPage'
import { redirects } from '@/config/redirects'
import { unauthorized } from '@/config/authorization'
import type { instruction } from '@/config/instructions'
import type { SelectOption } from '@/config/selectOption'
import { bypass } from '@/config/bypass'

const config = {
  api: {
    capture: api,
  },
  app: {
    bannerText,
  },
  bypass,
  countries,
  debug,
  distance: {
    radius,
  },
  echoBroadcaster,
  fingerprint,
  honeyBadger,
  pagination: {
    sites: {
      perPage: sitesPerPage,
    },
  },
  redirects,
  unauthorized,
}

export type { country, instruction, SelectOption }

export default config
