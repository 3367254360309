import { defineStore } from 'pinia'
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro'
import { computed, ref } from 'vue'
import config from '@/config'
import { useAppStore } from '@/storage/app'

export const useFingerprintStore = defineStore(
  'fingerprint',
  () => {
    /** state */
    const fingerprint = ref<null | ExtendedGetResult>(null)
    const updatedAt = ref(0)

    /** getters */
    const getBrowser = computed(() => fingerprint.value?.browserName)

    const getOs = computed(() => fingerprint.value?.os)

    const hasExpired = computed(
      () => updatedAt.value + config.fingerprint.ttl < Date.now(),
    )

    const hasFingerprint = computed(() => fingerprint.value !== null)

    const isPrivate = computed(() => fingerprint.value?.incognito === true)

    /** actions */
    const setFingerprint = (value: ExtendedGetResult) => {
      fingerprint.value = value
      updatedAt.value = Date.now()
    }

    const setIsLoading = (value: boolean) => {
      useAppStore().loading.set('app', value)
    }

    function resetFingerprint() {
      fingerprint.value = null
      updatedAt.value = 0
    }

    return {
      fingerprint,
      getBrowser,
      getOs,
      hasExpired,
      hasFingerprint,
      isPrivate,
      resetFingerprint,
      setFingerprint,
      setIsLoading,
      updatedAt,
    }
  },
  {
    persist: true,
  },
)
