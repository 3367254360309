class RuntimeException extends Error {
  public context: Record<string, unknown>
  public name = 'RuntimeError'
  public tags: string[]

  constructor(
    context: Record<string, unknown>,
    tags: string[],
    message?: string,
  ) {
    super(message)

    this.context = context
    this.tags = tags
  }
}

export default RuntimeException
