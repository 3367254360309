import axios from 'axios'
import { Answer, SchemaType } from '@verifiedit/form-generator'

export type SpotCheckForm = {
  form: {
    template: {
      schema: SchemaType[]
    }
  }
}

export async function getForm(): Promise<SpotCheckForm> {
  const response = await axios.get('api/spot-check-form')

  return response.data.data
}

export async function submitSpotCheck(
  customerId: number,
  permitFormSubmissionId: number,
  formData: Map<string, Answer>,
) {
  const response = await axios.post(`api/spot-check-submission`, {
    customerId: customerId,
    formData: Object.fromEntries(formData),
    permitFormSubmissionId: permitFormSubmissionId,
  })

  return response.data.data
}
