import { RouteLocationRaw } from 'vue-router'
import { routesMap } from '@/routes'
import { useServicesStore } from '@/storage/services'
import { useSessionStore } from '@/storage/session'

export function eventMap(): Map<string, RouteLocationRaw> {
  return new Map<string, RouteLocationRaw>([
    ['AuthenticatedEvent', { name: routesMap.SelectNearbySiteRoute.name }],
    ['GetNearbySitesEvent', { name: routesMap.SelectNearbySiteRoute.name }],
    ['GetSiteEvent', { name: routesMap.ContractorCompanySelectionRoute.name }],
    ['GetInductionFormEvent', { name: routesMap.InductionFormRoute.name }],
    [
      'GetUserSiteContractorCompaniesEvent',
      { name: routesMap.ContractorCompanySelectionRoute.name },
    ],
    [
      'GetCustomerServicesByContractorEvent',
      { name: routesMap.ServiceSelectionRoute.name },
    ],
    [
      'GetSiteServiceFrequenciesEvent',
      {
        name: routesMap.ServiceFrequencyRoute.name,
        params: { serviceType: useServicesStore().selectedServiceType },
      },
    ],
    [
      'GetServiceFrequenciesEvent',
      {
        name: routesMap.ServiceFrequencyRoute.name,
        params: { serviceType: useServicesStore().selectedServiceType },
      },
    ],
    ['GetCustomerServiceFormEvent', { name: routesMap.ServiceFormRoute.name }],
    [
      'ServiceRecordCreatedEvent',
      { name: routesMap.ServiceCheckoutRoute.name },
    ],
    ['GetVisitorUserTypesEvent', { name: routesMap.Representation.name }],
    ['ReadyToCheckoutEvent', { name: routesMap.ReadyToCheckoutRoute.name }],
    [
      'SiteEntryConfirmationEvent',
      { name: routesMap.SiteEntryConfirmationRoute.name },
    ],
    [
      'PermitFormSubmittedEvent',
      { name: routesMap.ServiceSelectionRoute.name },
    ],
    ['GetWorkOrdersEvent', { name: routesMap.WorkOrdersRoute.name }],
    ['GetPermitFormsEvent', { name: routesMap.StartPermitForm.name }],
  ])
}

export const getCurrentEventRoute = (): RouteLocationRaw => {
  const route = eventMap().get(useSessionStore().currentEvent)
  return route ? route : { name: routesMap.UserLoginRoute.name }
}

const visitorIdMatches = (
  eventVisitorId: string,
  currentVisitorId: string | undefined,
): boolean => eventVisitorId === currentVisitorId

const requestIdMatches = (
  eventRequestId: string,
  currentRequestId: string | undefined,
): boolean => eventRequestId === currentRequestId

export const isSameFingerprint = (
  eventRequestId: string,
  eventVisitorId: string,
  currentRequestId: string | undefined,
  currentVisitorId: string | undefined,
): boolean => {
  const visitorIdMatched = visitorIdMatches(eventVisitorId, currentVisitorId)
  const requestIdMatched = requestIdMatches(eventRequestId, currentRequestId)

  return visitorIdMatched && requestIdMatched
}
