<template>
  <main class="page-container">
    <div class="flex-container">
      <TitleBanner class="header" title="Who are you representing?" />
      <div class="ml-auto mr-3 h-6">
        <div v-if="showTopScrollButton"><ChevronUpIcon class="h-5 w-5" /></div>
      </div>
      <div
        ref="content"
        class="content mx-4 space-y-8 overflow-y-scroll px-6 text-center"
      >
        <PrimaryButton
          v-for="contractorCompany in contractorCompanies"
          :key="contractorCompanies.indexOf(contractorCompany)"
          class="max-w-2xl"
          :action="contractorCompany.name"
          data-test="company"
          @click.once="selectContractorCompany(contractorCompany.id)"
        />
      </div>
      <div class="ml-auto mr-3 h-6">
        <ChevronDownIcon v-if="showBottomScrollButton" class="h-5 w-5" />
        <div v-else class="h-5 w-5"></div>
      </div>
      <div v-if="showVisitorOption" class="footer mx-4 mb-20 mt-4 text-right">
        <a
          class="text-blue-400 hover:text-blue-500 active:text-blue-600"
          data-test="visitor"
          @click.once="goToVisitorAccessRepresentation"
        >
          Don't see your company?
        </a>
      </div>
    </div>
    <LoadingView :is-loading="isLoading" message="Selecting company..." />
  </main>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { onBeforeRouteLeave, useRouter } from 'vue-router'
  import { postSiteSelectedData } from '@/services/api/siteSelected'
  import siteSelected, { SiteSelectedData } from '@/components/use/siteSelected'
  import LoadingView from '@/views/LoadingView.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import RecoverableException from '@/exceptions/RecoverableException'
  import resetForms from '@/components/use/resetForms'
  import { resetDataStores } from '@/components/use/resetStore'
  import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/solid'
  import { useBypassStore } from '@/storage/bypass'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useUserPositionStore } from '@/storage/userPosition'
  import { useSessionStore } from '@/storage/session'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'ContractorCompanySelection',
    components: {
      ChevronDownIcon,
      ChevronUpIcon,
      LoadingView,
      PrimaryButton,
      TitleBanner,
    },

    async setup() {
      const {
        id: siteId,
        isSiteStoreDefault,
        authorisedContractorCompanies: contractorCompanies,
        canContinueAsVisitor,
      } = storeToRefs(useSiteStore())
      const { hasVisitorManagement } = storeToRefs(useCustomerStore())
      const { position } = storeToRefs(useUserPositionStore())
      const {
        id: userId,
        isSelectedContractorCompanyIdDefault,
        selectedContractorCompanyId,
        selectedContractorCompanyUserType,
        userType,
        isUserStoreDefault,
        isUserTypeDefault,
      } = storeToRefs(useUserStore())
      const { setCheckedInTime, setSelectedContractorCompanyId, setUserType } =
        useUserStore()
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const { setSiteSessionId, setSessionTtl } = useSessionStore()
      const router = useRouter()
      const isLoading = ref<boolean>(false)
      const showVisitorOption = computed(
        () =>
          hasVisitorManagement.value &&
          !useBypassStore().scanBypassed &&
          canContinueAsVisitor.value,
      )
      const showTopScrollButton = ref(false)
      const showBottomScrollButton = ref(false)

      onBeforeRouteLeave((to) => {
        if (to.name === 'checkWorkOrder') {
          resetDataStores()
        }
      })

      const content = ref<HTMLDivElement>()
      watch(content, () => {
        if (content.value === undefined) return
        const element = content.value
        showBottomScrollButton.value =
          element.offsetHeight + element.scrollTop < element.scrollHeight
        content.value.addEventListener('scroll', () => {
          showTopScrollButton.value = element.scrollTop !== 0
          showBottomScrollButton.value =
            element.offsetHeight + element.scrollTop < element.scrollHeight
        })
      })

      if (contractorCompanies.value.length === 1 && !showVisitorOption.value) {
        await selectContractorCompany(contractorCompanies.value[0].id)
      } else if (
        contractorCompanies.value.length === 0 &&
        showVisitorOption.value
      ) {
        await goToVisitorAccessRepresentation()
      } else if (contractorCompanies.value.length === 0) {
        throw new RecoverableException('No valid contractor companies')
      }

      async function acquireRequiredData(): Promise<SiteSelectedData> {
        const bypass = useBypassStore().getBypass
        if (
          isSelectedContractorCompanyIdDefault.value ||
          isSiteStoreDefault.value ||
          isUserStoreDefault.value ||
          isUserTypeDefault.value
        ) {
          throw new InvalidStoreException(
            {
              bypass: bypass,
              fingerprint: fingerprint.value,
              selectedContractorCompanyId: selectedContractorCompanyId.value,
              siteId: siteId.value,
              userId: userId.value,
              userType: userType.value,
            },
            ['InvalidStore', 'StoreSiteCheckin'],
          )
        }

        return {
          bypass: bypass,
          contractorCompanyId: selectedContractorCompanyId.value.toString(),
          location: {
            accuracy: position.value.accuracy,
            altitude: position.value.altitude,
            latitude: position.value.latitude,
            longitude: position.value.longitude,
          },

          siteId: siteId.value.toString(),
          userId: userId.value.toString(),
          userTypeId: userType.value.id,
        }
      }

      async function selectContractorCompany(id: number) {
        isLoading.value = true
        setSelectedContractorCompanyId(id)
        setUserType(selectedContractorCompanyUserType.value)
        resetForms()

        const response = await siteSelected(
          acquireRequiredData,
          postSiteSelectedData,
        )

        if (response.code === 200) {
          setCheckedInTime(response.checkedInAt)
          setSessionTtl(response.sessionTtl)
          setSiteSessionId(response.siteSessionId)
          await router.replace({ name: 'checkWorkOrder' })
        }
        isLoading.value = false
      }

      async function goToVisitorAccessRepresentation() {
        await router.replace({ name: 'representation' })
      }

      return {
        content,
        contractorCompanies,
        goToVisitorAccessRepresentation,
        isLoading,
        selectContractorCompany,
        showBottomScrollButton,
        showTopScrollButton,
        showVisitorOption,
      }
    },
  })
</script>
