<template>
  <div>
    <p id="user-type" class="text-center font-bold">
      {{ userTypeNameStatic }}
    </p>
    <img
      v-if="employeePhotoStatic"
      class="mx-auto aspect-square w-1/3"
      :src="'data:image/png;base64,' + employeePhotoStatic"
      alt="Employee Photo"
    />
    <div class="mt-4 w-full text-center">
      <p>{{ userFullName }}</p>
      <p>{{ contractorName }}</p>
      <p>{{ entryDate }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useUserStore } from '@/storage/user'
  import { computed } from 'vue'
  import { useUserVisitorCompaniesStore } from '@/storage/userVisitorCompanies'
  import dayjs from 'dayjs'
  import { useUserLogin } from '@/storage/userLogin'

  const {
    fullName,
    selectedContractorCompanyName,
    userType,
    employeePhoto,
    checkedInTime,
  } = storeToRefs(useUserStore())

  const { isVisitor } = storeToRefs(useUserLogin())
  const isVisitorStatic = isVisitor.value

  const getContractorName = computed(() => {
    return isVisitorStatic
      ? useUserVisitorCompaniesStore().lastSelected
      : selectedContractorCompanyName.value
  })
  const contractorName = getContractorName.value

  const userTypeNameStatic = userType.value.name
  const employeePhotoStatic = employeePhoto.value
  const userFullName = fullName.value
  const entryDate = dayjs(checkedInTime.value).format('DD MMM YYYY [at] HH:mm')
</script>
