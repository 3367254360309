import NoStateNativeScanStrategySelector from '@/strategies/selectors/NoStateNativeScanStrategySelector'

import {
  StrategyInterface,
  StrategySelectorContract,
} from '@/types/strategyInterface'
import DefaultStrategySelector from '@/strategies/selectors/DefaultStrategySelector'
import AuthenticatedUserNativeScanStrategySelector from '@/strategies/selectors/AuthenticatedUserNativeScanStrategySelector'
import CurrentSessionStrategySelector from '@/strategies/selectors/CurrentSessionStrategySelector'
import ReportableException from '@/exceptions/ReportableException'
import { getActivePinia } from 'pinia'

export default class StrategySelector {
  private selectors = [
    new AuthenticatedUserNativeScanStrategySelector(),
    new NoStateNativeScanStrategySelector(),
    new CurrentSessionStrategySelector(),
    new DefaultStrategySelector(),
  ]

  select = (): StrategyInterface => {
    const selected = this.selectors.find(
      (selector: StrategySelectorContract) => {
        return selector.can()
      },
    )

    if (!selected) {
      throw new ReportableException('App startup failed. Please try again', {
        piniaStoreState: getActivePinia()?.state.value,
      })
    }

    return selected.getStrategy()
  }
}
