<template>
  <div
    v-if="isLoading"
    class="z-[4] inline-flex items-center justify-center bg-white"
    :class="[position, { 'bg-opacity-60': !isOpaque }]"
  >
    <span
      id="loadingIcon"
      class="flex h-12 w-12 animate-spin-slow items-center justify-center"
    >
      <ArrowPathIcon class="h-9/12 w-9/12 opacity-80" />
    </span>
    <span class="ml-2 block text-lg opacity-80">{{ message }}</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ArrowPathIcon } from '@heroicons/vue/24/outline'

  export default defineComponent({
    name: 'LoadingView',
    components: { ArrowPathIcon },
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },

      isOpaque: {
        type: Boolean,
        default: false,
      },

      message: {
        type: String,
        default: 'Loading...',
      },

      position: {
        type: String,
        default: 'fixed bottom-0 left-0 right-0 top-0',
      },
    },
  })
</script>
