<template>
  <div>
    <TitleBanner title="Are you sure you want to checkout?" />
    <div class="p-4">
      <div class="flex space-x-4">
        <SecondaryButton
          action="NO"
          identifier="bypass-checkout-no"
          @click.once="cancelCheckout"
        />
        <PrimaryButton
          action="YES"
          identifier="bypass-checkout-yes"
          @click.once="confirmCheckout"
        />
      </div>
      <LoadingView :is-loading="checkoutConfirmed" message="Checking out..." />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import {
    onBeforeRouteLeave,
    RouteLocationNormalized,
    useRouter,
  } from 'vue-router'
  import { acquireRequiredData, checkout } from '@/components/use/checkout'
  import { postCheckoutData } from '@/services/api/checkout'
  import LoadingView from './LoadingView.vue'
  import dayjs from 'dayjs'
  import resetStore from '@/components/use/resetStore'
  import Log from '@/utilities/Log'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import { getCurrentEventRoute } from '@/utilities/session'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'CheckoutView',
    components: { LoadingView, PrimaryButton, SecondaryButton, TitleBanner },

    setup() {
      const router = useRouter()
      const checkoutConfirmed = ref(false)

      onBeforeRouteLeave((to: RouteLocationNormalized) => {
        if (to.name === 'serviceSelection' && checkoutConfirmed.value) {
          return false
        }
      })

      async function confirmCheckout() {
        checkoutConfirmed.value = true
        const response = await checkout(acquireRequiredData, postCheckoutData)
        if (response.code === 200) {
          const url = checkoutConfirmationRedirectUrl(window.location.origin)
          resetStore()
          Log.storeReset('CheckoutView.vue', 'confirmCheckout')
          window.location.replace(url)
        }
      }

      async function cancelCheckout() {
        await router.replace(getCurrentEventRoute())
      }

      function checkoutConfirmationRedirectUrl(base: string): string {
        const { address, name } = storeToRefs(useSiteStore())
        const currentTimeDate = dayjs()
        const currentTime = currentTimeDate.format('h:mma')
        const currentDate = currentTimeDate.format('D MMMM YYYY')
        const userName = useUserStore().firstName
        const query = new URLSearchParams({
          address: address.value,
          date: currentDate,
          name: name.value,
          time: currentTime,
          userName: userName,
        }).toString()

        const searchParams = new URLSearchParams({
          query: btoa(query),
        }).toString()

        return new URL(`/checkout/thank-you?${searchParams}`, base).toString()
      }

      return {
        cancelCheckout,
        checkoutConfirmed,
        confirmCheckout,
      }
    },
  })
</script>
