<template>
  <div class="card space-y-6 pb-3">
    <div
      class="flex items-center justify-center space-x-3 rounded-t-md py-2"
      :class="{
        'bg-green-600': passStatic,
        'bg-amber-500': !passStatic,
      }"
    >
      <CheckCircleIcon v-if="passStatic" class="h-7 w-7 fill-white" />
      <ExclamationTriangleIcon v-else class="h-7 w-7 stroke-white" />
      <h1 class="text-lg text-white">{{ headingText }}</h1>
    </div>

    <div class="px-4">
      <SiteEntryUserInformation />

      <ul class="my-6" data-test="checks-list">
        <li
          v-for="siteEntryCheck in siteEntryChecks"
          :key="siteEntryCheck.title"
          class="mb-7"
        >
          <div class="flex items-center space-x-3">
            <ExclamationTriangleIcon
              v-if="!siteEntryCheck.passed && siteEntryCheck.canContinue"
              class="w-6 stroke-yellow-600"
            />
            <CheckCircleOutlineIcon
              v-else-if="siteEntryCheck.canContinue"
              class="w-6 stroke-green-600"
            />
            <p data-test="check-title">
              {{ siteEntryCheck.title }}
            </p>
          </div>

          <ul
            v-if="siteEntryCheck.checks"
            class="mt-0.5"
            data-test="check-details"
          >
            <li
              v-for="check in siteEntryCheck.checks.filter(
                (check) => check.result.toLowerCase() === 'fail',
              )"
              :key="
                check.name !== ''
                  ? check.name
                  : `${siteEntryCheck.title}-feedback`
              "
              class="mb-0.5 ml-9 text-sm"
            >
              <p class="font-bold">
                {{ check['displayName'] ?? check.name }}
              </p>
              <p>
                {{ check['feedback'] ?? '' }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <p v-if="!passStatic" class="text-center text-sm">
        Please rectify to prevent future access being denied
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import SiteEntryUserInformation from '@/components/SiteEntryUserInformation.vue'
  import { computed, PropType } from 'vue'
  import {
    CheckType,
    SiteEntryCheckType,
  } from '@/storage/siteEntryConfirmation'
  import { AccreditationCheckType } from '@/services/api/accreditation'
  import {
    ExclamationTriangleIcon,
    CheckCircleIcon as CheckCircleOutlineIcon,
  } from '@heroicons/vue/24/outline'
  import { CheckCircleIcon } from '@heroicons/vue/24/solid'

  const props = defineProps({
    passStatic: {
      required: true,
      type: Boolean,
    },
    siteEntryChecks: {
      required: true,
      type: Array as PropType<
        SiteEntryCheckType<CheckType | AccreditationCheckType>[]
      >,
    },
  })

  const headingText = computed(() =>
    props.passStatic ? 'Access Granted' : 'Access Warning',
  )
</script>
