import axios from 'axios'

export type Service = {
  id: number
  name: string
  subServiceId: number
}

export const getSiteServices = async (
  siteId: number,
  contractorCompanyId: number,
): Promise<Service[]> => {
  const response = await axios.get('/api/sites/' + siteId + '/services', {
    params: {
      contractorCompanyId: contractorCompanyId,
    },
  })

  return response.data.data
}

export const getAllServices = async (
  contractorCompanyId: number,
  customerId: number | null,
): Promise<Service[]> => {
  const response = await axios.get('/api/services', {
    params: {
      contractorCompanyId: contractorCompanyId,
      customerId: customerId,
    },
  })

  return response.data.data
}
