<template>
  <TitleBanner title="Documents" />
  <div class="my-4">
    <div v-if="siteDocuments.length > 0" class="px-2">
      <div>Site documents</div>
      <ol>
        <li v-for="(document, index) in siteDocuments" :key="index">
          <DocumentListItem :document="document" document-type="site" />
        </li>
      </ol>
    </div>
    <div v-if="customerDocuments.length > 0" class="px-2">
      <div>Company documents</div>
      <ol>
        <li v-for="(document, index) in customerDocuments" :key="index">
          <DocumentListItem :document="document" document-type="customer" />
        </li>
      </ol>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import DocumentListItem from '@/components/DocumentListItem.vue'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'

  export default defineComponent({
    name: 'ViewDocuments',
    components: {
      DocumentListItem,
      TitleBanner,
    },

    setup() {
      const { siteDocuments } = storeToRefs(useSiteStore())
      const { customerDocuments } = storeToRefs(useCustomerStore())

      return {
        customerDocuments,
        siteDocuments,
      }
    },
  })
</script>

<style scoped>
  ol {
    all: revert;
    list-style: none;
  }
</style>
