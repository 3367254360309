<template>
  <BaseModal
    v-if="showDuplicateServiceModal"
    title="Duplicate service form submission?"
    @close="$emit('close')"
  >
    <p>
      We have recently received a similar record, this can be viewed in
      <span class="text-blue-600" @click="$emit('openHistory')">My History</span
      >. Do you wish to continue submitting?
    </p>
    <div class="ml-auto mt-4 flex w-3/4 gap-x-4">
      <SecondaryButton
        :key="confirmButtonKey"
        button-classes="bg-opacity-0 active:bg-opacity-20 text-verified-blue"
        action="YES"
        @click="confirmSubmission"
      />
      <PrimaryButton
        :key="rejectButtonKey"
        action="NO"
        @click="rejectSubmission"
      />
    </div>
  </BaseModal>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import BaseModal from '@/components/BaseModal.vue'
  import SecondaryButton from '@/components/SecondaryButton.vue'

  export default defineComponent({
    name: 'DuplicateServiceModal',

    components: { BaseModal, PrimaryButton, SecondaryButton },

    props: {
      showDuplicateServiceModal: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['confirm', 'openHistory', 'close'],

    setup(props, { emit }) {
      const confirmButtonKey = ref(0)
      const rejectButtonKey = ref(0)

      function confirmSubmission() {
        confirmButtonKey.value++
        emit('confirm')
      }

      function rejectSubmission() {
        rejectButtonKey.value++
        emit('close')
      }

      return {
        confirmButtonKey,
        confirmSubmission,
        rejectButtonKey,
        rejectSubmission,
      }
    },
  })
</script>
