<template>
  <div>
    <TitleBanner title="Select Site (Bypass)" />
    <div class="mx-2 mt-4 space-y-4">
      <BaseSelector
        label="Bypass records are reported separately"
        :label-classes="[labelClass]"
        :options="bypassReasons"
        placeholder="Please select your bypass reason"
        @option-changed="reasonSelected"
      >
      </BaseSelector>
      <VueDatePicker
        v-if="selectedReason?.hasDate"
        v-model="checkInDate"
        :enable-time-picker="false"
        format="MMMM d yyyy"
        :min-date="minCheckInDate"
        :max-date="maxCheckInDate"
        placeholder="Select check in date"
        :action-row="{
          showNow: false,
          showPreview: false,
          showSelect: false,
          showCancel: false,
        }"
        auto-apply
      />
      <div v-if="dateIsEmpty" class="error m-2">{{ error }}</div>
      <div v-if="selectedReason?.hasDescription">
        <BaseTextarea
          v-model="reasonDescription"
          placeholder="Bypass Reason"
          data-test="reason-description"
          :textarea-classes="textareaClasses"
          :required="textareaRequired"
          :maxlength="textareaMaxlength"
          :class="{ 'placeholder-red-500': descriptionIsEmpty }"
        >
        </BaseTextarea>
        <div v-if="descriptionIsEmpty" class="error">{{ error }}</div>
      </div>
    </div>
    <div class="mx-2 mt-4">
      <PrimaryButton
        :key="submitButtonKey"
        class="continue"
        type="button"
        :disabled="!selectedReason"
        action="CONTINUE"
        @click.once="confirmBypass"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref, watchEffect } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import BaseSelector from '@/components/BaseSelector.vue'
  import BaseTextarea from '@/components/BaseTextarea.vue'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'

  import {
    BypassReasonContract,
    fetchBypassReasons,
  } from '@/services/api/bypassReason'
  import { useRouter } from 'vue-router'
  import dayjs from 'dayjs'
  import { useBypassStore } from '@/storage/bypass'
  import config from '@/config'

  export default defineComponent({
    name: 'BypassSite',
    components: {
      BaseSelector,
      BaseTextarea,
      PrimaryButton,
      TitleBanner,
      VueDatePicker,
    },

    async setup() {
      const router = useRouter()
      const error = 'field cannot be empty'
      const textareaShowed = ref(false)
      const datepickerShowed = ref(false)
      const selectedReason = ref<BypassReasonContract>()
      const labelClass = ref(
        'text-center error block font-medium text-gray-700',
      )
      const textareaClasses = ref(['h-20'])
      const textareaRequired = ref(true)
      const textareaMaxlength = ref(30)
      const reasonDescription = ref('')
      const checkInDate = ref<Date | undefined>(undefined)
      const bypassReasons = ref<BypassReasonContract[]>()
      const {
        setSelectedBypassReason,
        setCheckInDate,
        setReasonDescription,
        resetBypass,
      } = useBypassStore()
      const minCheckInDate = ref(
        dayjs()
          .subtract(config.bypass.maxPastDateSelectionRange, 'month')
          .toDate(),
      )
      const maxCheckInDate = ref(dayjs().toDate())
      const dateIsEmpty = ref(false)
      const descriptionIsEmpty = ref(false)
      const submitButtonKey = ref<number>(0)

      onMounted(() => {
        resetBypass()
      })

      watchEffect(() => {
        if (checkInDate.value) {
          dateIsEmpty.value = false
        }

        if (reasonDescription.value) {
          descriptionIsEmpty.value = false
        }
      })

      const getBypassReasons = async () => {
        bypassReasons.value = await fetchBypassReasons()
      }

      await getBypassReasons()

      function saveBypassInStore() {
        setSelectedBypassReason(selectedReason.value as BypassReasonContract)

        if (checkInDate.value) {
          setCheckInDate(formatAndConvertDate(checkInDate.value))
        }

        if (reasonDescription.value) {
          setReasonDescription(reasonDescription.value)
        }
      }

      async function confirmBypass(): Promise<void> {
        submitButtonKey.value++
        if (checkMandatoryFields()) {
          saveBypassInStore()
          await router.replace({ name: 'selectBypassSites' })
        }
      }

      function clearOutPreviousSelection() {
        checkInDate.value = undefined
        reasonDescription.value = ''
        dateIsEmpty.value = false
        descriptionIsEmpty.value = false
      }

      function reasonSelected(selected: number): void {
        clearOutPreviousSelection()
        selectedReason.value = bypassReasons?.value?.find(
          (reason: BypassReasonContract): boolean => {
            return reason.id == selected
          },
        )
      }

      function formatAndConvertDate(date: Date | string): string {
        return dayjs(date).format('DD-MM-YYYY')
      }

      function checkMandatoryFields() {
        if (selectedReason.value?.hasDate && !checkInDate.value) {
          dateIsEmpty.value = true
        }
        if (selectedReason.value?.hasDescription && !reasonDescription.value) {
          descriptionIsEmpty.value = true
        }

        return !(dateIsEmpty.value || descriptionIsEmpty.value)
      }

      return {
        bypassReasons,
        checkInDate,
        confirmBypass,
        dateIsEmpty,
        datepickerShowed,
        descriptionIsEmpty,
        error,
        labelClass,
        maxCheckInDate,
        minCheckInDate,
        reasonDescription,
        reasonSelected,
        selectedReason,
        submitButtonKey,
        textareaClasses,
        textareaMaxlength,
        textareaRequired,
        textareaShowed,
      }
    },
  })
</script>
