import {
  CheckType,
  SiteEntryCheckType,
  useSiteEntryConfirmationStore,
  SiteEntryTitle,
} from '@/storage/siteEntryConfirmation'
import { AccreditationCheckType } from '@/services/api/accreditation'

export function getSiteEntryCheck(
  title: SiteEntryTitle,
): SiteEntryCheckType<CheckType | AccreditationCheckType> | undefined {
  const store = useSiteEntryConfirmationStore()
  return store.siteEntryChecks.find(
    (checkInStore) => checkInStore.title === title,
  )
}
