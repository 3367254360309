import { computed, ComputedRef, ref, shallowRef } from 'vue'
import UserHistory from '@/views/UserHistory.vue'
import UserHelp from '@/views/UserHelp.vue'
import ChangePin from '@/views/ChangePin.vue'
import SiteEntryConfirmationOverlay from '@/views/SiteEntryConfirmationOverlay.vue'
import SiteHazardOverlay from '@/views/SiteHazardOverlay.vue'
import ViewDocuments from '@/views/ViewDocuments.vue'
import EmptyView from '@/views/EmptyView.vue'
import CurrentNotificationsOverlay from '@/views/CurrentNotificationsOverlay.vue'
import SpotCheckFormOverlay from '@/views/SpotCheckFormOverlay.vue'

type OverlayComponent =
  | typeof ChangePin
  | typeof CurrentNotificationsOverlay
  | typeof EmptyView
  | typeof SiteEntryConfirmationOverlay
  | typeof SiteHazardOverlay
  | typeof SpotCheckFormOverlay
  | typeof UserHelp
  | typeof UserHistory
  | typeof ViewDocuments

const overlayComponent = shallowRef<OverlayComponent>(EmptyView)
const closeable = ref(true)

export default function overlay(): {
  getCloseable: () => ComputedRef<boolean>
  getOverlayComponent: () => ComputedRef<OverlayComponent>
  hideOverlay: () => void
  setOverlayComponent: (component: OverlayComponent, canClose?: boolean) => void
  showOverlay: () => ComputedRef<boolean>
} {
  return {
    getCloseable: () => computed(() => closeable.value),
    getOverlayComponent: () =>
      computed(() => {
        return overlayComponent.value
      }),
    hideOverlay: () => {
      overlayComponent.value = EmptyView
      closeable.value = true
    },
    setOverlayComponent: (
      component: OverlayComponent,
      canClose: boolean = true,
    ) => {
      closeable.value = canClose
      overlayComponent.value = component
    },

    showOverlay: () =>
      computed(() => {
        return (
          overlayComponent.value !== undefined &&
          overlayComponent.value?.name !== 'EmptyView'
        )
      }),
  }
}
