import Honeybadger from '@honeybadger-io/js'
import config from '@/config'

class Log {
  private static honeybadger = Honeybadger.configure(config.honeyBadger)

  static storeReset(file: string, functionName: string | null = null) {
    this.honeybadger.addBreadcrumb('Pinia store reset', {
      category: 'log',
      metadata: {
        file: file,
        function: functionName || '',
      },
    })
  }

  static storeResetExcludingUser(
    file: string,
    functionName: string | null = null,
  ) {
    this.honeybadger.addBreadcrumb('Pinia store excluding user reset', {
      category: 'log',
      metadata: {
        file: file,
        function: functionName || '',
      },
    })
  }

  static siteStoreReset(file: string, functionName: string | null = null) {
    this.honeybadger.addBreadcrumb('Site store reset', {
      category: 'log',
      metadata: {
        file: file,
        function: functionName || '',
      },
    })
  }

  static customerStoreReset(file: string, functionName: string | null = null) {
    this.honeybadger.addBreadcrumb('Customer store reset', {
      category: 'log',
      metadata: {
        file: file,
        function: functionName || '',
      },
    })
  }

  static genericBreadcrumb(message: string, data: unknown) {
    this.honeybadger.addBreadcrumb(message, {
      category: 'log',
      metadata: {
        data,
      },
    })
  }

  static notify(error: Error, name: string) {
    this.honeybadger.notify(error, name)
  }

  static getHoneybadger() {
    return this.honeybadger
  }
}

export default Log
