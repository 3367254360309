<template>
  <div
    ref="scrollTopButton"
    class="invisible sticky bottom-1 m-1 w-max transition"
    :class="{
      'mx-auto': position === 'center',
      'ml-auto': position === 'right',
      'mr-auto': position === 'left',
    }"
  >
    <div
      class="flex items-center justify-center rounded-full text-verified-blue"
    >
      <button
        type="button"
        role="button"
        aria-label="scroll to top of the page"
        class="p-1"
        @click="scrollToTop"
      >
        <span class="flex items-center justify-center">
          <ChevronDoubleUpIcon class="size-5 stroke-verified-blue" />
          Top
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted, ref } from 'vue'
  import { ChevronDoubleUpIcon } from '@heroicons/vue/24/outline'

  defineProps({
    position: { default: 'right', type: String },
  })

  const scrollTopButton = ref<HTMLButtonElement | null>(null)

  const handleScroll = () => {
    if (!scrollTopButton.value) {
      return
    }
    if (window.scrollY > 750) {
      scrollTopButton.value.classList.remove('invisible')
    } else {
      scrollTopButton.value.classList.add('invisible')
    }
  }

  function scrollToTop() {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })
</script>
