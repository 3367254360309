import ForgotPin from '@/views/ForgotPin.vue'

export default {
  component: ForgotPin,
  meta: {
    loadingMessage: 'Loading forgot PIN...',
    returnTo: 'login',
    showMenu: false,
  },
  name: 'forgotPin',
  path: '/forgot-pin',
}
