<template>
  <Suspense timeout="0">
    <template #default>
      <Component :is="overlayComponent" v-if="showOverlay"></Component>
    </template>
    <template #fallback>
      <LoadingView v-show="showOverlay" :is-loading="true" />
    </template>
  </Suspense>
</template>

<script lang="ts">
  import { defineComponent, watch } from 'vue'
  import overlay from '@/components/use/overlay'
  import LoadingView from '@/views/LoadingView.vue'

  export default defineComponent({
    name: 'OverlayView',
    components: { LoadingView },
    emits: ['hasOverlay'],
    setup(props, { emit }) {
      const { getOverlayComponent, showOverlay } = overlay()
      watch(showOverlay(), (value: boolean) => {
        emit('hasOverlay', value)
      })

      return {
        overlayComponent: getOverlayComponent(),
        showOverlay: showOverlay(),
      }
    },
  })
</script>
