import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useNativeScanStore } from '@/storage/nativeScan'
import { getCurrentEventRoute } from '@/utilities/session'
import SelectNearbySite from '@/views/SelectNearbySite.vue'
import { useCustomerStore } from '@/storage/customer'
import { useSiteStore } from '@/storage/site'

const guard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (
    from.name === 'confirmNearbySite' ||
    from.name === 'contractorCompanySelection' ||
    from.name === 'inductionForm'
  ) {
    useSiteStore().resetSite()
    useCustomerStore().resetCustomer()
    useNativeScanStore().resetNativeScan()
  }

  const uuid = useNativeScanStore().uuid

  if (useSiteStore().hasSameSiteSetInSession && uuid?.length === 36) {
    next(getCurrentEventRoute())
  } else {
    next()
  }
}

export default {
  beforeEnter: guard,
  component: SelectNearbySite,
  meta: {
    loadingMessage: 'Loading sites...',
    requiresLocation: true,
    showMenu: true,
  },
  name: 'selectNearbySite',
  path: '/sites/nearby',
}
