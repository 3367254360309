import axios from 'axios'
import { Site } from '@/services/api/site'

export type Plate = {
  description: string
  id: number
  site: Site
  uuid: string
}

export const getPlate = async (plateUuid: string): Promise<Plate> => {
  const response = await axios.get(`/api/plates/${plateUuid}`)

  return response.data.data
}
