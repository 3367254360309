import {
  StrategyInterface,
  ResolvableRouteInterface,
} from '@/types/strategyInterface'
import { RouteLocationRaw } from 'vue-router'
import { getCurrentEventRoute } from '@/utilities/session'

export default class CurrentSessionStrategy
  implements StrategyInterface, ResolvableRouteInterface
{
  setup(): void {
    return
  }

  resolve(): RouteLocationRaw {
    return getCurrentEventRoute()
  }
}
