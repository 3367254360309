import { RouteLocationNormalized } from 'vue-router'
// import user from '@/components/use/user'
import { useNativeScanStore } from '@/storage/nativeScan'
import { postLocation } from '@/services/api/locationRequested'
import { useUserLogin } from '@/storage/userLogin'
import { storeToRefs } from 'pinia'
import { useUserPositionStore } from '@/storage/userPosition'
import { useSiteStore } from '@/storage/site'

export const positionResolver = async (to: RouteLocationNormalized) => {
  if (!to.meta.requiresLocation) {
    return
  }

  const { isVisitor } = storeToRefs(useUserLogin())
  const { selectionConfirmed } = storeToRefs(useSiteStore())

  const visitorScanIn = isVisitor.value && useNativeScanStore().uuid
  const visitorNotScanIn = isVisitor.value && !useNativeScanStore().uuid
  // const visitorPreciseLocationRequired =
  //   visitorNotScanIn && to.name === 'selectNearbySite'

  try {
    await useUserPositionStore().setUserPosition()
    const location = useUserPositionStore().position
    if (location.accuracy > 250) {
      postLocation({ location: location }).then()
    }
    /** removed for time being until we can collect more info on why it is blocking some users
     * TODO: re-enable this check when we have more info
     */
    // if (
    //   user().getContractorCompanies().value.length > 0 ||
    //   (visitorPreciseLocationRequired &&
    //     usePosition().getUserPosition().value.accuracy > 250)
    // ) {
    //   return { name: 'helpLocationPermission' }
    // }
    return
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    if (visitorScanIn || (visitorNotScanIn && selectionConfirmed.value)) {
      return true
    }

    return { name: 'helpLocationPermission', query: { to: to.name as string } }
  }
}
